import Account from "store/models/Account";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Account);

export default class AccountActions {
  async searchAccounts(params) {
    const { limitData, limit, offset, filter } = params;

    return await storeActions.query(
      {
        queryName: "accounts",
        relativeUrl: "/account/search-accounts",
        limitData: limitData || null,
        limit: limit || null,
        offset: offset || null,
        filter: filter || null,
      },
      "To search accounts."
    );
  }

  async get(email, limitData = null) {
    return await storeActions.query(
      {
        queryName: "getAccount",
        relativeUrl: "/account/get-by-email",
        limitData,
      },
      "To fetch account by email.",
      {
        property: email,
      }
    );
  }

  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updateAccountDetails",
        relativeUrl: "/account/update-account-details",
        queries: variables.queries,
      },
      "To update account.",
      {
        accountId: variables.accountId,
      }
    );
  }

  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createVbsAccount",
        relativeUrl: "/account/create-vbs-account",
      },
      "To create account.",
      variables
    );
  }
}
