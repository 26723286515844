import { Model } from "@vuex-orm/core";

import GraphqlHelper from "store/models/helpers/graphql-helper";

import BillingErrorCode from "store/models/BillingErrorCode";
import InsuranceCompany from "store/models/InsuranceCompany";

const graphqlHelper = new GraphqlHelper();
export default class OrderBillingErrorCode extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "order_billing_error_code";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      created: this.attr(null),
      billingErrorAccession: this.attr(null),
      billingErrorCodeId: this.attr(null),
      billingErrorCode: this.belongsTo(BillingErrorCode, "billingErrorCodeId"),
      insuranceCompanyId: this.attr(null),
      insuranceCompany: this.belongsTo(InsuranceCompany, "insuranceCompanyId"),
      active: this.attr(false),
    };
  }

  static defaultData = [
    graphqlHelper.subQueryTagBuilder("billingErrorAccession", [
      graphqlHelper.subQueryTagBuilder("payorId", ["insuranceCompanyId"]),
    ]),
    graphqlHelper.subQueryTagBuilder("payorId", ["insuranceCompanyId"]),
    graphqlHelper.subQueryTagBuilder(
      "billingErrorCode",
      BillingErrorCode.defaultData
    ),
    "created",
    "active",
    "isAdded",
  ];
}
