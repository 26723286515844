import QueryModel from "store/models/QueryModel";

import Account from "store/models/Account";

import GraphqlHelper from "store/models/helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class DiagnosticCode extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "diagnostic_code";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      code: this.attr(""),
      description: this.attr(""),
      notes: this.attr([]),
      validityStartDate: this.attr(""),
      validityEndDate: this.attr(""),
      lastModified: this.attr(""),
      createdById: this.attr(null),
      createdBy: this.belongsTo(Account, "createdById"),
      created: this.attr(""),
    };
  }

  static defaultData = [
    "code",
    "description",
    "validityStartDate",
    "validityEndDate",
    "lastModified",
    graphqlHelper.subQueryTagBuilder("notes", ["note"]),
    graphqlHelper.subQueryTagBuilder("createdBy", ["email"]),
    "created",
  ];
}
