import { capitalize } from "lodash";

export default {
  name: "SummaryTable",
  props: {
    title: String,
    rows: Array,
    agingSummary: Boolean,
    minHeigth: String,
  },
  data() {
    return {
      showInfo: [],
    };
  },
  methods: {
    formatStatus(status) {
      if (!status) return;
      return capitalize(status);
    },
  },
};
