/* eslint-disable */
export default {
  methods: {
    buildQuery(queryName, fields, variables = null) {
      const queryWithParameterTagBuilder = (variables) => {
        const { parameters, query, setters } = variables;
        let queryParameter = [];

        //this will check if mutation parameter is set and will convert it to proper format
        // i.e. practiceId = practiceId : $practiceId
        if (variables.queryParameter && variables.queryParameter.length) {
          variables.queryParameter.forEach((param) => {
            queryParameter.push(param + ": $" + param);
          });
        }
        if (variables.additionalString && variables.additionalString.length) {
          variables.additionalString.forEach((additionalString) => {
            queryParameter.push(additionalString);
          });
        }
        let mergedQuery =
          queryParameter.length > 0
            ? query + "(" + queryParameter.join(",") + ")"
            : query + "()";
        let queryString =
          "query QueryString(" +
          parameters.join(",") +
          ")" +
          "{ " +
          mergedQuery +
          " { id " +
          setters.join(" ") +
          " } }";
        return queryString;
      };

      const queryTagBuilder = (entity, fields, settings = null) => {
        let entityArg = [];

        if (settings !== null) {
          if (settings.hasOwnProperty("orderBy") && settings.orderBy !== null)
            entityArg.push(`${settings.orderBy}:true`);
          if (settings.hasOwnProperty("order") && settings.order !== null)
            if (typeof settings.order === "object")
              entityArg.push(`${settings.order.by}:${settings.order.order}`);
          if (settings.hasOwnProperty("limit") && settings.limit !== null)
            entityArg.push(`limit: ${settings.limit}`);
          if (settings.hasOwnProperty("offset") && settings.offset !== null)
            entityArg.push(`offset: ${settings.offset}`);
          if (
            settings.hasOwnProperty("includeDeleted") &&
            settings.includeDeleted
          )
            entityArg.push(`includeDeleted: true`);
          if (
            settings.hasOwnProperty("additionalString") &&
            settings.additionalString &&
            settings.additionalString.length
          ) {
            for (let i = 0; i < settings.additionalString.length; i++) {
              entityArg.push(settings.additionalString[i]);
            }
          }

          if (settings.hasOwnProperty("filter") && settings.filter !== null) {
            var filterArg = "filter:{";
            const optimizedFilter = this.optimizeString(settings.filter);
            Object.keys(optimizedFilter).forEach((key, index) => {
              if (key === "logicalOperator") {
                if (optimizedFilter[key] === "AND") {
                  filterArg +=
                    index === Object.keys(optimizedFilter).length - 1
                      ? `${key}: AND}`
                      : `${key}: AND,`;
                } else {
                  filterArg +=
                    index === Object.keys(optimizedFilter).length - 1
                      ? `${key}: OR}`
                      : `${key}: OR,`;
                }
              } else if (
                key === "date" ||
                key === "lastCalibrationDate" ||
                key === "expiration" ||
                key === "expirationDate" ||
                key === "effectivityDate" ||
                key === "terminationDate" ||
                key === "generationDate"
              ) {
                let dateArg = `{gt: "${optimizedFilter[key].gt}", lt: "${optimizedFilter[key].lt}"}`;
                filterArg +=
                  index === Object.keys(optimizedFilter).length - 1
                    ? `${key}: ${dateArg}}`
                    : `${key}: ${dateArg},`;
              } else if (key === "amount") {
                let dateArg = `{from: "${optimizedFilter[key].from}", to: "${optimizedFilter[key].to}"}`;
                filterArg +=
                  index === Object.keys(optimizedFilter).length - 1
                    ? `${key}: ${dateArg}}`
                    : `${key}: ${dateArg},`;
              } else {
                // check first if settings filter key has enum prefix
                const enumRegex = new RegExp("enum_");
                const hasEnumPrefix = enumRegex.test(key);

                if (hasEnumPrefix) {
                  const newKey = key.replace("enum_", "");

                  filterArg +=
                    index === Object.keys(optimizedFilter).length - 1
                      ? `${newKey}: ${optimizedFilter[key]}}`
                      : `${newKey}: ${optimizedFilter[key]},`;
                } else {
                  if (typeof optimizedFilter[key] === "object") {
                    let filterObj = "{";
                    // get keys
                    const filterObjKeys = Object.keys(optimizedFilter[key]);

                    // remove isMultiField key
                    const findIsMultiFieldIndex = filterObjKeys.findIndex(
                      (filter) => {
                        return filter === "isMultiFields";
                      }
                    );
                    if (findIsMultiFieldIndex > -1) {
                      filterObjKeys.splice(findIsMultiFieldIndex, 1);
                    }

                    if (optimizedFilter[key].hasOwnProperty("isMultiFields")) {
                      for (let i = 0; i < filterObjKeys.length; i++) {
                        // create array string
                        let filterArrObj = "";
                        let arrayValue = [];
                        if (optimizedFilter[key][filterObjKeys[i]].length > 0) {
                          for (
                            let a = 0;
                            a < optimizedFilter[key][filterObjKeys[i]].length;
                            a++
                          ) {
                            const item =
                              optimizedFilter[key][filterObjKeys[i]][a];
                            arrayValue.push(`"${item}"`);
                          }
                          filterArrObj = arrayValue.join(",");
                          filterObj +=
                            i === filterObjKeys.length - 1
                              ? `${filterObjKeys[i]}: ${filterArrObj}}`
                              : `${filterObjKeys[i]}: ${filterArrObj},`;
                        }
                      }
                    } else {
                      for (let i = 0; i < filterObjKeys.length; i++) {
                        // create array string
                        let filterArrObj = "";
                        if (optimizedFilter[key][filterObjKeys[i]].length > 0) {
                          if (
                            typeof optimizedFilter[key][filterObjKeys[i]] !==
                            "string"
                          ) {
                            filterArrObj += "[";
                            for (
                              let a = 0;
                              a < optimizedFilter[key][filterObjKeys[i]].length;
                              a++
                            ) {
                              const item =
                                optimizedFilter[key][filterObjKeys[i]][a];
                              filterArrObj +=
                                a ===
                                optimizedFilter[key][filterObjKeys[i]].length -
                                  1
                                  ? `"${item}"]`
                                  : `"${item}",`;
                            }
                          } else {
                            filterArrObj = `"${
                              optimizedFilter[key][filterObjKeys[i]]
                            }"`;
                          }

                          filterObj +=
                            i === filterObjKeys.length - 1
                              ? `${filterObjKeys[i]}: ${filterArrObj}}`
                              : `${filterObjKeys[i]}: ${filterArrObj},`;
                        } else
                          filterObj +=
                            i === filterObjKeys.length - 1 ? "}" : "";
                      }
                    }

                    filterArg +=
                      index === Object.keys(optimizedFilter).length - 1
                        ? `${key}: ${filterObj}}`
                        : `${key}: ${filterObj},`;
                  } else {
                    if (typeof optimizedFilter[key] === "boolean") {
                      filterArg +=
                        index === Object.keys(optimizedFilter).length - 1
                          ? `${key}: ${optimizedFilter[key]}}`
                          : `${key}: ${optimizedFilter[key]},`;
                    } else {
                      filterArg +=
                        index === Object.keys(optimizedFilter).length - 1
                          ? `${key}: "${optimizedFilter[key]}"}`
                          : `${key}: "${optimizedFilter[key]}",`;
                    }
                  }
                }
              }
            });

            entityArg.push(filterArg);
          }
        }

        return (
          "{" +
          (entityArg.length
            ? `${entity}(${entityArg.join(",")})`
            : `${entity}`) +
          "{ id," +
          fields.join(",") +
          "}" +
          "}"
        );
      };

      let query;
      if (variables !== null && variables.hasOwnProperty("id")) {
        const parameters = ["$id: UUID!"];
        const queryParameter = ["id"];

        query = queryWithParameterTagBuilder({
          query: queryName,
          parameters,
          queryParameter,
          setters: fields,
        });
      } else {
        query = queryTagBuilder(queryName, fields);
      }

      return query;
    },
    buildSubQuery(entity, fields, hasId = true) {
      return (
        "" + entity + `{ ${hasId ? "id " : ""}` + fields.join(" ") + "}" + ""
      );
    },
  },
};
