const RouterLayout = import("layouts/RouterLayout");

const Analytics = import("modules/analytics");
const FinalReportedAccessions = import(
  "modules/analytics/FinalReportedAccessions"
);

const routes = [
  {
    path: "/analytics",
    meta: {
      slug: "analytics",
    },
    component: () => RouterLayout,
    children: [
      {
        path: "",
        name: "analytics",
        component: () => Analytics,
      },
      {
        path: "final-reported-accessions",
        name: "final-reported-accessions",
        meta: {
          slug: "final-reported-accessions",
        },
        component: () => FinalReportedAccessions,
      },
    ],
  },
];
export default routes;
