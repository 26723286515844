import { uuid } from "vue-uuid";

import Actions from "modules/actions";

export default {
  name: "EmailSection",
  props: {
    emails: {
      required: true,
      type: Array,
    },
    singleSelection: {
      required: false,
      type: Boolean,
    },
    withType: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    superScript: {
      required: false,
      type: Object, // { prefix: Boolean, value: String  }
    },


    hideAdd: { 
      default: false,
      type: Boolean,
    },

    hideRemove:{ 
      default: false,
      type: Boolean,
    },

    withTag: { 
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      actions: new Actions(),
      defaultEmail: "",
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (val.id && val.id !== null) || "This field is required.";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
        validateEmailType: (val, email) => {
          return email.length > 0
            ? (val !== null && val.length > 0) || "This field is required."
            : true;
        },
      },
      emailTypes: [],
    };
  },
  computed: {
    __Emails: {
      get() {
        const defaultEmail = this.emails.find((email) => email.isDefault);

        if (defaultEmail) this.defaultEmail = defaultEmail.uuid;

        return this.emails;
      },
      set(value) {
        this.$emit("update:emails", value);
      },
    },
  },
  methods: {
    async getEmailTypes() {
      try {
        if (!this.emailTypes.length) {
          this.emailTypes = [
            { label: "Fetching email types...", disabled: true },
          ];

          const result = await this.actions.getEmailTypes();

          if (result) {
            this.emailTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    addEmail() {
      this.__Emails.push({
        id: null,
        uuid: uuid.v1(),
        isDefault: false,
        email: "",
        emailTypeId: null,
      });
    },
    removeEmail(emailUUID) {
      const findIndex = this.__Emails.findIndex(
        (email) => email.uuid === emailUUID
      );

      if (findIndex > -1) this.__Emails.splice(findIndex, 1);

      this.$nextTick(() => {
        this.updateDefaultEmail(this.defaultEmail);
      });

      this.$refs.form.resetValidation();
    },
    updateDefaultEmail(e) {
      this.__Emails.map((email) => {
        email.isDefault = false;
        return email;
      });

      const findIndex = this.__Emails.findIndex((email) => email.uuid === e);
      const setIndex = findIndex > -1 ? findIndex: 0;

      this.__Emails[setIndex].isDefault = true;
      this.defaultEmail = this.__Emails[setIndex].uuid;
    },
  },
  async mounted() {
    if (!this.singleSelection || this.__Emails.length) {
      await this.getEmailTypes();
    }
  },
};
