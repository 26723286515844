const RouterLayout = import("layouts/RouterLayout");

const Accession = import("modules/orders/Accession");
const Listing = import("modules/orders/Listing");
const OrderEntry = import("modules/orders/OrderEntry");

const routes = [
  {
    path: "/orders",
    meta: {
      slug: "orders",
    },
    component: () => RouterLayout,
    children: [
      {
        path: "",
        name: "orders",
        component: () => Listing,
      },
      {
        path: "accession-details/:id",
        name: "accession-details",
        meta: {
          slug: "accession-details",
        },
        component: () => Accession,
      },
      {
        path: "order-entry/:id",
        name: "order-entry",
        meta: {
          label: "Order Entry Details",
          slug: "order-entry",
        },
        component: () => OrderEntry,
      },
    ],
  },
];
export default routes;
