export default class StoreActions {
  constructor(model) {
    this.model = model;
  }
  /**
   *
   * @param {*} variables = {
   *  property: { // (optional) for getting query without using id as main parameter
   *    email: { // for passing args in the main query
   *      type: String, value: ""
   *    }
   *    ["orderBillingErrorCodes.orderByDate"]: { // for passing args in subqueries
   *      type: "DateSort",
   *      value: "ASC",
   *    },
   *    ["transactions.limit"]: { // for passing limit and/or offset for paginated subqueries
   *      type: "Int",
   *      value: "15",
   *    }
   *  } 
   * }
   * @param {*} options = {
   *  relativeUrl: String,
   *  limitData: Array,
   *  queryName: String,
   *  limit: Number,
   *  offset: Number,
   *  filter: Object (e.g) sample {name: ""},
   *  includeDeleted: Boolean,
   *  additionalString: String,
   *  order: Number
   *  enumQuery: String
   * }
   * @param {*} description = ""
   *
   */
  async query(options, description, variables = null) {
    const result = await this.model
      .api()
      .query(options, description, variables);
    if (result.response.status === 200) return result.response.data;
  }

  /**
   *
   * @param {*} variables = Object || Null
   * @param {*} options = Object {
   *  relativeUrl: String,
   *  queryName: String,
   *  queries: {
   *    addReagentItem: {
   *      reagentItemId: {
   *        value: "",
   *        type: String, Number, etc.,
   *      }
   *    }
   *  }
   *
   *  collectionQueries: [{
   *    addReagentItem: {
   *      reagentItemId: {
   *        value: "",
   *        type: String, Number, etc.,
   *      }
   *    }
   *  }]
   *
   *  childQueries: [{
   *    insured: {
   *      updateName: {
   *        firstName: {
   *          value: "",
   *          type: String, Number, etc.,
   *        },
   *        lastName: {
   *          value: "",
   *          type: String, Number, etc.,
   *        }
   *      }
   *    }
   *  }]
   * *1:1 relationship
   *
   *  booleanQueries: ["generateOrderBillingErrorCode"]
   * }
   * @param {*} description = String
   *
   */
  async mutation(options, description, variables = null) {
    const result = await this.model
      .api()
      .mutation(options, description, variables);

    if (result.response.status === 200 || result.response.status === 201)
      return result.response.data;
  }
}
