export default {
  name: "NotificationModal",
  props: {
    promptVisibility: {
      required: true,
      type: Boolean,
    },
    message: {
      required: false,
      type: String,
    },
    emitData: {
      required: false,
      type: [String, Object, Array],
    },
    positiveBtnTxt: {
      required: false,
      type: String,
    },
    negativeBtnTxt: {
      required: false,
      type: String,
    },
    negativeBtnColor: String,
    positiveBtnColor: String,
    negativeBtnPos: String,
    positiveBtnPos: String,
    width: String,
    textCenter: Boolean,
    noDivider: Boolean,
    buttonsElevation: Number,
    isLoading: Boolean,
  },
  computed: {
    __PromptVisibility: {
      get() {
        return this.promptVisibility;
      },
      set(value) {
        this.$emit("update:promptVisibility", value);
      },
    },
  },
  methods: {
    doOk() {
      this.$emit("doOk", this.emitData);
    },

    doCancel() {
      this.__PromptVisibility = false;
      this.$emit("doCancel");
    },
  },
};
