import { Model } from "@vuex-orm/core";
// import GraphqlHelper from "./helpers/graphql-helper";

// const graphqlHelper = new GraphqlHelper();
export default class Phlebotomist extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "phlebotomist";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      firstName: this.string(),
      middleName: this.string(),
      lastName: this.string(),
      created: this.attr(null),
      isDeleted: this.attr(null),
      totalCount: this.attr(null),
    };
  }
  // Setup to call an endpoint
  // static apiConfig = {
  //   actions: {
  //     async all(
  //       limit = null,
  //       offset = null,
  //       filter = null,
  //       limitData = null,
  //       isDataManagement = false,
  //       includeDeleted = false,
  //       additionalString = null,
  //       order = null
  //     ) {
  //       let defaultData = ["id", "firstName", "middleName", "lastName"];

  //       let route = isDataManagement
  //         ? "/data-management/all-phlebotomist"
  //         : "/accession/step5/get-phlebotomists";

  //       if (limitData !== null && limitData !== "") {
  //         defaultData = limitData;
  //       }

  //       let queryString = graphqlHelper.queryTagBuilder(
  //         "phlebotomists",
  //         defaultData,
  //         {
  //           limit,
  //           offset,
  //           filter,
  //           includeDeleted,
  //           additionalString,
  //           order
  //         }
  //       );
  //       const result = await this.post(route, {
  //         query: queryString
  //       });
  //       return result;
  //     },
  //     async createPhlebotomist(variables) {
  //       try {
  //         const parameters = [];
  //         const mutationParameter = [];
  //         const setters = [];

  //         if (
  //           variables.hasOwnProperty("firstName") &&
  //           variables.firstName.length
  //         ) {
  //           mutationParameter.push("firstName");
  //           parameters.push("$firstName: String!");
  //           setters.push("firstName");
  //         }
  //         if (
  //           variables.hasOwnProperty("lastName") &&
  //           variables.lastName.length
  //         ) {
  //           mutationParameter.push("lastName");
  //           parameters.push("$lastName: String!");
  //           setters.push("lastName");
  //         }
  //         if (
  //           variables.hasOwnProperty("middleName") &&
  //           variables.middleName.length
  //         ) {
  //           mutationParameter.push("middleName");
  //           parameters.push("$middleName: String");
  //           setters.push("middleName");
  //         }

  //         const param = {
  //           parameters,
  //           mutation: "createPhlebotomist",
  //           mutationParameter,
  //           setters
  //         };
  //         const mutation = graphqlHelper.mutationTagBuilder(param);
  //         const result = await this.post(
  //           "/accession/step5/create-phlebotomist",
  //           {
  //             mutation,
  //             variables: JSON.stringify(variables)
  //           }
  //         );
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async updatePhlebotomistDetails(variables) {
  //       try {
  //         const parameters = ["$phlebotomistId: UUID!"];
  //         const mutationParameter = ["phlebotomistId"];
  //         const setters = [];

  //         if (
  //           variables.hasOwnProperty("updateName") &&
  //           Object.keys(variables.updateName).length
  //         ) {
  //           parameters.push(`$firstName: String`);
  //           parameters.push(`$middleName: String`);
  //           parameters.push(`$lastName: String`);

  //           setters.push(
  //             `updateName(firstName: $firstName middleName: $middleName lastName: $lastName)`
  //           );

  //           variables[`firstName`] = variables.updateName.firstName;
  //           variables[`middleName`] = variables.updateName.middleName;
  //           variables[`lastName`] = variables.updateName.lastName;
  //           delete variables.updateName;
  //         }

  //         const param = {
  //           parameters,
  //           mutation: "updatePhlebotomistDetails",
  //           mutationParameter,
  //           setters
  //         };

  //         const mutation = graphqlHelper.mutationTagBuilder(param);
  //         const result = await this.post(
  //           "/accession/step5/update-phlebotomist-details",
  //           {
  //             mutation,
  //             variables: JSON.stringify(variables)
  //           }
  //         );
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async getById(id, limitData = null) {
  //       let setters = [
  //         "created",
  //         "isDeleted",
  //         "firstName",
  //         "lastName",
  //         "middleName",
  //         "totalCount"
  //       ];

  //       if (limitData && typeof limitData == "object") setters = limitData;

  //       const query = graphqlHelper.queryWithParameterTagBuilder({
  //         parameters: ["$id: UUID!"],
  //         query: "getPhlebotomist",
  //         queryParameter: ["id"],
  //         additionalString: ["includeDeleted:true"],
  //         setters
  //       });
  //       const result = await this.post(
  //         "/data-management/phlebotomist/get-phlebotomist",
  //         {
  //           query,
  //           variables: JSON.stringify({ id })
  //         }
  //       );
  //       return [result];
  //     },

  //     async delete(variables, isDataManagement = false) {
  //       try {
  //         let route = isDataManagement
  //           ? "/data-management/phlebotomist/delete-phlebotomist"
  //           : "/data-management/phlebotomist/delete-phlebotomist";
  //         const result = await this.post(route, {
  //           mutation: "mutation ($id: UUID!) { deletePhlebotomist(id: $id) }",
  //           variables: JSON.stringify(variables)
  //         });
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     }
  //   }
  // };
}
