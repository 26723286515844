import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";

import Physician from "store/models/Physician";
import OrderBillingDiagnosticCode from "store/models/OrderBillingDiagnosticCode";
import Patient from "store/models/Patient";
import DiagnosticCode from "store/models/DiagnosticCode";
import TRF from "store/models/TRF";
import Test from "store/models/Test";
import Sample from "store/models/Sample";
import Insurance from "store/models/Insurance";
import PayorAccount from "store/models/PayorAccount";
import PatientSignature from "store/models/PatientSignature";

const graphqlHelper = new GraphqlHelper();
// const orderHelper = new OrderHelper();
export default class Accession extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "accession";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      patientSignatureId: this.attr(null),
      dateSampleTaken: this.string(""),
      dateFirstTransmitted: this.attr(""),
      billingNetsuiteStatus: this.attr(""),
      netsuiteInvoice: this.attr(""),
      billedAmount: this.attr(""),
      balanceDue: this.attr(""),
      priceDate: this.attr(""),
      billingAccessionStatus: this.attr(""),
      billingStatementStatus: this.attr(""),
      sampleReceiveDate: this.attr(null),
      testRequisitionFormId: this.attr(null),
      patientId: this.attr(null),
      testId: this.attr(null),
      internalId: this.attr(null),
      diagnosticCodeId: this.attr(null),
      status: this.attr(null),
      created: this.attr(null),
      physicianId: this.attr(null),
      orderPatientStatus: this.attr(null),
      physician: this.belongsTo(Physician, "physicianId"),
      diagnosticCodes: this.belongsTo(DiagnosticCode, "diagnosticCodeId"),
      referingPhysicianId: this.attr(null),
      referingPhysician: this.belongsTo(Physician, "referingPhysicianId"),
      billingDiagnosticCodeId: this.attr(null),
      billingDiagnosticCode: this.belongsTo(
        OrderBillingDiagnosticCode,
        "billingDiagnosticCodeId"
      ),
      patient: this.belongsTo(Patient, "patientId"),
      test: this.belongsTo(Test, "testId"),
      usedInsurance: this.attr(null),
      notes: this.attr(null),
      payorAccountId: this.attr(null),
      payorAccount: this.belongsTo(PayorAccount, "payorAccountId"),
      trfId: this.attr(null),
      trf: this.belongsTo(TRF, "trfId"),
      sampleId: this.attr(null),
      sample: this.belongsTo(Sample, "sampleId"),
      patientSignature: this.belongsTo(PatientSignature, "patientSignatureId"),
      priorAuthorization: this.attr(null),
    };
  }
  static defaultData = [
    "internalId",
    "status",
    "created",
    "orderPatientStatus",
    "dateSampleTaken",
    "dateFirstTransmitted",
    "billingNetsuiteStatus",
    "billingAccessionStatus",
    "billingStatementStatus",
    "netsuiteInvoice",
    "billedAmount",
    "balanceDue",
    "priceDate",
    "priorAuthorization",
    graphqlHelper.subQueryTagBuilder("notes", [
      "created",
      "note",
      graphqlHelper.subQueryTagBuilder("createdBy", ["username"]),
    ]),
    graphqlHelper.subQueryTagBuilder("trf", ["testRequisitionFormId"]),
    graphqlHelper.subQueryTagBuilder("sample", ["receiveDate", "sampleId"]),
    graphqlHelper.subQueryTagBuilder("payorAccount", [
      "payorCode",
      "payorName",
    ]),
    graphqlHelper.subQueryTagBuilder(
      "referingPhysician",
      Physician.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("physician", Physician.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "billingDiagnosticCode",
      OrderBillingDiagnosticCode.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("patient", Patient.defaultData),
    graphqlHelper.subQueryTagBuilder("test", Test.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "diagnosticCodes",
      DiagnosticCode.defaultData
    ),
    "totalCount",
    graphqlHelper.subQueryTagBuilder("usedInsurance", [
      "isDefault",
      graphqlHelper.subQueryTagBuilder("insurance", [Insurance.defaultData]),
    ]),
    graphqlHelper.subQueryTagBuilder(
      "patientSignature",
      PatientSignature.defaultData
    ),
  ];
}
