import QueryModel from "store/models/QueryModel";

import GraphqlHelper from "store/models/helpers/graphql-helper";

import DiagnosticCode from "store/models/DiagnosticCode";
import Account from "store/models/Account";

const graphqlHelper = new GraphqlHelper();
export default class OrderBillingDiagnosticCode extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "order_billing_diagnostic_code";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      diagnosticCodeId: this.attr(null),
      diagnosticCode: this.belongsTo(DiagnosticCode, "diagnosticCodeId"),
      addedById: this.attr(null),
      addedBy: this.belongsTo(Account, "addedById"),
      addedDate: this.attr(""),
      notes: this.attr(""),
      active: this.attr(false),
      sortOrder: this.attr(null)
    };
  }

  static defaultData = [
    graphqlHelper.subQueryTagBuilder(
      "diagnosticCode",
      DiagnosticCode.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("addedBy", Account.defaultData),
    "addedDate",
    "notes",
    "active",
    "sortOrder"
  ];
}
