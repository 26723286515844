import Account from "./Account";
import QueryModel from 'store/models/QueryModel'

export default class BillingClaimsSubmission extends QueryModel {
  static entity = "billing_claims_submission";

  static fields() {
    return {
      id: this.attr(null),
      date: this.attr(null),
      payorId: this.attr(null),
      requestor276: this.attr(null),
      transactionDate: this.attr(null),
      clearinghouseIcn: this.attr(null),
      payorIcn: this.attr(null),
      updateDate: this.boolean(null),
      updatePayorId: this.boolean(null),
      updateRequestor276: this.boolean(null),
      updateTransactionDate: this.boolean(null),
      updateClearinghouseIcn: this.boolean(null),
      updatePayorIcn: this.boolean(null),
      totalCount: this.number(0),
      createdBy: this.belongsTo(Account, "payorId"),
      isDeleted: this.boolean(null),
      created: this.attr(null),
      lastModified: this.attr(null)
    };
  }

  static defaultData = [
    "id",
    "date",
    "payorId",
    "requestor276",
    "transactionDate",
    "clearinghouseIcn",
    "payorIcn",
  ];
}
