import QueryModel from "store/models/QueryModel";

export default class PaymentTerm extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "paymentTerm";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      label: this.attr(""),
      created: this.attr(""),
      lastModified: this.attr(""),
      totalCount: this.number(0),
      updateLabel: this.boolean(false),
      createdBy: this.attr(null),
      isDeleted: this.boolean(false)
    };
  }

  static defaultData = [
    "label",
    "created",
    "lastModified",
    "totalCount",
    "updateLabel",
    "createdBy",
    "isDeleted"
  ];
}
