import QueryModel from "store/models/QueryModel";

export default class AuditLog extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "audit_log";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      module: this.attr(""),
      affectedRecordId: this.attr(null),
      affectedColumnName: this.attr(""),
      affectedColumnLabel: this.attr(""),
      oldValue: this.attr(""),
      newValue: this.attr(""),
      message: this.attr(""),
      action: this.attr(""),
      created: this.attr(""),
      totalCount: this.attr(0),
    };
  }

  static defaultData = [
    "module",
    "message",
    "created",
    "action",
    "totalCount",
  ];
}
