import Actions from "modules/actions/client-invoice-actions";

export default {
  name: "CIAccordion",
  props: {
    data: Object,
    id: String
  },
  data() {
    return {
      actions: new Actions(),
    };
  },
  methods: {
    async view() {
      const url = await this.actions.viewClientInvoice({
        property: {
          clientInvoiceId: {
            type: "UUID!",
            value: this.id,
          },
          revisionNumber: {
            type: "Int",
            value: this.data.revisionNumber,
          },
        },
      });
      window.open(url);
    },
    resend() {
      console.log("resend");
    },
  },
};
