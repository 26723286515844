import { isArray } from "lodash";

export default {
  name: "SearchFilter",
  props: {
    options: Array,
    customSearch: Boolean,
    modelInstance: Function,
    columns: Number | String,
    limitData: Array,
    limit: Number,
    parentEntity: String,
    parentId: String,
    additionalString: Array,
    additionalFilter: Object,
    hideDeleted: Boolean,
    hasArchive: Boolean,
    hasArchiveText: String,
    hasInactive: Boolean,
    hasInactiveText: String,
    hideSearchFilter: Boolean,
    isAuditTrail: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    customExpansionTitle: String,
    removeLogicalOperation: Boolean,
    relativeUrl: String,
    queryName: String,
    allowFutureDates: Boolean,
    defaultSort: Object,
    auditTrail: Object,
    hideBottomActions: Boolean,
    shouldNotReturnId: {
      type: Boolean,
      default: false,
    },
    resetButtonText: {
      type: String,
      default: "Reset",
    },
    searchButtonText: {
      type: String,
      default: "Search",
    }
  },
  data() {
    return {
      test: "",
      date: {
        EFFECTIVITYDATE: { lt: "", gt: "" },
        TERMINATIONDATE: { lt: "", gt: "" },
        VALIDITYSTARTDATE: { lt: "", gt: "" },
        VALIDITYENDDATE: { lt: "", gt: "" },
        DATESAMPLERECEIVED: { lt: "", gt: "" },
        CREATED: { lt: "", gt: "" },
        DATEFIRSTTRANSMITTED: { lt: "", gt: "" },
      },
      offset: null,
      currentLimit: 0,
      includeArchive: false,
      includeDeleted: false,
      includeInactive: false,
      dataFields: [],
      dateMenu1: {
        EFFECTIVITYDATE: false,
        TERMINATIONDATE: false,
        VALIDITYSTARTDATE: false,
        VALIDITYENDDATE: false,
        CREATED: false,
        DATESAMPLERECEIVED: false,
        DATEFIRSTTRANSMITTED: false,
      },
      dateMenu2: {
        EFFECTIVITYDATE: false,
        TERMINATIONDATE: false,
        VALIDITYSTARTDATE: false,
        VALIDITYENDDATE: false,
        CREATED: false,
        DATESAMPLERECEIVED: false,
        DATEFIRSTTRANSMITTED: false,
      },
      filteredOptions: [],
      fieldCol: "col-12",
      searchValue: "",
      menu1: false,
      menu2: false,
      rules: {
        validDate: (val) =>
          !val || this.isValidDate(val) || "Invalid date format.",
      },
      singleMenu: {},
      singleDate: {},
    };
  },
  watch: {
    searchValue(val) {
      if (!val) return;

      clearTimeout(this.__timerId);
      this.__timerId = setTimeout(async () => {
        await this.filterOptions(val, true);
      }, 500);
    },
  },
  computed: {
    dataTable() {
      return this.$parent.$parent.$refs.dataTable || null;
    },
    buildSearchData() {
      let searchData = {};
      if (this.dataFields && this.dataFields.length) {
        for (let i = 0; i < this.dataFields.length; i++) {
          const dataField = this.dataFields[i];
          const isDateFilterField = [
            "validityStartDate",
            "validityEndDate",
            "effectivityDate",
            "terminationDate",
            "created",
          ].includes(dataField.name);
          const isAllowedSingleDateInput = ([
            "payorAccounts",
            "physicians",
            "sampleStorageLaboratories",
            "insuranceCompanies",
            "cptCodes",
            "diagnosticCodes",
            "vbsFeeSchedules",
            "vbsRevenueCodes",
            "vbsConversionRules",
            "orders",
          ].includes(this.queryName) && isDateFilterField)
          || this.isAuditTrail;
          const hasDateFilter = isAllowedSingleDateInput
            ? dataField.value?.lt || dataField.value?.gt
            : dataField.value?.lt && dataField.value?.gt;
          if (
            (dataField.value || typeof dataField.value === "boolean") &&
            !searchData[dataField.name] &&
            dataField.value !== "" &&
            (dataField.value.length > 0 ||
              hasDateFilter ||
              (dataField.type == "amount" &&
                dataField.value.from !== "" &&
                dataField.value.to !== "") ||
              typeof dataField.value === "boolean")
          ) {
            if (dataField.isEnum) {
              if (
                dataField.value[0] &&
                typeof dataField.value[0] === "object"
              ) {
                let arrayValue = [];
                for (let e = 0; e < dataField.value.length; e++) {
                  arrayValue.push(dataField.value[e].value);
                }
                searchData[`enum_${dataField.name}`] = `[${arrayValue.join(
                  ","
                )}]`;
              } else {
                if (dataField.singleSelect) {
                  searchData[`enum_${dataField.name}`] = `${dataField.value}`;
                } else
                  searchData[
                    `enum_${dataField.name}`
                  ] = `[${dataField.value.join(",")}]`;
              }
            } else if (dataField.type === "date") {
              const key =
                dataField.name || this.isAuditTrail
                  ? dataField.name
                  : "date";
              const gt = dataField.value.gt;
              const lt = dataField.value.lt;
              searchData[key] = {
                gt: gt
                  ? this.$options.filters.parseDateFromString(gt, "YYYY-MM-DD")
                  : "",
                lt: lt
                  ? this.$options.filters.parseDateFromString(lt, "YYYY-MM-DD")
                  : "",
              };
              const withTimeInput = isDateFilterField || this.isAuditTrail;
              if (withTimeInput) {
                searchData[key].gt = this.getDatePayload(searchData[key].gt, "gt", dataField.asUtcDate);
                searchData[key].lt = this.getDatePayload(searchData[key].lt, "lt", dataField.asUtcDate);
              }
            } else if (dataField.type == "amount") {
              let key = dataField.name;
              if (dataField.value.from && dataField.value.to)
                searchData[key] = {
                  from: dataField.value.from,
                  to: dataField.value.to,
                };
            } else if (dataField.isMultiFields) {
              if (dataField.searchBy && dataField.searchBy.length) {
                for (let x = 0; x < dataField.searchBy.length; x++) {
                  searchData[dataField.searchBy[x]] = dataField.value;
                }
              } else {
                let fields = dataField.fields;
                let dataObj = {};
                for (let x = 0; x < fields.length; x++) {
                  dataObj[fields[x]] = [dataField.value];
                  dataObj["isMultiFields"] = true;
                }
                searchData[dataField.name] = dataObj;
              }
            } else {
              // check if datafield value is array or not

              if (typeof dataField.value === "object") {
                let dataFieldObj = {};

                // get data field keys and assign to dataFieldObj
                const dataFieldKeys = Object.keys(dataField.value[0].value);
                if (dataFieldKeys.indexOf("id") != -1) {
                  for (let a = 0; a < dataFieldKeys.length; a++) {
                    dataFieldObj["id"] = [];
                  }
                } else {
                  for (let a = 0; a < dataFieldKeys.length; a++) {
                    dataFieldObj[dataFieldKeys[a]] = [];
                  }
                }

                // populate dataFieldObj
                for (let k = 0; k < dataField.value.length; k++) {
                  // get object keys
                  const fObj = dataField.value[k].value;
                  const fObjKeys = Object.keys(fObj);
                  if (dataFieldObj.id) {
                    dataFieldObj["id"].push(fObj["id"]);
                  } else {
                    for (let b = 0; b < fObjKeys.length; b++) {
                      if (
                        dataFieldObj[fObjKeys[b]] &&
                        fObj[fObjKeys[b]] !== null &&
                        fObj[fObjKeys[b]] !== ""
                      ) {
                        dataFieldObj[fObjKeys[b]].push(fObj[fObjKeys[b]]);
                      }
                    }
                  }
                }

                // To handle mismatch name and filter prop
                if (dataField.filterProp && dataField.filterProp)
                  searchData[dataField.filterProp] = dataFieldObj;
                else searchData[dataField.name] = dataFieldObj;
              } else {
                // To check if data is related to other dataFields `name`
                if (dataField.belongsTo)
                  if (searchData[dataField.belongsTo])
                    // To check if the prop name is added
                    searchData[dataField.belongsTo][dataField.name] =
                      dataField.value;
                  else {
                    // create the the prop name value of dataFields.belongsTo
                    searchData[dataField.belongsTo] = {};
                    searchData[dataField.belongsTo][dataField.name] =
                      dataField.value;
                  }
                else searchData[dataField.name] = dataField.value;
              }
            }
          } else {
            if (
              dataField.type != "date" &&
              dataField.value !== "" &&
              dataField.value
            ) {
              if (dataField.value.value) {
                if (dataField.filterProp) {
                  if (dataField.filterProp === dataField.name) {
                    let objVal = {};
                    objVal[dataField.filterProp] =
                      dataField.value.value[dataField.filterProp];
                    searchData[dataField.name] = objVal;
                  }
                } else searchData[dataField.name] = dataField.value.value;
              }
            }
          }
        }

        if (this.includeArchive) searchData["enum_archive"] = "true";
        if (this.includeInactive) searchData["enum_inactive"] = "true";
        if (this.isAuditTrail) {
          searchData.affectedRecordId = this.auditTrail.recordId;
          searchData.enum_module = this.auditTrail.module;
          searchData.enum_patternMatch = "LIKE";
        }
        if (!this.removeLogicalOperation) searchData["logicalOperator"] = "AND";
      }

      // add parent entity to search data
      if (this.parentEntity && this.parentId && this.parentEntity != "") {
        searchData[this.parentEntity] = {
          isMultiFields: true,
          id: [this.parentId],
        };
      }

      // add custom additional filters
      if (
        this.additionalFilter !== undefined &&
        Object.keys(this.additionalFilter).length > 0
      ) {
        const additionalFilterKeys = Object.keys(this.additionalFilter);

        for (let a = 0; a < additionalFilterKeys.length; a++) {
          searchData[additionalFilterKeys[a]] =
            this.additionalFilter[additionalFilterKeys[a]];
        }
      }

      if (!this.removeLogicalOperation) searchData["logicalOperator"] = "AND";

      return searchData;
    },
  },
  methods: {
    formateDateName(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      if (!this.isValidDate(date)) return "";
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onInput(ref) {
      if (!this.$refs[ref][0].inputValue.length) return;
      this.$refs[ref][0].inputValue = "";
      this.$refs[ref][0].filter("");
    },
    validateDateRange(to, from) {
      if (!to || !from) return true;
      return this.$options.filters.isDateBefore(from, to);
    },
    onBlurDate(name, label) {
      const date = this.date[name.toUpperCase()];
      const isValid = this.validateDateRange(date.lt, date.gt);
      if (!isValid) {
        this.toggleInvalidDateRateError(`${label} To`, `${label} From`);
        return;
      }
    },
    validateEffectiveAndTermination() {
      const effectiveFields = ["effectivityDate", "validityStartDate"];
      const terminationFields = ["terminationDate", "validityEndDate"];
      const effectiveDate = this.dataFields.find(({ name }) =>
        effectiveFields.includes(name)
      );
      const terminationDate = this.dataFields.find(({ name }) =>
        terminationFields.includes(name)
      );
      if (!effectiveDate || !terminationDate) return true;
      if (!effectiveDate.value.gt || !terminationDate.value.gt) return true;
      const isValid = this.validateDateRange(
        terminationDate.value.gt,
        effectiveDate.value.gt
      );
      if (!isValid)
        this.toggleInvalidDateRateError(
          "Termination Date From",
          "Effective Date From"
        );
      return isValid;
    },
    validateDateFields() {
      let areDatesValid = true;
      this.dataFields.map(async (item, i) => {
        if (item.type !== "date") return;
        if (!item.value) return;
        const { gt, lt } = item.value;
        if (!gt || !lt) return;
        const isValid = this.validateDateRange(lt, gt);
        if (!isValid) {
          areDatesValid = false;
          const label = this.options[i].label;
          this.toggleInvalidDateRateError(`${label} To`, `${label} From`);
        }
      });
      return areDatesValid;
    },
    toggleInvalidDateRateError(to, from) {
      this.showNotifyMessage({
        message: `"${to}" should not be less than the "${from}."`,
        type: "danger",
      });
    },
    closeDateModal(index, type = "SINGLE", { name, label } = null) {
      if (type.toUpperCase() !== "SINGLE") {
        const theDate = this.date[name.toUpperCase()];

        if (theDate.lt && theDate.gt && theDate.gt > theDate.lt) {
          this.toggleInvalidDateRateError(`${label} To`, `${label} From`);

          return;
        }

        if (theDate.lt) {
          this.dataFields[index].value.lt = this.formatDate(theDate.lt);

          if (!this.dataFields[index].value.gt.length) {
            this.dataFields[index].value.gt = this.formatDate(theDate.lt);
            theDate.gt = theDate.lt;
          }
        }

        if (theDate.gt) {
          this.dataFields[index].value.gt = this.formatDate(theDate.gt);

          if (!this.dataFields[index].value.lt.length) {
            this.dataFields[index].value.lt = this.formatDate(theDate.gt);
            theDate.lt = theDate.gt;
          }
        }

        if (isArray(this.$refs.menu1))
          this.$refs.menu1.forEach((menu) => {
            menu.save();
          });
        else this.$refs.menu1.save();

        if (isArray(this.$refs.menu2))
          this.$refs.menu2.forEach((menu) => {
            menu.save();
          });
        else this.$refs.menu2.save();
      } else {
        this.dataFields[index].value = this.formatDate(
          this.singleDate[this.dataFields[index].name]
        );
        this.singleMenu[this.dataFields[index].name] = false;
      }
    },

    async onSubmit(limit = null, offset = null) {
      const validate = await this.$refs.form.validateAsync();
      if (!validate) return;

      await this.filterSearchModel(limit, offset);
    },

    async filterSearchModel(
      limit = null,
      offset = null,
      returnResults = false,
      order = null
    ) {
      if (!this.validateEffectiveAndTermination()) return;
      if (!this.validateDateFields()) return;
      if (this.dataTable) {
        this.dataTable.setError(false);
      }

      if (this.customSearch) {
        this.offset = offset;
        this.$emit(
          "searchResults",
          this.convertBuildSearchToFilterString(limit, offset)
        );
        return;
      }
      if (!order && this.defaultSort) order = this.defaultSort;
      try {
        this.$emit("loading", true);
        this.offset = offset;
        this.currentLimit = limit;
        const options = {
          relativeUrl: this.relativeUrl,
          offset,
          filter: this.isEmpty(this.buildSearchData)
            ? null
            : this.buildSearchData,
          includeDeleted: this.includeDeleted,
          additionalString:
            typeof this.additionalString == "object"
              ? this.additionalString
              : null,
          order,
          queryName: this.queryName,
        };


        if (
          (typeof limit == "object" || limit == null) &&
          this.limit != undefined
        ) {
          limit = this.limit;
          options["limit"] = limit;
        } else {
          options["limit"] = limit;
        }

        if (this.limitData) options["limitData"] = this.limitData;

        const filterSearchResult = await this.modelInstance
          .api()
          .query(options, "to get all test");
        if (filterSearchResult && filterSearchResult.response.status === 200) {
          if (returnResults) return filterSearchResult.response.data;
          else this.$emit("searchResults", filterSearchResult.response.data);
          this.$emit("loading", false);
        } else {
          this.showNotifyMessage({
            message: "Unable to fetch data.",
            actions: [
              {
                label: "Retry",
                color: "white",
                handler: () => {
                  this.filterSearchModel(limit, offset, returnResults, order);
                },
              },
            ],
            type: "danger",
          });
          if (this.dataTable) {
            this.dataTable.setError(true, {
              limit,
              offset,
              returnResults,
              order,
            });
          }
          this.$emit("loading", false);
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          actions: [
            {
              label: "Retry",
              color: "white",
              handler: () => {
                this.filterSearchModel(limit, offset, returnResults, order);
              },
            },
          ],
          type: "danger",
        });
        if (this.dataTable) {
          this.dataTable.setError(true, {
            limit,
            offset,
            returnResults,
            order,
          });
        }
        this.$emit("loading", false);

        // throw new Error(err);
      }
    },
    async resetFilter() {
      for (let i = 0; i < this.dataFields.length; i++) {
        const dataField = this.dataFields[i];
        if (dataField.type === "date") {
          dataField.value = { gt: "", lt: "" };
          this.date[dataField.name.toUpperCase()] = { gt: "", lt: "" };
        } else if (dataField.type === "singleDate") {
          dataField.value = "";
          this.singleDate[dataField.name] = "";
        } else {
          if (dataField.type === "lazy-loading")
            this.$refs[dataField.name][0].clearFields();

          dataField.value = typeof dataField.value === "string" ? "" : [];
        }
      }

      this.includeDeleted = false;
      this.includeArchive = false;

      this.$emit("resetDataTableOptions");

      await this.filterSearchModel(this.limit, null);
    },
    createDataFields(options = this.options) {
      if (options.length) {
        for (let i = 0; i < options.length; i++) {
          const option = options[i];
          // find if field already exist
          const hasField = this.dataFields.filter((field) => {
            return field.name === option.name;
          });

          if (!hasField.length) {
            // add filtered options
            if (option.multiSelect && option.multiSelect) {
              const hasFilteredOptions = this.filteredOptions.find((option) => {
                return option.name === option.name;
              });

              if (!hasFilteredOptions) {
                this.filteredOptions.push({
                  name: option.name,
                  options: option.selectOptions || [],
                });
              }
            }

            // add data field
            let filterOptionIndex = this.filteredOptions.findIndex((filter) => {
              return filter.name === option.name;
            });

            const dataFieldData = {
              name: option.name,
              type: option.type,
              value: option.defaultValue
                ? option.defaultValue
                : option.multiSelect && option.multiSelect
                ? []
                : option.type == "date"
                ? { lt: "", gt: "" }
                : option.type == "amount"
                ? { from: "", to: "" }
                : "",
              filteredOptionIndex: filterOptionIndex > -1 ? filterOptionIndex : null,
              isEnum: !!option?.isEnum,
              isMultiFields: !!option?.isMultiFields,
              searchBy: option?.searchBy || null,
              fields: option?.fields || null,
              format: option?.format || null,
              filterProp: option?.filterProp || null,
              belongsTo: option?.belongsTo || false,
              singleSelect: !!option.singleSelect,
              asUtcDate: !!option.asUtc,
            };

            this.dataFields.push(dataFieldData);
          }
        }
      }
    },
    filterOptions(
      searchFilter,
      val,
      update,
      abort,
      name,
      modelOrOptions,
      searchBy = null,
      render = null,
      byId,
      entityName,
      additionalString = null
    ) {
      let limit;
      if (!val.length) {
        limit = 10;
      } else if (val.length < 2) {
        abort();
        return;
      }

      // find filtered options
      const findFilterIndex = this.filteredOptions.findIndex((filter) => {
        return filter.name === name;
      });
      this.filteredOptions[findFilterIndex].options = val.length
        ? [{ label: "searching...", inactive: true }]
        : [{ label: "loading...", inactive: true }];
      update(async () => {
        try {
          const needle = val.toLowerCase();

          if (typeof modelOrOptions === "function") {
            let searchParams = {};
            if (searchBy !== null) {
              const searchArr = searchBy.split(",");

              for (let i = 0; i < searchArr.length; i++) {
                searchParams[searchArr[i]] = val;
              }
              if (
                additionalString &&
                Array.isArray(additionalString) &&
                additionalString.length
              ) {
                for (let i = 0; i < additionalString.length; i++) {
                  searchParams[additionalString[i].key] =
                    additionalString[i].value;
                }
              }
            } else {
              searchParams = null;
            }

            const filterResult = await modelOrOptions
              .api()
              .query(limit, null, searchParams);
            if (filterResult && filterResult.response.status === 200) {
              const renderData = this.renderData(
                filterResult.entities[entityName] || [],
                render,
                byId
              );

              if (findFilterIndex > -1) {
                this.filteredOptions[findFilterIndex].options =
                  Object.freeze(renderData);

                this.$refs[`${searchFilter}`][0].refresh();
              }
            } else {
              this.showNotifyMessage({
                message: "Unable to fetch data.",
                type: "danger",
              });
              this.filteredOptions[findFilterIndex].options = [];
            }
          } else {
            let findLike = modelOrOptions.filter((opt) => {
              if (typeof opt === "object")
                return opt.value.toLowerCase().indexOf(needle) > -1;
              return opt.toLowerCase().indexOf(needle) > -1;
            });

            if (findFilterIndex > -1) {
              if (findLike.length > 0) {
                this.filteredOptions[findFilterIndex].options = Object.freeze(
                  findLike.map((f) => {
                    return modelOrOptions.find((opt) => opt === f);
                  })
                );
              } else {
                this.filteredOptions[findFilterIndex].options = Object.freeze(
                  modelOrOptions.filter(
                    (v) => v.value.toLowerCase().indexOf(needle) > -1
                  )
                );
              }
            }
          }
        } catch (err) {
          this.showNotifyMessage({
            message: "Unable to fetch data.",
            actions: [
              {
                label: "Retry",
                color: "white",
                handler: () => {
                  this.filterSearchModel(limit);
                },
              },
            ],
            type: "danger",
          });
          if (this.dataTable) {
            this.dataTable.setError(true, {
              limit,
            });
          }
          this.filteredOptions[findFilterIndex].options = [];
        }
      });

      abort();
    },
    renderData(data, render = null, byId) {
      let renderKeys;
      let renderResult;

      if (render) {
        renderKeys = render.match(/(<([^>]+)>)/gi);

        renderKeys = renderKeys.map((key) => {
          return key.replace(/<|>/g, "");
        });

        renderResult = render;
      } else {
        renderKeys = this.getAllKeys(data[0]);
        const newRenderKeys = renderKeys.map((key) => {
          return "<" + key + ">";
        });

        renderResult = newRenderKeys.join(" ");
      }

      return data.map((opt) => {
        let renderObj;
        let renderString = renderResult;

        for (let k = 0; k < renderKeys.length; k++) {
          let regex = new RegExp("<" + renderKeys[k] + ">");
          let keyValue = opt[renderKeys[k]];

          renderString = renderString.replace(regex, keyValue);
        }

        // finalize string for empty occurences
        renderString = renderString.replace(/\[\]|\(\)|\{\}/g, "");

        let renderValue = {};
        for (let i = 0; i < renderKeys.length; i++) {
          renderValue[renderKeys[i]] = opt[renderKeys[i]];
          if (byId) renderValue.id = opt.id;
        }

        renderObj = {
          label: renderString,
          value: renderValue,
        };

        return renderObj;
      });
    },
    getAllKeys(options) {
      let newKeys = [];
      for (let idx in options) {
        if (idx !== "id") {
          newKeys.push(idx);
        }
      }
      return newKeys;
    },
    isEmpty(obj) {
      for (let prop in obj) {
        if (obj[prop]) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
    convertBuildSearchToFilterString(limit, offset) {
      const filter = this.buildSearchData;
      const filters = [];
      let filterString = "filter:{";
      Object.keys(filter).forEach((key) => {
        if (key === "logicalOperator") {
          filters.push(`${key}:${filter[key]}`);
        } else if (key === "date") {
          filters.push(
            `${key}:{gt:"${filter[key].gt}",lt:"${filter[key].lt}" }`
          );
        } else {
          const enumRegex = new RegExp("enum_");
          const hasEnumPrefix = enumRegex.test(key);
          if (hasEnumPrefix) filters.push(`${key}:${filter[key]}`);
          else filters.push(`${key}:"${filter[key]}"`);
        }
      });
      filterString = filterString + filters.join(",") + "}";


      if (offset) {
        filterString = filterString + `,offset:${offset}`;
      }

      if (limit != null) {
        filterString = filterString + `,limit:${limit}`;
      }
      if (this.includeDeleted) {
        filterString = filterString + `,includeDeleted:true`;
      }
      return filterString;
    },
    setValue(name, value) {
      const fieldIndex = this.dataFields.findIndex(
        (data) => data.name === name
      );
      this.dataFields[fieldIndex].value = value;
    },
    renderDates(date, allowFutureDates = false) {
      if (allowFutureDates) return true;
      return this.$options.filters.isDateBefore(date, new Date());
    },
    getDatePayload(dateVal, field = "gt", asUtc = false) {
      const isGt = field === "gt";
      if (!dateVal) return "";
      if (asUtc) return `${dateVal} ${isGt ? "00:00:00" : "23:59:59"}`;
      const offset = isGt
        ? this.getTimezoneOffset({ date: dateVal })
        : {
            days: 1,
            ...this.getTimezoneOffset({
              date: dateVal,
              minusSeconds: 1,
            }),
          };
      return this.$options.filters.getTimeDate(
        dateVal,
        "YYYY-MM-DD HH:mm:ss",
        true,
        null,
        offset,
      ).utc;
    },
  },
  beforeMount() {
    this.createDataFields(this.options);
    this.$nextTick(() => {
      if (this.columns !== undefined) {
        const colNum = parseInt(this.columns);
        this.fieldCol = 12 / colNum;
      }
    });
  },
};
