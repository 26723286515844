import { camelCase } from "lodash";

export default class GraphqlHelper {
  mutationTagBuilder(
    variables,
    shouldHaveParameters = false,
    shouldNotReturnId = false
  ) {
    const { parameters, mutation, setters, options } = variables;
    let mutationParameter = [];

    //this will check if mutation parameter is set and will convert it to proper format
    // i.e. practiceId = practiceId : $practiceId
    if (variables.mutationParameter && variables.mutationParameter.length) {
      variables.mutationParameter.forEach((param) => {
        mutationParameter.push(param + ": $" + param);
      });
    }

    let mutationString;
    if (this.objectHasOwnProperty(variables, "withMutationParameter")) {
      mutationString =
        variables.withMutationParameter && mutationParameter.length > 0
          ? mutation + "(" + mutationParameter.join(",") + ")"
          : mutation;
    } else {
      mutationString =
        mutationParameter.length > 0
          ? mutation + "(" + mutationParameter.join(",") + ")"
          : mutation;
    }

    let query;
    if (
      (mutationParameter.length > 0 || shouldHaveParameters === true) &&
      !this.objectHasOwnProperty(options, "noMutationString")
    ) {
      query =
        "mutation SetData(" +
        parameters.join(",") +
        ")" +
        "{ " +
        mutationString +
        `${shouldNotReturnId ? "{ " : "{ id "}` +
        setters.join(" ") +
        " } }";
    } else if (this.objectHasOwnProperty(options, "noMutationString")) {
      query =
        "mutation SetData(" +
        parameters.join(",") +
        ")" +
        "{ " +
        mutationString +
        "}";
    } else {
      query =
        "mutation {" + mutationString + "{ id " + setters.join(" ") + "} }";
    }

    return query;
  }
  sanitizeParams(params) {
    const sanitizedParams = [];
    params.forEach((param) => {
      const [variable, type] = param.split(":");
      const isMainQuery = variable.split(".").length <= 1;
      if (!isMainQuery) {
        const formattedVariable = `$${camelCase(variable.replace(".", " "))}`;
        sanitizedParams.push(`${formattedVariable}:${type}`);
      } else {
        sanitizedParams.push(param);
      }
    });
    return sanitizedParams;
  }
  queryWithParameterTagBuilder(variables, noneLimitData=false) {
    const { parameters, query, setters } = variables;
    let queryParameter = [];

    //this will check if mutation parameter is set and will convert it to proper format
    // i.e. practiceId = practiceId : $practiceId
    if (variables.queryParameter && variables.queryParameter.length) {
      variables.queryParameter.forEach((param) => {
        queryParameter.push(param + ": $" + param);
      });
    }
    if (variables.additionalString && variables.additionalString.length) {
      variables.additionalString.forEach((additionalString) => {
        queryParameter.push(additionalString);
      });
    }
    const mainQueryParams = queryParameter.filter(
      (param) => param.indexOf(".") < 0
    );
    const subQueryParams = queryParameter.filter(
      (param) => param.indexOf(".") >= 0
    );
    let settersWithParams = setters;
    let subParams = "";
    let queryObject = {};
    subQueryParams.forEach((query, i) => {
      const [params] = query.split(":");
      const queryParam = params.split(".").slice(0, -1).join(".");
      if (queryObject[queryParam]) queryObject[queryParam].lastIndex = i;
      else {
        queryObject = {
          ...queryObject,
          [queryParam]: {
            firstIndex: i,
            lastIndex: i,
          },
        };
      }
    });
    subQueryParams.map((item, i) => {
      const [params] = item.split(":");
      const queryParam = params.split(".").slice(0, -1).join(".");
      const queryStrings = params.split(".");
      const field = queryStrings[queryStrings.length - 2];
      const variable = queryStrings[queryStrings.length - 1];
      if (!queryObject[queryParam]) return;
      subParams = `${
        i === queryObject[queryParam].firstIndex ? `${field}(` : subParams
      }${variable}: $${camelCase(params.replace(".", " "))},`;
      if (i === queryObject[queryParam].lastIndex) {
        subParams = `${subParams.slice(0, -1)})`;
        const objectIndex = setters.findIndex(
          (setter) =>
            setter.includes(`${field}{`) || setter.includes(`${field} `)
        );
        if (setters[objectIndex]) {
          settersWithParams[objectIndex] = setters[objectIndex].replace(
            field,
            subParams
          );
        }
      }
    });
    const mergedQuery =
      mainQueryParams.length > 0
        ? `${query}(${mainQueryParams.join(",")})`
        : `${query}()`;
    const queryString = `query QueryString(${this.sanitizeParams(
      parameters
    ).join(",")}) { ${mergedQuery} ${
      noneLimitData ? "" : `{ id ${settersWithParams.join(" ")} }`
    }}`;
    return queryString;
  }
  queryTagBuilder(
    entity,
    fields,
    settings = null,
    shouldNotReturnId = false,
    noQueryString = false
  ) {
    let entityArg = [];
    if (settings !== null) {
      if (
        this.objectHasOwnProperty(settings, "orderBy") &&
        settings.orderBy !== null
      )
        entityArg.push(`${settings.orderBy}:true`);
      if (
        this.objectHasOwnProperty(settings, "order") &&
        settings.order !== null
      )
        if (typeof settings.order === "object")
          entityArg.push(`${settings.order.by}:${settings.order.order}`);
      if (
        this.objectHasOwnProperty(settings, "limit") &&
        settings.limit !== null
      )
        entityArg.push(`limit: ${settings.limit}`);
      if (
        this.objectHasOwnProperty(settings, "offset") &&
        settings.offset !== null
      )
        entityArg.push(`offset: ${settings.offset}`);
      if (
        this.objectHasOwnProperty(settings, "includeDeleted") &&
        settings.includeDeleted
      )
        entityArg.push(`includeDeleted: true`);
      if (
        this.objectHasOwnProperty(settings, "includeInactive") &&
        settings.includeInactive !== null
      )
        entityArg.push(`includeInactive: ${settings.includeInactive}`);
      if (
        this.objectHasOwnProperty(settings, "additionalString") &&
        settings.additionalString &&
        settings.additionalString.length
      ) {
        for (let i = 0; i < settings.additionalString.length; i++) {
          entityArg.push(settings.additionalString[i]);
        }
      }

      if (
        this.objectHasOwnProperty(settings, "recipient") &&
        settings.recipient &&
        settings.recipient.length
      )
        entityArg.push(`recipient: "${settings.recipient}"`);

      if (
        this.objectHasOwnProperty(settings, "filter") &&
        settings.filter !== null
      ) {
        var filterArg = "filter:{";
        const optimizedFilter = this.optimizeString(settings.filter);
        Object.keys(optimizedFilter).forEach((key, index) => {
          if (key === "logicalOperator") {
            if (optimizedFilter[key] === "AND") {
              filterArg +=
                index === Object.keys(optimizedFilter).length - 1
                  ? `${key}: AND}`
                  : `${key}: AND,`;
            } else {
              filterArg +=
                index === Object.keys(optimizedFilter).length - 1
                  ? `${key}: OR}`
                  : `${key}: OR,`;
            }
          } else if (
            key === "date" ||
            key === "created" ||
            key === "lastCalibrationDate" ||
            key === "expiration" ||
            key === "effectivityDate" ||
            key === "terminationDate" ||
            key === "validityStartDate" ||
            key === "validityEndDate" ||
            key === "generationDate"
          ) {
            let dateArg = `{gt: "${optimizedFilter[key].gt}", lt: "${optimizedFilter[key].lt}"}`;
            filterArg +=
              index === Object.keys(optimizedFilter).length - 1
                ? `${key}: ${dateArg}}`
                : `${key}: ${dateArg},`;
          } else {
            // check first if settings filter key has enum prefix
            const enumRegex = new RegExp("enum_");
            const hasEnumPrefix = enumRegex.test(key);

            if (hasEnumPrefix) {
              const newKey = key.replace("enum_", "");

              filterArg +=
                index === Object.keys(optimizedFilter).length - 1
                  ? `${newKey}: ${optimizedFilter[key]}}`
                  : `${newKey}: ${optimizedFilter[key]},`;
            } else {
              if (typeof optimizedFilter[key] === "object") {
                let filterObj = "{";
                // get keys
                const filterObjKeys = Object.keys(optimizedFilter[key]);

                // remove isMultiField key
                const findIsMultiFieldIndex = filterObjKeys.findIndex(
                  (filter) => {
                    return filter === "isMultiFields";
                  }
                );
                if (findIsMultiFieldIndex > -1) {
                  filterObjKeys.splice(findIsMultiFieldIndex, 1);
                }

                if (
                  this.objectHasOwnProperty(
                    optimizedFilter[key],
                    "isMultiFields"
                  )
                ) {
                  for (let i = 0; i < filterObjKeys.length; i++) {
                    // create array string
                    let filterArrObj = "";
                    let arrayValue = [];
                    if (optimizedFilter[key][filterObjKeys[i]].length > 0) {
                      for (
                        let a = 0;
                        a < optimizedFilter[key][filterObjKeys[i]].length;
                        a++
                      ) {
                        const item = optimizedFilter[key][filterObjKeys[i]][a];
                        arrayValue.push(`"${item}"`);
                      }
                      filterArrObj = arrayValue.join(",");
                      filterObj +=
                        i === filterObjKeys.length - 1
                          ? `${filterObjKeys[i]}: ${filterArrObj}}`
                          : `${filterObjKeys[i]}: ${filterArrObj},`;
                    }
                  }
                } else {
                  for (let i = 0; i < filterObjKeys.length; i++) {
                    // create array string
                    let filterArrObj = "";
                    if (optimizedFilter[key][filterObjKeys[i]].length > 0) {
                      if (
                        typeof optimizedFilter[key][filterObjKeys[i]] !==
                        "string"
                      ) {
                        filterArrObj += "[";
                        for (
                          let a = 0;
                          a < optimizedFilter[key][filterObjKeys[i]].length;
                          a++
                        ) {
                          const item =
                            optimizedFilter[key][filterObjKeys[i]][a];
                          filterArrObj +=
                            a ===
                            optimizedFilter[key][filterObjKeys[i]].length - 1
                              ? `"${item}"]`
                              : `"${item}",`;
                        }
                      } else {
                        filterArrObj = `"${
                          optimizedFilter[key][filterObjKeys[i]]
                        }"`;
                      }

                      filterObj +=
                        i === filterObjKeys.length - 1
                          ? `${filterObjKeys[i]}: ${filterArrObj}}`
                          : `${filterObjKeys[i]}: ${filterArrObj},`;
                    }
                  }
                }

                filterArg +=
                  index === Object.keys(optimizedFilter).length - 1
                    ? `${key}: ${filterObj}}`
                    : `${key}: ${filterObj},`;
              } else {
                if (typeof optimizedFilter[key] === "boolean") {
                  filterArg +=
                    index === Object.keys(optimizedFilter).length - 1
                      ? `${key}: ${optimizedFilter[key]}}`
                      : `${key}: ${optimizedFilter[key]},`;
                } else {
                  filterArg +=
                    index === Object.keys(optimizedFilter).length - 1
                      ? `${key}: "${optimizedFilter[key]}"}`
                      : `${key}: "${optimizedFilter[key]}",`;
                }
              }
            }
          }
        });

        entityArg.push(filterArg);
      }
    }

    return (
      "{" +
      (entityArg.length ? `${entity}(${entityArg.join(",")})` : `${entity}`) +
      (noQueryString
        ? ""
        : `{${shouldNotReturnId ? "" : " id,"}` + fields.join(",") + "}") +
      "}"
    );
  }
  subQueryTagBuilder(entity, fields, hasId = true) {
    return (
      "" + entity + `{ ${hasId ? "id " : ""}` + fields.join(" ") + "}" + ""
    );
  }
  multipleMutationBuilder(variables, forDeletion = false) {
    let query = [];
    let mergedParameters = [];
    for (let i = 0; i < variables.length; i++) {
      let variable = variables[i];
      const { alias, parameters, mutation, setters } = variable;

      let mutationParameter = [];

      //this will check if mutation parameter is set and will convert it to proper format
      // i.e. practiceId = practiceId : $practiceId
      if (variable.mutationParameter && variable.mutationParameter.length) {
        variable.mutationParameter.forEach((param) => {
          mutationParameter.push(param);
        });
      }

      let mutationString =
        mutationParameter.length > 0
          ? mutation + "(" + mutationParameter.join(",") + ")"
          : mutation + "()";
      mergedParameters.push(parameters);
      if (forDeletion) {
        query.push("mutation" + i + " : " + mutationString);
      } else {
        query.push(
          `${alias ? alias : `mutation${i}`}` +
            " : " +
            mutationString +
            " { id " +
            setters.join(" ") +
            " }"
        );
      }
    }
    if (forDeletion) {
      return (
        "mutation UnsetData(" +
        mergedParameters.join(",") +
        ") {" +
        query.join(" ") +
        "}"
      );
    } else {
      return (
        "mutation SetData(" +
        mergedParameters.join(",") +
        ") {" +
        query.join(" ") +
        "}"
      );
    }
  }
  optimizeString(data) {
    let returnData = {};
    // eslint-disable-next-line no-unused-vars
    Object.keys(data).forEach((key, index) => {
      returnData[key] = {};
      if (typeof data[key] == "string") {
        let arrString = data[key].split("");
        for (let z = 0; z < arrString.length; z++)
          if (/["\\]/g.test(arrString[z])) arrString[z] = `\\${arrString[z]}`;

        returnData[key] = arrString.join("");
      } else if (typeof data[key] == "object") {
        const props = Object.keys(data[key]);
        for (let i = 0; i < props.length; i++) {
          let propData = data[key][props[i]];
          returnData[key][props[i]] = [];
          if (typeof propData == "string") {
            let arrString = propData.split("");
            for (let x = 0; x < arrString.length; x++)
              if (/["\\]/g.test(arrString[x]))
                arrString[x] = `\\${arrString[x]}`;

            returnData[key][props[i]] = arrString.join("");
          } else if (typeof propData == "object") {
            returnData[key][props[i]] = [];
            for (let a = 0; a < propData.length; a++) {
              let arrString = propData[a].split("");
              for (let x = 0; x < arrString.length; x++)
                if (/["\\]/g.test(arrString[x]))
                  arrString[x] = `\\${arrString[x]}`;

              returnData[key][props[i]].push(arrString.join(""));
            }
          } else returnData[key][props[i]] = propData;
        }
      } else {
        returnData[key] = data[key];
      }
    });
    return returnData;
  }
  objectHasOwnProperty(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }
}
