import Order from "store/models/Order";
import StoreActions from "store/actions";

const storeActions = new StoreActions(Order);

export default class OrderActions {
  async get(params) {
    const { limitData, variables } = params;
    return await storeActions.query({
      queryName: "getOrder",
      relativeUrl: "/order/get-order",
      limitData: limitData || [],
    }, "To fetch order.", {
      ...variables,
    });
  }

  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updateOrderDetails",
        relativeUrl: "/order/update-order-details",
        queries: variables.queries || {},
        collectionQueries: variables.collectionQueries || [],
        childQueries: variables.childQueries || [],
        booleanQueries: variables.booleanQueries || [],
      },
      "To update order.",
      {
        orderId: variables.orderId,
      }
    );
  }

  async sendNotification(variables) {
    return await storeActions.mutation(
      {
        queryName: "sendChangeLogNotification",
        relativeUrl: "/order/send-change-log-notification",
        noMutationString: true,
      },
      "To send changelog notification.",
      { ...variables.params }
    );
  }

  async runPricingRules(params) {
    return await storeActions.mutation(
      {
        queryName: "vbsProcessPricingRules",
        relativeUrl: "/order/run-pricing-rules",
        limitData: ["success", "errors", "code", "entityId"],
        shouldNotReturnId: true,
      },
      "to run the pricing rules process",
      { ...params }
    );
  }
}
