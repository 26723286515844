import QueryModel from "store/models/QueryModel";
// import GraphqlHelper from "./helpers/graphql-helper";
// const graphqlHelper = new GraphqlHelper();
export default class AppSettings extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "app_settings";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      key: this.attr(null),
      value: this.attr(null),
      description: this.attr(null),
    };
  }

  static defaultData = [
    "key",
    "value",
    "description",
    "display",
    "isDeleted",
    "totalCount",
    "created",
  ];
}
