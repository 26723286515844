export default {
  //FormDataTable uses v-custom-data-table with own filterSearch method
  name: "FormDataTable",
  props: {
    openModal: {
      type: Boolean,
      value: false,
    },
    modalTitle: String,
    sectionNote: String,

    tableHeader: [Array, Object],
    searchLabel: String,
    withSearchFilter: { type: Boolean, default: true },
    executeQuery: Function,
    limitData: Array,
    relativeUrl: String,
    queryName: String,
    searchBy: { type: String, require: true },
    defaultSort: Object,
    modelInstance: { type: Function, require: true },
    mapDataCollection: Function,
    selectable: { type: Boolean, default: true },
    hideSearch: { type: Boolean, default: false },
    hideArchiveSwitch: { type: Boolean, default: false },
    searchLogicalOperand: String,

    initialValue: Array,

    shouldNotReturnId: { type: Boolean, default: false },
    filterField: { text: Object, default: null },
    initialFilter: { text: Object, default: null },
    disabledSelection: { type: Boolean, default: false },
  },
  data() {
    return {
      canEdit: true,
      searchFilter: null,
      isLoading: false,
      dataCollection: [],
      currentLimit: 0,
      totalCount: 0,
      offset: null,
      numberOfSelected: 0,
      searchVal: "",
      selectedOption: null,
      includeArchive: false,
      tab: "",
      additionalFilter: null,
      selectedInTable: [],
    };
  },
  watch: {
    async isLoading(val) {
      if (val) {
        await this.filterSearchModel(10, null);
        this.isLoading = false;
      }
    },
    async includeArchive() {
      await this.filterSearchModel(10, null);
      this.isLoading = false;
    },

    async selectedOption() {
      await this.filterSearchModel(10, null);
      this.isLoading = false;
    },

    openModal(val) {
      if (val) {
        this.dataCollection = [];
        this.offset = null;
        this.searchVal = "";
        this.isLoading = true;
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.openModal;
      },
      set(bool) {
        this.$emit("update:openModal", bool);
      },
    },

    isSingleTable() {
      return !this.withTabs ? true : false;
    },
  },
  methods: {
    updateNumberOfSelected() {
      this.numberOfSelected = this.$refs.searchDataTable.getSelectedString();
    },

    updateSelectedString(val) {
      this.selectedInTable = val;
      this.numberOfSelected = this.$refs.searchDataTable.getSelectedString();
      this.$emit("selectDeselect", this.selectedInTable);
    },

    async filterSearchModel(
      limit = null,
      offset = null,
      returnResults = false,
      order = null
    ) {
      if (typeof limit == "object" || limit == null) {
        limit = this.limit;
      }

      if (!order && this.defaultSort) order = this.defaultSort;

      if (!this.withSearchFilter) {
        await this.executeQuery();
        this.totalCount = this.dataCollection[0]?.totalCount || 0;
        return;
      }

      try {
        this.$emit("loading", true);
        this.offset = offset;
        this.currentLimit = limit;
        let options = {
          relativeUrl: this.relativeUrl,
          limit,
          offset,
          filter: this.searchVal
            ? {
                ...this.buildFilters(this.searchBy),
                logicalOperator: this.searchLogicalOperand || "OR",
              }
            : {},
          includeDeleted: this.includeDeleted,
          additionalString:
            typeof this.additionalString == "object"
              ? this.additionalString
              : null,
          order,
          queryName: this.queryName,
        };
        if (this.limitData) options["limitData"] = this.limitData;

        if (this.shouldNotReturnId) options["shouldNotReturnId"] = true;

        Object.assign(options["filter"], { archive: this.includeArchive });

        if (this.selectedOption) {
          Object.assign(options["filter"], {
            [this.filterField.field]: this.selectedOption,
          });
        }

        if (this.additionalFilter) {
          Object.assign(options["filter"], this.additionalFilter);
        }

        const filterSearchResult = await this.modelInstance
          .api()
          .query(options, "to get all data");

        this.dataCollection = filterSearchResult.response.data;

        if (this.mapDataCollection) {
          this.dataCollection = this.mapDataCollection(this.dataCollection);
        }

        this.totalCount = this.dataCollection[0]?.totalCount || 0;
        if (filterSearchResult && filterSearchResult.response.status === 200) {
          if (returnResults) return filterSearchResult.response.data;
          else this.$emit("searchResults", filterSearchResult.response.data);
          this.$emit("loading", false);
        } else {
          this.showNotifyMessage({
            message: `Unable to fetch data.`,
            actions: [
              {
                label: "Retry",
                color: "white",
                handler: () => {
                  this.filterSearchModel(limit, offset, returnResults, order);
                },
              },
            ],
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          actions: [
            {
              label: "Retry",
              color: "white",
              handler: () => {
                this.filterSearchModel(limit, offset, returnResults, order);
              },
            },
          ],
          type: "danger",
        });
      }
    },
    buildFilters(searchFilters) {
      const props = this.parseToArray(searchFilters);

      if (props.length == 1) {
        const allFilter = { [searchFilters.replace(/ /g, "")]: this.searchVal };
        return allFilter;
      } else {
        return props.reduce((pv, cv) => ((pv[cv] = this.searchVal), pv), {});
      }
    },
    parseToArray(data) {
      return data.replace(/ /g, "").split(",");
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.orders = result;
    },

    async searchData() {
      this.searchDebounce();
    },
    getSelected() {
      this.$emit(
        "returnSelected",
        this.$refs.searchDataTable.getSelectedItems()
      );
    },

    setEventListeners() {
      this.$eventBus.$on(
        `${this.queryName}SetAdditionalFilter`,
        async (filterData) => {
          this.additionalFilter = filterData;
          await this.filterSearchModel(10, null);
          this.isLoading = false;
        }
      );

      this.$eventBus.$on(`${this.queryName}RemoveSelectedFilter`, () => {
        this.selectedOption = "";
      });

      this.$eventBus.$on(`${this.queryName}RemoveSelectedIds`, (filterFunc) => {
        this.selectedInTable = this.selectedInTable.filter(filterFunc);
        if (this.$refs.searchDataTable) {
          this.$refs.searchDataTable.setSelected(this.selectedInTable);
          this.numberOfSelected =
            this.$refs.searchDataTable.getSelectedString();
        }
        this.$emit("setSelected", this.selectedInTable);
      });

      this.$eventBus.$on(`${this.queryName}RefreshList`, async () => {
        await this.filterSearchModel(10, null);
        this.isLoading = false;
      });

      this.$eventBus.$on(`${this.queryName}SetDataList`, async (list) => {
        this.dataCollection = list;
      });
    },
  },

  async created() {
    this.searchFilter = this;
    this.searchDebounce = await this.debounce(() => {
      this.isLoading = true;
      this.dataCollection = [];
      this.offset = null;
    }, 600);

    if (this.initialFilter) {
      this.additionalFilter = this.initialFilter;
    }

    this.isLoading = true;

    this.setEventListeners();

    if (this.initialValue) {
      this.numberOfSelected = `${
        this.initialValue?.length | 0
      } record selected`;
    }
  },
};
