export default {
  methods: {
    // in multiple click will extend the time of execution
    async debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    // in multiple click, the first click will execute the function
    async debounce_leading(func, timeout = 300) {
      let timer;
      return async (...args) => {
        if (!timer) {
          await func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = undefined;
        }, timeout);
      };
    },
  },
};
