import Phone from "store/models/Phone";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Phone);

export default class PhoneActions {
  async create(variables) {
    const { phone, areaCode } = variables;

    return await storeActions.mutation(
      {
        queryName: "createPhone",
        relativeUrl: "/phone/create-phone",
        queries: variables.queries || null,
        collectionQueries: variables.collectionQueries || [],
      },
      "To update phone.",
      {
        phone: phone,
        areaCode: areaCode,
      }
    );
  }

  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updatePhoneDetails",
        relativeUrl: "/phone/update-phone-details",
        queries: variables.queries,
        collectionQueries: variables.collectionQueries,
      },
      "To update phone.",
      {
        phoneId: variables.phoneId,
      }
    );
  }
}
