import EnumModel from "store/models/EnumModel";

import StoreActions from "store/actions";

const storeActions = new StoreActions(EnumModel);
export default class EnumModelActions {
  async getEnumValues(Type) {
    return await storeActions.query(
      {
        relativeUrl: "/enum/get-enum-values",
        queryName: "__type",
        enumQuery: Type,
      },
      "To get enum values."
    );
  }
}
