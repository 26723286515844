import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class FinalAccessionReport extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "final_accession_report";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      reportId: this.attr(""),
      downloadUrl: this.attr(""),
      previewUrl: this.attr(""),
      fileName: this.attr(""),
      dateGenerated: this.attr(""),
      dateCoverage: this.attr(""),
      dateRequested: this.attr(""),
      startDate: this.attr(""),
      endDate: this.attr(""),
      createdBy: this.attr(""),
      status: this.attr(null),
      reportType: this.attr(null),
      totalCount: this.attr(0),
      retryCount: this.attr(0),
      config: this.attr(null),
    };
  }

  static defaultData = [
    "reportId",
    "downloadUrl",
    "previewUrl",
    "fileName",
    "dateGenerated",
    "dateCoverage",
    "dateRequested",
    "startDate",
    "endDate",
    "createdBy",
    graphqlHelper.subQueryTagBuilder("status", ["enum", "label"], false),
    "reportType",
    "totalCount",
    "retryCount",
    graphqlHelper.subQueryTagBuilder("config", [
      "orderNumber",
      "clientId",
      "clientName",
      "accessionNumber",
      "patientFirstName",
      "patientLastName",
      "dateOfService",
      "dateReceivedInLab",
      "dateResultsFinalized",
      "orderingPhysicianNpi",
      "orderingPhysicianLastName",
      "orderingPhysicianFirstName",
      "testCode",
      "testName",
      "orderStatus",
    ]),
  ];
}
