export default {
  methods: {
    sanitizeString(string) {
      if (null === string) return "";
      return string.replace(/\s+/g, " ").trim();
    },
    sanitizePhoneString(string) {
      if (null === string) return "";
      return string.replace(/[()|-]/g, "");
    },
    formatPhoneString(string, format) {
      if (null === string || null === format) return "";

      let i = 0;
      return format.replace(/#/g, string[i++]);
    },
    formatCamelCaseToTitleCase(string) {
      const result = string.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    snakeCaseToCamelCase(string) {
      return string.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
      });
    },
    formatCurrency(val, dec = 2, symbol) {
      if (!val) return "";
      const stringVal = parseFloat(val.toString().replace(",", "")).toFixed(dec);
      return `${symbol ? `${symbol} ` : ""}${stringVal.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}`;
    },
  },
};
