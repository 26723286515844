import Address from "store/models/Address";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Address);

export default class AddressActions {
  async create(variables) {
    const { params, queries, collectionQueries } = variables;

    return await storeActions.mutation(
      {
        relativeUrl: "/address/create-address",
        queryName: "createAddress",
        queries: queries || null,
        collectionQueries: collectionQueries || null,
      },
      "To create address.",
      { ...params }
    );
  }

  async update(params) {
    const { collectionQueries, queries, childQueries, variables } = params;

    return await storeActions.mutation(
      {
        relativeUrl: "/address/update-address",
        queryName: "updateAddressDetails",
        queries: queries || null,
        collectionQueries: collectionQueries || null,
        childQueries: childQueries || null,
      },
      "To update address.",
      { ...variables }
    );
  }

  async archive(params) {
    const { id } = params;

    return await storeActions.mutation(
      {
        queryName: "archiveAddress",
        relativeUrl: "/address/archive-address",
        noMutationString: true,
      },
      "To archive address.",
      { id }
    );
  }

  async deArchive(params) {
    const { id } = params;

    return await storeActions.mutation(
      {
        queryName: "deArchiveAddress",
        relativeUrl: "/address/de-archive-address",
        noMutationString: true,
      },
      "To de-archive address.",
      { id }
    );
  }
}
