import QueryModel from "store/models/QueryModel";

export default class Modifier extends QueryModel {
  static entity = "modifier";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      modifierCode: this.string(""),
      modifierCodeDescription: this.string(""),
      created: this.string(""),
      lastModified: this.string(""),
    };
  }

  static defaultData = ["modifierCode", "modifierCodeDescription"];
}
