import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";
import Address from "./Address";
import Email from "./Email";
import Phone from "./Phone";

const graphqlHelper = new GraphqlHelper();
export default class Patient extends QueryModel {
  static entity = "patients";
  static fields() {
    return {
      id: this.attr(null),
      firstName: this.string(""),
      middleName: this.string(""),
      lastName: this.string(""),
      gender: this.string(""),
      status: this.attr(null),
      dateOfBirth: this.string(""),
      addresses: this.hasMany(Address, "patientId"),
      emails: this.hasMany(Email, "patientId"),
      phones: this.hasMany(Phone, "patientId"),
      defaultAddressId: this.attr(null),
      patientSsn: this.attr(null),
      defaultEmailId: this.attr(null),
      defaultPhoneId: this.attr(null),
      defaultAddress: this.belongsTo(Address, "defaultAddressId"),
      defaultEmail: this.belongsTo(Email, "defaultEmailId"),
      defaultPhone: this.belongsTo(Phone, "defaultPhoneId"),
    };
  }
  static defaultData = [
    "firstName",
    "dateOfBirth",
    "lastName",
    "gender",
    "patientSsn",
    graphqlHelper.subQueryTagBuilder("defaultAddress", [
      "line1",
      "line2",
      "city",
      "postalCode",
      "country",
      "stateProvince",
    ]),
    graphqlHelper.subQueryTagBuilder("addresses", [
      "line1",
      "line2",
      "city",
      "country",
    ]),
    graphqlHelper.subQueryTagBuilder("defaultPhone", [
      "phone",
      "areaCode",
      graphqlHelper.subQueryTagBuilder("phoneType", ["id", "label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("phones", [
      "phone",
      "areaCode",
      graphqlHelper.subQueryTagBuilder("phoneType", ["id", "label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("defaultEmail", ["email"]),
  ];
}
