import QueryModel from "store/models/QueryModel";

import GraphqlHelper from "store/models/helpers/graphql-helper";

import ContactType from "store/models/ContactType";

const graphqlHelper = new GraphqlHelper();
export default class ContactInformation extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "contact_information";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      contactTypeId: this.attr(null),
      contactType: this.belongsTo(ContactType, "contactTypeId"),
      firstName: this.attr(""),
      middleName: this.attr(""),
      lastName: this.attr(""),
      nameTitle: this.attr(""),
      mainPhone: this.attr(null),
      mainFax: this.attr(null),
      contactEmail: this.attr(null),
    };
  }

  static defaultData = [
    graphqlHelper.subQueryTagBuilder("contactType", ["label"]),
    "firstName",
    "middleName",
    "lastName",
    "nameTitle",
    graphqlHelper.subQueryTagBuilder("mainPhone", [
      "phone",
      "label",
      "areaCode",
      graphqlHelper.subQueryTagBuilder("phoneType", ["label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("mainFax", [
      "phone",
      "label",
      "areaCode",
      graphqlHelper.subQueryTagBuilder("phoneType", ["label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("contactEmail", [
      "email",
      graphqlHelper.subQueryTagBuilder("emailType", ["label"]),
    ]),
  ];
}
