import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";

import Account from "store/models/Account";
import ScheduledProcessorTxn from "store/models/ScheduledProcessorTxn";

const graphqlHelper = new GraphqlHelper();
export default class ScheduledProcessor extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "scheduled_processor";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      nextRunDate: this.attr(""),
      lastProcessorTxnId: this.attr(null),
      lastProcessorTxn: this.belongsTo(
        ScheduledProcessorTxn,
        "lastProcessorTxnId"
      ),
      frequency: this.attr(null),
      schedule: this.attr(""),
      isActive: this.attr(false),
      created: this.attr(""),
      accountId: this.attr(null),
      createdBy: this.belongsTo(Account, "accountId"),
      lastModified: this.attr(""),
      lastModifiedBy: this.belongsTo(Account, "accountId"),
      processorLogic: this.attr(""),
      totalCount: this.attr(0),
    };
  }

  static defaultData = [
    "name",
    "nextRunDate",
    graphqlHelper.subQueryTagBuilder(
      "lastProcessorTxnId",
      ScheduledProcessorTxn.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("frequency", ["enum", "label"], false),
    "schedule",
    "isActive",
    "created",
    graphqlHelper.subQueryTagBuilder("createdBy", ["name", "email"]),
    "lastModified",
    graphqlHelper.subQueryTagBuilder("lastModifiedBy", ["name", "email"]),
    "processorLogic",
    "totalCount",
  ];
}
