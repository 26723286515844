import QueryModel from "store/models/QueryModel";
import Npi from "store/models/Npi";
import Address from "store/models/Address";
import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();

export default class Physician extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "physician";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      physicianId: this.attr(""),
      physicianType: this.attr(""),
      npiNumber: this.attr(""),
      stateMedicalLicense: this.attr(null),
      licenseState: this.attr(""),
      licenseStatus: this.attr(""),
      defaultPhone: this.attr(null),
      defaultEmail: this.attr(null),
      effectivityDate: this.attr(""),
      terminationDate: this.attr(""),
      npi: this.belongsTo(Npi, "npiNumber", "npiNumber"),
      firstName: this.string(""),
      lastName: this.string(""),
      middleName: this.string(""),
      taxonomy: this.attr(""),
      created: this.attr(""),
      isDeleted: this.attr(false),
      defaultAddressId: this.attr(null),
      defaultAddress: this.belongsTo(Address, "defaultAddressId"),
    };
  }
  static defaultData = [
    "firstName",
    "lastName",
    "middleName",
    "taxonomy",
    "created",
    "isDeleted",
    "physicianId",
    "physicianType",
    "licenseState",
    "licenseStatus",
    "effectivityDate",
    "terminationDate",
    "archived",
    graphqlHelper.subQueryTagBuilder("stateMedicalLicense", ["licenseNumber"]),
    graphqlHelper.subQueryTagBuilder("npi", ["npiNumber"]),
    graphqlHelper.subQueryTagBuilder("defaultPhone", [
      "phone",
      "areaCode",
      graphqlHelper.subQueryTagBuilder("phoneType", ["label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("defaultEmail", [
      "email",
      graphqlHelper.subQueryTagBuilder("emailType", ["label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("phones", ["phone", "areaCode"]),
    graphqlHelper.subQueryTagBuilder("emails", [
      "email",
      graphqlHelper.subQueryTagBuilder("emailType", ["label"]),
    ]),
    graphqlHelper.subQueryTagBuilder("payorAccounts", [
      "id",
      "payorCode",
      "payorName",
    ]),
    graphqlHelper.subQueryTagBuilder("defaultPayorAccount", ["id"]),
    // added for iKonek
    graphqlHelper.subQueryTagBuilder("defaultAddress", [
      "line1",
      "line2",
      "city",
      "stateProvince",
      "postalCode",
      "country",
    ]),
  ];
}
