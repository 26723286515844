import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";
import Physician from "store/models/Physician";
import OrderBillingDiagnosticCode from "store/models/OrderBillingDiagnosticCode";
import Patient from "store/models/Patient";
import DiagnosticCode from "store/models/DiagnosticCode";
import TRF from "store/models/TRF";
import Test from "store/models/Test";
import Sample from "store/models/Sample";
import Insurance from "store/models/Insurance";
import PayorAccount from "store/models/PayorAccount";
import TestOrdered from "store/models/TestOrdered";
import TestsOrdered from "store/models/TestsOrdered";
import PatientSignature from "store/models/PatientSignature";
import BillingClaimsSubmission from "./BillingClaimsSubmission";
import BillingTransactionDetails from "./BillingTransactionDetails";
import OrderBillingErrorCode from "store/models/OrderBillingErrorCode";
import AddedOrderBillingErrorCode from "store/models/AddedOrderBillingErrorCode";
import EOBDepositInformation from "./EOBDepositInformation";

const graphqlHelper = new GraphqlHelper();
// const orderHelper = new OrderHelper();
export default class Order extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "order";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      abn: this.attr(null),
      dateSampleTaken: this.string(null),
      dateFirstTransmitted: this.attr(null),
      billingNetsuiteStatus: this.attr(null),
      netsuiteInvoice: this.attr(null),
      billedAmount: this.attr(null),
      balanceDue: this.attr(null),
      priceDate: this.attr(null),
      billingAccessionStatus: this.attr(null),
      billingStatementStatus: this.attr(null),
      sampleReceiveDate: this.attr(null),
      patientId: this.attr(null),
      testId: this.attr(null),
      internalId: this.attr(null),
      diagnosticCodeIds: this.attr(null),
      testOrderedId: this.attr(null),
      physicianId: this.attr(null),
      referingPhysicianId: this.attr(null),
      billingDiagnosticCodeId: this.attr(null),
      orderBillingErrorCodesIds: this.attr([]),
      addedOrderBillingErrorCodeIds: this.attr([]),
      patientSignatureId: this.attr(null),
      testsOrderedIds: this.attr([]),
      status: this.attr(null),
      created: this.attr(null),
      orderPatientStatus: this.attr(null),
      physician: this.belongsTo(Physician, "physicianId"),
      diagnosticCodes: this.hasManyBy(DiagnosticCode, "diagnosticCodeIds"),
      referingPhysician: this.belongsTo(Physician, "referingPhysicianId"),
      billingDiagnosticCode: this.hasManyBy(
        OrderBillingDiagnosticCode,
        "billingDiagnosticCodeId"
      ),
      patient: this.belongsTo(Patient, "patientId"),
      test: this.belongsTo(Test, "testId"),
      usedInsurance: this.attr(null),
      notes: this.attr(null),
      payorAccountId: this.attr(null),
      payorAccount: this.belongsTo(PayorAccount, "payorAccountId"),
      trfId: this.attr(null),
      trf: this.belongsTo(TRF, "trfId"),
      sampleId: this.attr(null),
      sample: this.belongsTo(Sample, "sampleId"),
      testOrdered: this.belongsTo(TestOrdered, "testOrderedId"),
      patientSignature: this.belongsTo(PatientSignature, "patientSignatureId"),
      testsOrdered: this.hasManyBy(TestsOrdered, "testsOrderedIds"),
      billingClaimsSubmissionIds: this.attr([]),
      billingClaimsSubmission: this.hasManyBy(
        BillingClaimsSubmission,
        "billingClaimsSubmissionIds"
      ),
      billingTransactionDetailsIds: this.attr([]),
      billingTransactionDetails: this.hasManyBy(
        BillingTransactionDetails,
        "billingTransactionDetailsIds"
      ),
      priorAuthorization: this.attr(null),
      priorAuthorizationCode: this.attr(""),
      orderBillingErrorCodes: this.hasManyBy(
        OrderBillingErrorCode,
        "orderBillingErrorCodesIds"
      ),
      addedOrderBillingErrorCode: this.hasManyBy(
        AddedOrderBillingErrorCode,
        "addedOrderBillingErrorCodeIds"
      ),
      eobDepositInformationIds: this.attr([]),
      eobDepositInformation: this.hasManyBy(
        EOBDepositInformation,
        "eobDepositInformationIds"
      ),
      orderEntrySaved: this.attr(false),
      billType: this.attr(""),
      dateOfService: this.attr(""),
      billingHistory: this.attr(null),
    };
  }

  static defaultData = [
    "abn",
    "internalId",
    "abn",
    "status",
    "created",
    "orderPatientStatus",
    "dateSampleTaken",
    "billedAmount",
    "balanceDue",
    "priceDate",
    "netsuiteInvoice",
    "billingNetsuiteStatus",
    "dateFirstTransmitted",
    "billingAccessionStatus",
    "orderEntrySaved",
    "billingStatementStatus",
    graphqlHelper.subQueryTagBuilder("notes", [
      "created",
      "note",
      graphqlHelper.subQueryTagBuilder("createdBy", ["username"]),
    ]),
    graphqlHelper.subQueryTagBuilder("sample", ["receiveDate", "sampleId"]),
    graphqlHelper.subQueryTagBuilder("payorAccount", [
      "payorCode",
      "payorName",
      graphqlHelper.subQueryTagBuilder(
        "clientBillType",
        ["enum", "label"],
        false
      ),
    ]),
    graphqlHelper.subQueryTagBuilder(
      "referingPhysician",
      Physician.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("physician", Physician.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "billingDiagnosticCode",
      OrderBillingDiagnosticCode.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("patient", Patient.defaultData),
    graphqlHelper.subQueryTagBuilder("test", Test.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "diagnosticCodes",
      DiagnosticCode.defaultData
    ),
    "totalCount",
    graphqlHelper.subQueryTagBuilder("usedInsurance", [
      "isDefault",
      graphqlHelper.subQueryTagBuilder("insurance", [Insurance.defaultData]),
    ]),
    graphqlHelper.subQueryTagBuilder("sample", Sample.defaultData),
    graphqlHelper.subQueryTagBuilder("testsOrdered", TestOrdered.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "patientSignature",
      PatientSignature.defaultData
    ),
    graphqlHelper.subQueryTagBuilder(
      "billingClaimsSubmission",
      BillingClaimsSubmission.defaultData
    ),
    graphqlHelper.subQueryTagBuilder(
      "billingTransactionDetails",
      BillingTransactionDetails.defaultData
    ),
    "priorAuthorization",
    "priorAuthorizationCode",
    graphqlHelper.subQueryTagBuilder(
      "orderBillingErrorCodes",
      OrderBillingErrorCode.defaultData
    ),
    graphqlHelper.subQueryTagBuilder(
      "addedOrderBillingErrorCode",
      AddedOrderBillingErrorCode.defaultData
    ),
    graphqlHelper.subQueryTagBuilder(
      "eobDepositInformation",
      EOBDepositInformation.defaultData
    ),
    "orderEntrySaved",
    graphqlHelper.subQueryTagBuilder("trf", ["testRequisitionFormId"]),
    "billType",
    "dateOfService",
  ];
}
