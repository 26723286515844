import * as moment from "moment";
import "moment-timezone";

const DEFAULT_TIMEZONE = "America/Los_Angeles";

moment.tz.add([
  `${DEFAULT_TIMEZONE}|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp1 1VaX 3dA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6`,
]);

export default {
  methods: {
    // Can validate months (1 - 12)
    // Can't handle days validation per month, it allows 31 days
    isValidDateByDateParse(val, msg) {
      if (val.length === 0) return true;
      if (Date.parse(val)) return true;
      else return msg;
    },
    // Can validate days per month
    isValidDateByDaysPerMonth(val, msg) {
      if (val.length === 0) return true;
      const months = [4, 6, 9, 11];
      const arr = val.split("/");
      const mm = parseInt(arr[0]);
      const dd = parseInt(arr[1]);
      if (!months.includes(mm)) return true;
      if (dd > 30) return msg;
    },
    // Can validate year
    isValidDateByYear(val, msg) {
      if (val.length === 0) return true;
      const arr = val.split("/");
      const yyyy = parseInt(arr[2]);
      if (yyyy.toString().length === 4) return true;
      else return msg;
    },
    // Can validate leap year
    isValidDateByLeapYear(val, msg) {
      if (val.length === 0) return true;
      const arr = val.split("/");
      const mm = parseInt(arr[0]);
      const dd = parseInt(arr[1]);
      const yyyy = parseInt(arr[2]);
      if (mm !== 2) return true;
      if (yyyy % 100 === 0 ? yyyy % 400 === 0 : yyyy % 4 === 0) {
        if (dd > 29) return msg;
      } else {
        if (dd > 28) return msg;
      }
    },
    isValidDate(dateString) {
      // First check for the pattern
      if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

      // Parse the date parts to integers
      var parts = dateString.split("/");
      var day = parseInt(parts[1], 10);
      var month = parseInt(parts[0], 10);
      var year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

      // Check the range of the day
      let isValid = day > 0 && day <= monthLength[month - 1];
      return isValid;
    },
    isNotFutureDate(dateString) {
      // First check for the pattern
      if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

      // Parse the date parts to integers
      var parts = dateString.split("/");
      var day = parseInt(parts[1], 10);
      var month = parseInt(parts[0], 10);
      var year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

      // Check the range of the day
      let isValid = day > 0 && day <= monthLength[month - 1];
      if (isValid) {
        let now = new Date();
        if (now < Date.parse(dateString)) {
          return false;
        }
      }
      return isValid;
    },
    isPassedDate(dateString) {
      if (!this.isValidDate(dateString)) return false;

      let now = new Date();
      return Date.parse(dateString) < now.setHours(0, 0, 0, 0);
    },
    isDateToday(dateString) {
      if (!this.isValidDate(dateString)) return false;

      let now = new Date();
      return Date.parse(dateString) == now.setHours(0, 0, 0, 0);
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return month && day && year
        ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        : null;
    },
    getTimezoneOffset({
      date = null,
      minusSeconds = 0,
      zone = DEFAULT_TIMEZONE,
    }) {
      let offset;
      if (date) {
        const utcDate = date.indexOf("/") > 0
          ? moment.utc(this.parseDate(date))
          : moment.utc(date);
        offset = moment.tz(utcDate, zone).utcOffset();
      } else {
        offset = moment.tz(zone).utcOffset();
      }
      const hoursInSeconds = Math.abs(offset * 60) - minusSeconds;
      return this.hoursToAddMinus(hoursInSeconds);
    },
    hoursToAddMinus(hrsInSecs) {
      const hours = Math.trunc(hrsInSecs / 60 / 60);
      const minutes = hrsInSecs % 60;
      const seconds = minutes % 60;
      return {
        hours,
        minutes,
        seconds,
      };
    },
  },
};
