import Vue from "vue";
import QueryBuilder from "mixins/query-builder";
import ObjectPrototype from "mixins/object-prototype";
import Debounce from "mixins/debounce";
import Notify from "mixins/notify";
import DateValidation from "mixins/date-validation";
import ValidateNpiNumber from "mixins/validate-npi-number";
import Notifications from "vue-notification";
import ValidateEmail from "mixins/validate-email";
import CountryState from "mixins/country-state";
import ValidateFileSize from "mixins/validate-file-size";
import SanitizeString from "mixins/sanitize-string";
import InternalVersion from "mixins/internal-version";
import AutoScroll from "mixins/auto-scroll";

export default () => {
  Vue.mixin(QueryBuilder);
  Vue.mixin(Debounce);
  Vue.mixin(ObjectPrototype);
  Vue.mixin(Notify);
  Vue.mixin(DateValidation);
  Vue.mixin(ValidateNpiNumber);
  Vue.mixin(CountryState);
  Vue.mixin(ValidateEmail);
  Vue.mixin(ValidateFileSize);
  Vue.mixin(SanitizeString);
  Vue.mixin(InternalVersion);
  Vue.mixin(AutoScroll);
  Vue.use(Notifications);
};
