export default {
  name: "NotificationModal",
  props: {
    promptVisibility: {
      required: true,
      type: Boolean,
    },
    message: {
      required: false,
      type: String,
    },
    header: String,
    emitData: {
      required: false,
      type: [String, Object],
    },
    positiveBtnTxt: {
      required: false,
      type: String,
    },
    negativeBtnTxt: {
      required: false,
      type: String,
    },
    textCenter: Boolean,
    noDivider: Boolean,
    width: {
      type: String,
      default: "fit-content"
    },
    isForDraft: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    __PromptVisibility: {
      get() {
        return this.promptVisibility;
      },
      set(value) {
        this.$emit("update:promptVisibility", value);
      },
    },
  },
  methods: {
    doOk() {
      this.$emit("doOk", this.emitData);
      if (this.isForDraft)  this.__PromptVisibility = false;
    },

    doCancel() {
      if (!this.isForDraft)  this.__PromptVisibility = false;
      this.$emit("doCancel");
    },
  },
};
