export default {
  name: "LoadingScreen",
  props: {
    type: String,
    displayModal: Boolean,
    mesg: String,
    progress: Number,
    progressLabel: String,
    overlay: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    loadingMesg() {
      return this.mesg ? this.mesg : "Saving...";
    },
    progressHeight() {
      return this.progress || this.progressLabel ? 16 : 5;
    },
  },
  mounted() {},
};
