const MainLayout = import("layouts/MainLayout");

import main from "./main";
import authentication from "./authentication";
import order from "./order";
import systemMaintenance from "./system-maintenance";
import analytics from "./analytics";

const routes = [
  {
    path: "/",
    name: "default",
    redirect: "/dashboard",
    component: () => MainLayout,
    children: [...systemMaintenance, ...order, ...main, ...analytics],
  },
  ...authentication,
];

export default routes;
