export default {
  name: "CustomAutoComplete",
  props: {
    label: String,
    options: { type: Array, default: () => [] },
    render: String,
    keyValue: String,
    name: String,
    isSearchFilter: Boolean,
    searchBy: String,
    disable: Boolean,
    multiple: { type: Boolean, default: () => false },
    dense: { type: Boolean, default: () => false },
    forceValue: String,
    rules: { type: Array, default: () => [] },
    modelInstance: Function,
    limit: Number | String,
    isDataManagement: Boolean,
    fixedParamsFilter: Object,
    clearOnFocus: Boolean,
    queryDescription: String,
    modelQueryOptions: Object,
    byId: Boolean,
    initialValue: Object,
    maxlength: Number | String,

    ableToCreateNoData: { type: Boolean, default: () => false },
    modelCreateAction: Function,
    createVariable: Object,

    customMethodValidation: Function, //Custom Validation for modelCreateAction
    isToUpperSearch: { type: Boolean, default: () => false },
    excludedOnSearch: Array, // collection of item UUIDs ['23408428423842094823']
    hideDetails: Boolean,
  },
  data() {
    return {
      localOptions: this.options,
      rawOptions: [],
      filteredOptions: [],
      keys: [],
      renderKeys: [],
      loading: false,
      searchValue: "",
      selected: this.multiple ? [] : null,
      pagination: false,
      offset: null,
      isError: false,
    };
  },
  watch: {
    async pagination(val) {
      if (val) {
        await this.loadDebounce();
        return false;
      }
    },
    async searchValue() {
      if (this.isToUpperSearch)
        this.searchValue = this.searchValue?.toUpperCase() || "";
      this.offset = null;
      if (!this.loading) await this.searchDebounce();
    },
  },
  methods: {
    buildSingleVariable(createItem) {
      const { fieldName, type } = this.createVariable;
      return {
        [fieldName]: {
          type: type,
          value: createItem,
          unincludeToFields: true,
        },
      };
    },
    async onEnter(e) {
      e.preventDefault();
      const result = this.customMethodValidation(this.searchValue);
      if (result !== true) {
        this.showNotifyMessage({
          message: result,
          type: "danger",
        });
        return;
      }
      await this.createDebounce();
    },
    async createAction() {
      try {
        if (this.searchValue && !this.loading && this.itemList.length == 0) {
          let createItem = this.searchValue;
          this.loading = true;
          await this.modelCreateAction({
            variables: this.buildSingleVariable(createItem),
            queries: {},
            collectionQueries: [],
          });
          this.searchValue = "";
          this.showNotifyMessage({
            message: `Successfully saved`,
            type: "Success",
          });
          this.$nextTick(() => {
            this.searchValue = createItem;
          });
          await this.searchDebounce();
        }
      } catch (e) {
        this.searchValue = "";
        this.showNotifyMessage({
          message: `Unable to save data.`,
          type: "danger",
        });
      }
    },
    prepareOptionsRender() {
      if (this.localOptions) {
        let renderKeys;
        let renderResult;
        // set keys
        this.keys = this.getAllKeys(this.localOptions[0]);

        if (this.render) {
          renderKeys = this.render.match(/(<([^>]+)>)/gi);
          renderKeys = renderKeys.map((key) => {
            return key.replace(/<|>/g, "");
          });

          renderResult = this.render;
        } else {
          renderKeys = this.getAllKeys(this.localOptions[0]);
          const newRenderKeys = renderKeys.map((key) => {
            return "<" + key + ">";
          });

          renderResult = newRenderKeys.join(" ");
        }

        // set render keys
        this.renderKeys = renderKeys;

        this.rawOptions = this.renderObj(
          this.localOptions,
          this.renderKeys,
          renderResult
        );
      }
    },
    renderObj(options, renderKeys, render) {
      return options.map((opt) => {
        // get values from keys
        let renderObj;
        let renderString;

        for (var k = 0; k < renderKeys.length; k++) {
          const regex = new RegExp("<" + renderKeys[k] + ">");

          if (k === 0) {
            renderString = render.replace(regex, opt[renderKeys[k]].value);
          } else {
            renderString = renderString.replace(
              regex,
              opt[renderKeys[k]].value
            );
          }
        }

        renderObj = {
          id: opt.id,
          label: renderString,
          value: renderString,
        };

        return renderObj;
      });
    },
    async filterOptions(val) {
      try {
        if (val !== null) {
          const needle = val.toLowerCase();
          let result;

          this.modelQueryOptions.offset = this.offset;

          if (this.modelInstance !== undefined) {
            if (!this.pagination) {
              this.filteredOptions = [];
              this.localOptions = [];
              this.loading = true;
            }

            if (this.searchBy) {
              const searchBy = this.searchBy.split(",");
              const filter = {};

              for (var i = 0; i < searchBy.length; i++) {
                filter[searchBy[i]] = val;
              }

              // fixed param filters
              if (this.fixedParamsFilter !== undefined) {
                const paramKeys = Object.keys(this.fixedParamsFilter);

                if (paramKeys.length > 0) {
                  for (var p = 0; p < paramKeys.length; p++) {
                    filter[paramKeys[p]] = this.fixedParamsFilter[paramKeys[p]];
                  }
                }
              }
              const customFilter = this.modelQueryOptions?.filter || {};
              this.modelQueryOptions.filter = {
                ...customFilter,
                ...filter,
              };

              result = await this.modelInstance
                .api()
                .query(this.modelQueryOptions, "to fetch data.");
            } else {
              result = await this.modelInstance
                .api()
                .query(this.modelQueryOptions, "to fetch data.");
            }

            if (this.isSearchFilter) this.saveResult(result.response.data);

            if (result.response.data) {
              this.offset = this.offset
                ? this.offset + result.response.data.length
                : result.response.data.length || null;
            }

            let newResult = result.response.data.map((item) => {
              const resultKeys = Object.keys(item);
              let resultObj = {};
              for (var k = 0; k < resultKeys.length; k++) {
                resultObj[resultKeys[k]] = {
                  label: resultKeys[k],
                  value: item[resultKeys[k]],
                };
              }

              return resultObj;
            });

            this.localOptions = this.localOptions.concat(newResult);

            let renderKeys = this.render.match(/(<([^>]+)>)/gi);
            renderKeys = renderKeys.map((key) => {
              return key.replace(/<|>/g, "");
            });

            this.filteredOptions = this.filteredOptions.concat(
              Object.freeze(
                this.checkSelected(
                  this.renderObj(newResult, renderKeys, this.render)
                )
              )
            );

            if (this.filteredOptions.length === 0) {
              this.populateFields({
                [this.keyValue]: val,
              });
            }
          } else {
            if (this.searchBy !== undefined) {
              let findLike = this.localOptions.filter((opt) => {
                return (
                  opt[this.searchBy].value.toLowerCase().indexOf(needle) > -1
                );
              });
              this.filteredOptions = Object.freeze(
                findLike.map((f) => {
                  return this.rawOptions.find((rawOpt) => rawOpt.id === f.id);
                })
              );
            } else {
              this.filteredOptions = Object.freeze(
                this.rawOptions.filter(
                  (v) => v.value.toLowerCase().indexOf(needle) > -1
                )
              );
            }

            if (this.filteredOptions.length === 0) {
              this.populateFields({
                [this.keyValue]: val,
              });
            }
          }

          this.loading = false;
        }
      } catch (e) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
      } finally {
        this.loading = false;
        this.pagination = false;
      }
    },
    populateFields(val) {
      let objCallback = {};
      if (val && val !== null) {
        if (this.objectHasOwnProperty(val, "id")) {
          const findOpt = this.localOptions.find((opt) => {
            return opt.id === val.id;
          });

          if (typeof findOpt === "object") {
            for (var k in findOpt) {
              objCallback[k] = findOpt[k].value;
            }
          }
        } else {
          objCallback = val;
        }
        const selectedValue =
          this.selected && Array.isArray(this.selected)
            ? this.selected.map(({ id }) => id?.value)
            : this.selected?.id?.value || null;
        this.$emit(
          "populateFields",
          this.isSearchFilter && selectedValue
            ? this.parseData(selectedValue)
            : objCallback
        );
      }
    },
    parseData(data) {
      if (typeof data === "object")
        return this.rawOptions.filter((opt) => data.includes(opt.value.id));
      return this.rawOptions.find((opt) => opt.value.id == data);
    },
    async clearFields() {
      this.filteredOptions = [];
      this.$emit("clearFields", this.keys);
      this.searchValue = "";
      this.selected = null;
      this.offset = null;
      await this.loadDebounce();
    },
    getAllKeys(options) {
      let newKeys = [];
      for (var idx in options) {
        if (idx !== "id") {
          newKeys.push(idx);
        }
      }
      return newKeys;
    },
    remove(item) {
      this.selected.splice(
        this.selected.findIndex((val) => val.id.value == item.value),
        1
      );
    },
    saveResult(res) {
      let searchBy = this.searchBy.replace(/ /g, "").split(",");
      res = res.map((val) => {
        let returnVal = { id: val.id };
        if (!this.byId)
          for (let i = 0; i < searchBy.length; i++)
            returnVal[searchBy[i]] = val[searchBy[i]];
        return { value: returnVal };
      });

      for (let i = 0; i < res.length; i++)
        if (this.rawOptions.findIndex((opt) => opt.value.id == res[i].id) == -1)
          this.rawOptions.push(res[i]);
    },
    checkSelected(obj) {
      if (!this.selected || this.name !== "lazy-loading") return obj;
      let selectedIndex = -1;
      if (!Array.isArray(this.selected)) {
        selectedIndex = obj.findIndex(
          (ob) => ob.id.value == this.selected.id.value
        );
        if (selectedIndex !== -1) obj.splice(selectedIndex, 1);
        if (this.offset == 10) obj.unshift(this.selected);
      } else {
        for (let i = 0; i < this.selected.length; i++) {
          selectedIndex = obj.findIndex(
            (opt) => opt.id.value == this.selected[i].id.value
          );
          if (selectedIndex !== -1) obj.splice(selectedIndex, 1);
          if (this.offset == 10) obj.unshift(this.selected[i]);
        }
      }

      return obj;
    },
    async atBlur() {
      this.offset = null;
      this.searchValue = "";
      this.filteredOptions = [];
      if (this.$refs[this.name].isFocused) await this.loadDebounce();
    },
    async atFocus() {
      this.loading = true;
      this.offset = null;
      this.filteredOptions = [];
      await this.loadDebounce();
    },
    atChange(val) {
      this.populateFields(val);
      if (this.searchValue) {
        if (this.selected && Array.isArray(this.selected)) {
          const ids = this.selected.map((obj) => obj.id.value);
          this.filteredOptions = this.filteredOptions.filter(
            (opt) =>
              opt.label
                .toLowerCase()
                .includes(this.searchValue.toLowerCase()) ||
              ids.includes(opt.id)
          );
        } else {
          this.filteredOptions = this.filteredOptions.filter((opt) =>
            opt.label.toLowerCase().includes(this.searchValue.toLowerCase())
          );
        }
      }
    },
  },
  computed: {
    itemList() {
      return this.loading || !this.filteredOptions.length
        ? this.multiple
          ? this.selected
          : this.selected
          ? [this.selected]
          : []
        : this.excludedOnSearch
        ? this.filteredOptions.length
          ? this.filteredOptions.filter((item) => {
              return item.id
                ? !this.excludedOnSearch.includes(item.id.value)
                : true;
            })
          : this.filteredOptions
        : this.filteredOptions;
    },
  },
  mounted() {
    this.$nextTick(async () => {
      // get all keys from rendering pattern
      this.prepareOptionsRender();
      if (!this.objectHasOwnProperty(this.modelQueryOptions, "limit"))
        this.modelQueryOptions["limit"] = 10;
      // await this.filterOptions("");
    });
  },
  async created() {
    this.searchDebounce = await this.debounce(async () => {
      try {
        if (this.$refs[this.name].isMenuActive) {
          await this.filterOptions(this.searchValue || "");
        }
      } catch (e) {
        //
      }
    }, 500);
    this.loadDebounce = await this.debounce(async () => {
      try {
        if (this.$refs[this.name].isFocused) {
          await this.filterOptions(this.searchValue || "");
        }
      } catch (e) {
        //
      }
    }, 650);

    this.createDebounce = await this.debounce(async () => {
      try {
        if (this.$refs[this.name].isFocused) {
          await this.createAction();
        }
      } catch (e) {
        //
      }
    }, 200);

    if (this.initialValue) {
      this.selected = this.initialValue;
    }
  },
};
