import { Model } from "@vuex-orm/core";

export default class DataManagement extends Model {
  static entity = "data_management";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      orderUUID: this.attr(null),
      practiceUUID: this.attr(null),
      patientUUID: this.attr(null),
      payorUUID: this.attr(null),
      patientInternalId: this.attr(null),
      orderInternalId: this.attr(null),
      insuranceCompanyUUID: this.attr(null),
      containerVisualInspectionCategoryUUID: this.attr(null),
      sampleVisualInspectionCategoryUUID: this.attr(null),
      isView: this.boolean(false),
    };
  }
}
