import { uuid } from "vue-uuid";
import actions from "modules/actions";
export default {
  name: "PhoneSection",
  props: {
    phones: Array,
    type: String,
    defaultPhoneIndex: Number,
    singleSelection: Boolean,
    noType: Boolean,
    disabled: Boolean,
    deleteMsg: String,
  },
  data() {
    return {
      actions: new actions(),
      rules: {
        forPhone: [(val) => val.length != 0 || "This field is required"],
        forPhoneType: [
          (val) => (val != null && val !== "") || "This field is required",
        ],
        isRequired: (val) => {
          return val.length != 0 || "This field is required";
        },
        validatePhoneCountryCode: (val, countryCode) => {
          if (countryCode) {
            return this.validatePhoneViaCountryCode(val, countryCode.code);
          }

          return true;
        },
        isNumeric: (val) =>
          !isNaN(val) || "Phone number only allows numeric characters.",
        hasSpecialChars: (val) =>
          /^[a-zA-Z0-9 ]*$/.test(val) ||
          "Phone number only allows numeric characters.",
        validateLength: (val) =>
          val.length >= 5 || "Phone length should be atleast 5 characters.",
      },
      oldPhoneIds: [],
    };
  },
  computed: {
    defaultPhone: {
      get() {
        return this.defaultPhoneIndex;
      },
      set(value) {
        this.$emit("updatePhoneIndex", value, this.type);
      },
    },
    getLabels() {
      let options = [];
      for (let i = 0; i < this.phones.length; i++) {
        if (this.phones[i].id)
          options.push({
            id: this.phones[i].id,
            label: this.phones[i].phoneType.label,
            value: this.phones[i].phone,
            isDefault: this.defaultPhone == i,
          });
      }
      return options;
    },
    phoneTypeOptions() {
      const options = this.actions.getAllPhoneTypes().map((type) => {
        return {
          id: type.id,
          label: type.label,
          value: type.id,
        };
      });
      return options;
    },
    phoneCountryCodeOptions() {
      return this.getAllPhoneCountryCodes().map((country) => {
        return {
          shortName: country.id,
          label: `${country.name} (+${country.phone})`,
          value: `${country.name}-${country.phone}`,
          code: country.phone,
        };
      });
    },
  },
  methods: {
    openDialog(e) {
      this.$emit("openDialog", e);
    },

    addNewPhone() {
      this.phones.push({
        uid: uuid.v1(),
        id: null,
        phone: "",
        phoneTypeUUID: null,
        phoneType: "",
        countryCode: {
          code: "1",
          shortName: "US",
        },
      });
    },
    async savePhones() {
      if (this.phones.length == 0) {
        this.showNotifyMessage({
          message: "Please input atleast one phone number",
          type: "danger",
        });
      } else {
        this.$emit("showLoading", true);
      }
    },

    async removePhonePerItem(phoneId, index = null) {
      if (phoneId == null) {
        let phones = this.phones.splice(index, 1);
        this.$emit("update:payorModel.phones", phones);
        return;
      }
      this.$q
        .dialog({
          title: "Confirm",
          message:
            this.deleteMsg ||
            "Are you sure you want to remove this phone from the current order?",
          persistent: true,
          ok: "Yes",
          cancel: "No",
        })
        .onOk(async () => {
          if (phoneId) {
            this.$emit("onRemovePhone", phoneId);
          }
        });
    },
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },
    countryCodeLabel(item) {
      if (item === undefined) return;

      const findCountryCode = this.phoneCountryCodeOptions.find(
        (countryCode) =>
          countryCode.shortName === item.shortName &&
          countryCode.code === item.code
      );

      if (typeof findCountryCode !== "object") {
        const findUsCountryCode = this.phoneCountryCodeOptions.find(
          (countryCode) =>
            countryCode.shortName.toLowerCase() === "us" &&
            countryCode.code === "1"
        );
        return findUsCountryCode.label;
      }

      return findCountryCode.label;
    },
  },
  updated() {
    this.$nextTick(async () => {
      if (this.phones.length) {
        this.oldPhoneIds = this.phones.map((phone) => phone.id);
      }
      if (this.phones[0] && this.phones[0].countryCode) {
        this.phones[0].countryCode = this.phoneCountryCodeOptions.find(
          (x) => x.shortName == this.phones[0].countryCode.shortName
        );
      }
    });
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.phones.length) {
        this.oldPhoneIds = this.phones.map((phone) => phone.id);
      }
      if (this.phones[0] && this.phones[0].countryCode) {
        this.phones[0].countryCode = this.phoneCountryCodeOptions.find(
          (x) => x.shortName == this.phones[0].countryCode.shortName
        );
      }
    });
  },
};
