import { Model } from "@vuex-orm/core";
export default class PhysicianSignature extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "physician_signature";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      filename: this.string(""),
      signatureType: this.string(""),
      physicianSignatureData: this.string(""),
      dateSigned: this.attr(null),
    };
  }
  // static apiConfig = {
  //   actions: {
  //     async createPhysicianSignature(data) {
  //       const { signatureType, uuid, fileObj, dateSigned } = data;
  //       const variables = { signatureType, dateSigned };
  //       const formData = new FormData();

  //       if (["NA", "TRF"].includes(signatureType.toUpperCase())) {
  //         variables.filename = "n/a";
  //         variables.physicianSignatureData = "n/a";
  //       } else {
  //         variables.filename = fileObj.name;
  //         variables.physicianSignatureData = {};
  //         formData.append("file", fileObj);
  //         formData.append("uuid", uuid);
  //       }

  //       formData.append("docType", "signature");
  //       variables.dateSigned
  //         ? formData.append(
  //             "mutation",
  //             "mutation ($filename: String, $dateSigned: String!,  $signatureType: SignatureType!, $physicianSignatureData: String!) { createPhysicianSignature (filename: $filename, dateSigned: $dateSigned, signatureType: $signatureType, physicianSignatureData: $physicianSignatureData) { id filename dateSigned signatureType physicianSignatureData } }"
  //           )
  //         : formData.append(
  //             "mutation",
  //             "mutation ($filename: String,  $signatureType: SignatureType!, $physicianSignatureData: String!) { createPhysicianSignature (filename: $filename,  signatureType: $signatureType, physicianSignatureData: $physicianSignatureData) { id filename  signatureType physicianSignatureData } }"
  //           );
  //       formData.append("variables", JSON.stringify(variables));

  //       try {
  //         const result = await this.post(
  //           "/accession/step2/create-physician-signature",
  //           formData,
  //           {
  //             headers: { "Content-Type": "multipart/form-data" }
  //           }
  //         );
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async updatePhysicianSignatureDetails(data) {
  //       const {
  //         physicianSignatureId,
  //         signatureType,
  //         uuid,
  //         fileObj,
  //         dateSigned
  //       } = data;
  //       const variables = { physicianSignatureId, signatureType, dateSigned };
  //       const formData = new FormData();
  //       if (["NA", "TRF"].includes(signatureType.toUpperCase())) {
  //         variables.filename = "n/a";
  //         variables.physicianSignatureData = "n/a";
  //       } else {
  //         variables.filename = fileObj.name;
  //         variables.physicianSignatureData = {};
  //         formData.append("file", fileObj);
  //         formData.append("uuid", uuid);
  //       }

  //       formData.append("docType", "signature");
  //       variables.dateSigned
  //         ? formData.append(
  //             "mutation",
  //             "mutation ($physicianSignatureId:UUID! $filename: String!, $dateSigned: String!, $signatureType: SignatureType!, $physicianSignatureData: String!) { updatePhysicianSignatureDetails (physicianSignatureId: $physicianSignatureId) { id updateFilename(filename: $filename) updateDateSigned(dateSigned: $dateSigned) updateSignatureType(signatureType: $signatureType) updatePhysicianSignatureData(physicianSignatureData: $physicianSignatureData) } }"
  //           )
  //         : formData.append(
  //             "mutation",
  //             "mutation ($physicianSignatureId:UUID! $filename: String!, $signatureType: SignatureType!, $physicianSignatureData: String!) { updatePhysicianSignatureDetails (physicianSignatureId: $physicianSignatureId) { id updateFilename(filename: $filename) updateSignatureType(signatureType: $signatureType) updatePhysicianSignatureData(physicianSignatureData: $physicianSignatureData) } }"
  //           );
  //       formData.append(
  //         "query",
  //         "query ($physicianSignatureId: UUID!) { getPhysicianSignature(id: $physicianSignatureId) { id filename signatureType physicianSignatureData dateSigned } }"
  //       );
  //       formData.append("variables", JSON.stringify(variables));

  //       try {
  //         const result = await this.post(
  //           "/accession/step2/update-physician-signature-details",
  //           formData,
  //           {
  //             headers: { "Content-Type": "multipart/form-data" }
  //           }
  //         );
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async deletePhysicianSignature(data) {
  //       try {
  //         const result = await this.post(
  //           "/accession/step2/delete-physician-signature",
  //           {
  //             mutation:
  //               "mutation ($id: UUID!) { deletePhysicianSignature(id: $id) }",
  //             variables: JSON.stringify(data)
  //           }
  //         );
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     }
  //   }
  // };
}
