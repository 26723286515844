import QueryModel from "store/models/QueryModel";

export default class Email extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "email";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      email: this.attr(""),
      practiceId: this.attr(null),
      patientId: this.attr(null),
      insuredId: this.attr(null),
    };
  }

  static defaultData = ["id", "email", "created", "isDeleted", "totalCount"];
}
