import QueryModel from "store/models/QueryModel";

export default class EOBDepositInformation extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "eob_deposit_information";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      date: this.attr(null),
      payorId: this.attr(null),
      subscriberId: this.attr(null),
      depositNumber: this.attr(null),
      payorIcn: this.attr(null),
      checkNumber: this.attr(null),
      checkDate: this.attr(null),
      paidAmount: this.attr(null),
    };
  }

  static defaultData = [
    "date",
    "payorId",
    "subscriberId",
    "depositNumber",
    "payorIcn",
    "checkNumber",
    "checkDate",
    "paidAmount",
  ];
}
