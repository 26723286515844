import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";
import InsuranceCompany from "store/models/InsuranceCompany";
import InsuranceRelationshipType from "store/models/InsuranceRelationshipType";
import InsurancePlan from "store/models/InsurancePlan";
import Insured from "store/models/Insured";
import InsuranceCard from "store/models/InsuranceCard";
import Address from "store/models/Address";
import MSP from "store/models/MSP";
import InsuranceNote from "store/models/InsuranceNote";

const graphqlHelper = new GraphqlHelper();
export default class Insurance extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "insurance";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      insuranceGroupId: this.attr(null),
      insuranceMemberId: this.attr(null),
      insuranceCompanyId: this.attr(null),
      insuranceCompany: this.belongsTo(InsuranceCompany, "insuranceCompanyId"),
      insuranceRelationshipTypeId: this.attr(null),
      insuranceRelationshipType: this.belongsTo(
        InsuranceRelationshipType,
        "insuranceRelationshipTypeId"
      ),
      insurancePlanId: this.attr(null),
      insurancePlan: this.belongsTo(InsurancePlan, "insurancePlanId"),
      insuredId: this.attr(null),
      insured: this.belongsTo(Insured, "insuredId"),
      insuranceCards: this.hasMany(InsuranceCard, "insuranceId"),
      addresses: this.hasMany(Address, "insuranceId"),
      patientId: this.attr(null),
      mspId: this.attr(null),
      msp: this.belongsTo(MSP, "mspId"),
      notesIds: this.attr([]),
      notes: this.hasManyBy(InsuranceNote, "notesIds"),
      planId: this.attr(null),
    };
  }
  // Setup to call an endpoint
  // Insurance.api().fetch()

  static defaultData = [
    "payorPriority",
    "insuranceGroupId",
    "insuranceMemberId",
    "authorizationNumber",
    "insurancePlanName",
    "groupName",
    "groupId",
    "planId",
    "subscriberId",
    "created",
    "totalCount",
    "isDeleted",
    graphqlHelper.subQueryTagBuilder("notes", ["note"]),
    graphqlHelper.subQueryTagBuilder("msp", ["mspCode", "mspCodeDescription"]),
    graphqlHelper.subQueryTagBuilder("insuranceCompany", [
      "name",
      "insuranceCompanyId",
      "insuranceSourceType",
      "payorSubGroup",
      "payorGroup",
    ]),
    graphqlHelper.subQueryTagBuilder("insuranceRelationshipType", [
      "label",
      "hippaCode",
    ]),
    graphqlHelper.subQueryTagBuilder("insurancePlan", ["label"]),
    graphqlHelper.subQueryTagBuilder("insuranceCards", [
      "id",
      "insuranceCardData",
      "filename",
      "side",
    ]),
    graphqlHelper.subQueryTagBuilder("insured", [
      "firstName",
      "lastName",
      "middleName",
      "dateOfBirth",
      "gender",
      graphqlHelper.subQueryTagBuilder("defaultAddress", [
        "line1",
        "line2",
        "city",
        "postalCode",
        "country",
        "stateProvince",
        graphqlHelper.subQueryTagBuilder("addressType", ["label"]),
      ]),
      graphqlHelper.subQueryTagBuilder("defaultEmail", ["email"]),
      graphqlHelper.subQueryTagBuilder("defaultPhone", ["phone"]),
      graphqlHelper.subQueryTagBuilder("emails", [
        "email",
        graphqlHelper.subQueryTagBuilder("emailType", ["label"]),
      ]),
      graphqlHelper.subQueryTagBuilder("phones", [
        "phone",
        "areaCode",
        graphqlHelper.subQueryTagBuilder("phoneType", ["label"]),
      ]),
    ]),
    graphqlHelper.subQueryTagBuilder("notes", ["note"]),
  ];
}
