import QueryModel from "store/models/QueryModel";

import GraphqlHelper from "./helpers/graphql-helper";

import InsurancePlan from "store/models/InsurancePlan";
import MSP from "store/models/MSP";

const graphqlHelper = new GraphqlHelper();
export default class InsuranceCompany extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "insurance_company";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      insuranceSourceType: this.string(""),
      insuranceCompanyId: this.string(""),
      insurancePlans: this.hasMany(InsurancePlan, "insuranceCompanyId"),
      mspId: this.attr(null),
      msp: this.belongsTo(MSP, "mspId"),
      ediEnrollmentDateSubmitted: this.string(""),
    };
  }

  static defaultData = [
    "name",
    "insuranceSourceType",
    "insuranceCompanyId",
    "payorGroup",
    "payorSubGroup",
    "created",
    "totalCount",
    "isDeleted",
    "payorEdi",
    "inactive",
    "keywordSearchTags",
    "payorInterlinkTag",
    "effectivityDate",
    "termDate",
    "lastModified",
    graphqlHelper.subQueryTagBuilder("updatedBy", ["id", "name", "email"]),
    graphqlHelper.subQueryTagBuilder("appealsFaxCover", ["filename", "note"]),
    graphqlHelper.subQueryTagBuilder("authorizationFileAttachments", [
      "filename",
      "note",
    ]),
    "authorizationRequirement",
    "authorizationRequirementDetails",
    "authorizationRequirementNote",
    graphqlHelper.subQueryTagBuilder("ediEnrollmentFileAttachments", [
      "filename",
      "note",
    ]),
    graphqlHelper.subQueryTagBuilder("eftEnrollmentFileAttachments", [
      "filename",
      "note",
    ]),
    graphqlHelper.subQueryTagBuilder("eraEnrollmentFileAttachments", [
      "filename",
      "note",
    ]),
    graphqlHelper.subQueryTagBuilder("ediEnrollmentStatus", [
      "enrollmentStatus",
      "description",
    ]),
    "ediEnrollmentRequirement",
    "ediEnrollmentDateSubmitted",
    "ediEnrollmentDateCompleted",
    "ediEnrollmentApprovalNumber",
    "ediEnrollmentNote",
    graphqlHelper.subQueryTagBuilder("eftEnrollmentStatus", [
      "enrollmentStatus",
      "description",
    ]),
    "eftEnrollmentRequirement",
    "eftEnrollmentDateSubmitted",
    "eftEnrollmentDateCompleted",
    "eftEnrollmentApprovalNumber",
    "eftEnrollmentNote",
    graphqlHelper.subQueryTagBuilder("eraEnrollmentStatus", [
      "enrollmentStatus",
      "description",
    ]),
    "eraEnrollmentRequirement",
    "eraEnrollmentDateSubmitted",
    "eraEnrollmentDateCompleted",
    "eraEnrollmentApprovalNumber",
    "eraEnrollmentNote",
    graphqlHelper.subQueryTagBuilder("payorClaimsAddress", [
      "line1",
      "line2",
      "city",
      "postalCode",
      "country",
      "stateProvince",
      "addressType { id,label }",
    ]),
    graphqlHelper.subQueryTagBuilder("payorAppealsAddress", [
      "line1",
      "line2",
      "city",
      "postalCode",
      "country",
      "stateProvince",
      "addressType { id,label }",
    ]),
    graphqlHelper.subQueryTagBuilder("payorOtherAddress", [
      "line1",
      "line2",
      "city",
      "postalCode",
      "country",
      "stateProvince",
      "addressType { id,label }",
    ]),

    "payorOtherAddressDescription",
    graphqlHelper.subQueryTagBuilder("payorClaimPhone", ["phone", "areaCode"]),
    graphqlHelper.subQueryTagBuilder("payorClaimFax", ["phone", "areaCode"]),
    graphqlHelper.subQueryTagBuilder("payorAppealsPhone", [
      "phone",
      "areaCode",
    ]),
    graphqlHelper.subQueryTagBuilder("payorAppealsFax", ["phone", "areaCode"]),
    "payorClaimFaxNote",
    "payorAppealFaxNote",
    "claimsTimelyFilingLimit",
    "resubmissionTimelyFilingLimit",
    "appealsTimelyFilingLimit",
    graphqlHelper.subQueryTagBuilder("insurancePlans", [
      "label",
      "description",
    ]),

    graphqlHelper.subQueryTagBuilder("primaryInsuranceSubmissionMethod", [
      "claimsSubmissionMethod",
      "description",
    ]),
    graphqlHelper.subQueryTagBuilder("secondaryInsuranceSubmissionMethod", [
      "claimsSubmissionMethod",
      "description",
    ]),
    graphqlHelper.subQueryTagBuilder("supplementalInsuranceSubmissionMethod", [
      "claimsSubmissionMethod",
      "description",
    ]),
    graphqlHelper.subQueryTagBuilder("claimFilingIndicator", [
      "claimFilingIndicator",
      "description",
    ]),
    "claimSubmissionNotes",
    "achBankInfo",
  ];
}
