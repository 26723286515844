import QueryModel from "store/models/QueryModel";

// import GraphqlHelper from "./helpers/graphql-helper";

// const graphqlHelper = new GraphqlHelper();

export default class InsuranceRelationshipType extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "insurance_relationship_type";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      hippaCode: this.string(""),
      label: this.string(""),
      description: this.string(""),
    };
  }

  static defaultData = ["hippaCode", "label", "description"];

  // Setup to call an endpoint
  // InsuranceRelationshipType.api().fetch()
  // static apiConfig = {
  //   actions: {
  //     async all(
  //       limit = null,
  //       offset = null,
  //       filter = null,
  //       limitData = null,
  //       isDataManagement = false,
  //       includeDeleted = false,
  //       additionalString = null,
  //       order = null
  //     ) {
  //       let defaultData = limitData ? limitData : this.model.defaultData;

  //       let queryString = graphqlHelper.queryTagBuilder(
  //         "insuranceRelationshipTypes",
  //         defaultData,
  //         {
  //           limit,
  //           offset,
  //           filter,
  //           includeDeleted,
  //           additionalString,
  //           order
  //         }
  //       );

  //       let route = isDataManagement
  //         ? "/data-management/insurance-relationship-type/all"
  //         : "/accession/step4/get-insurance-relationship-types";

  //       const result = await this.post(route, {
  //         query: queryString
  //       });

  //       return result;
  //     },
  //     async create(variables) {
  //       let parameters = [];
  //       let mutationParameter = [];
  //       let setters = [];
  //       let data = JSON.parse(variables);

  //       //data.hasOwnProperty("firstName") && data.firstName != null
  //       if (data.label) {
  //         mutationParameter.push("label");
  //         parameters.push("$label: String!");
  //         setters.push("label");
  //       }
  //       if (data.description) {
  //         mutationParameter.push("description");
  //         parameters.push("$description: String!");
  //         setters.push("description");
  //       }
  //       if (data.hippaCode) {
  //         mutationParameter.push("hippaCode");
  //         parameters.push("$hippaCode: String!");
  //         setters.push("hippaCode");
  //       }
  //       let param = {
  //         parameters: parameters,
  //         mutation: "createInsuranceRelationshipType",
  //         mutationParameter: mutationParameter,
  //         setters: setters
  //       };
  //       let mutationString = graphqlHelper.mutationTagBuilder(param);
  //       const result = await this.post(
  //         "/data-management/insurance-relationship-type/create",
  //         {
  //           mutation: mutationString,
  //           variables
  //         }
  //       );
  //       return [result];
  //     },
  //     async update(variables) {
  //       let parameters = ["$insuranceRelationshipTypeId: UUID!"];
  //       let mutationParameter = ["insuranceRelationshipTypeId"];
  //       let setters = [];
  //       let data = JSON.parse(variables);

  //       if (data.label) {
  //         parameters.push("$label: String!");
  //         setters.push("updateLabel(label:$label)");
  //         setters.push("label");
  //       }
  //       if (data.description) {
  //         parameters.push("$description: String!");
  //         setters.push("updateDescription(description:$description)");
  //         setters.push("description");
  //       }
  //       if (data.hippaCode) {
  //         parameters.push("$hippaCode: String!");
  //         setters.push("updateHippaCode(hippaCode:$hippaCode)");
  //         setters.push("hippaCode");
  //       }

  //       let param = {
  //         parameters: parameters,
  //         mutation: "updateInsuranceRelationshipTypeDetails",
  //         mutationParameter: mutationParameter,
  //         setters: setters
  //       };
  //       let mutationString = graphqlHelper.mutationTagBuilder(param);
  //       const result = await this.post(
  //         "/data-management/insurance-relationship-type/update",
  //         {
  //           mutation: mutationString,
  //           variables
  //         }
  //       );
  //       return [result];
  //     },
  //     async delete(variables) {
  //       try {
  //         const result = await this.post(
  //           "/data-management/insurance-relationship-type/delete",
  //           {
  //             mutation:
  //               "mutation ($id: UUID!) { deleteInsuranceRelationshipType(id: $id) }",
  //             variables: JSON.stringify(variables)
  //           }
  //         );
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     }
  //   }
  // };
}
