var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.title)?_c('p',{staticClass:"font-weight-bold text-h6"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._l((_vm.rows),function(row,index_row){return _c('v-row',{key:("row-" + index_row),staticClass:"d-flex align-content-start flex-wrap"},_vm._l((row.cols),function(data,index_col){return _c('v-col',{key:("cell-" + index_row + index_col),class:{
        'col-default-width': !row.span || !data.colSpan,
      },attrs:{"cols":row.span || data.colSpan || ''}},[_c('v-card',{staticClass:"data-container elevation-0 rounded",class:_vm.agingSummary ? ("aging-summary bg-" + index_col) : null,attrs:{"tile":""}},[(data.clickable)?_c('v-card',{staticClass:"clickable-aging-summary elevation-0",attrs:{"clickable":"","ripple":false},on:{"click":function($event){return _vm.$emit(
              'click',
              data.returnValue
                ? data.returnValue
                : { row: index_row, col: index_col }
            )}}},[_c('v-icon',{attrs:{"color":"#FFF"}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('div',{staticClass:"data-info header"},[_vm._v(" "+_vm._s(data.header)+" "),(data.icon && data.icon.show)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":"4","nudge-top":"7","offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(data.icon.show),expression:"data.icon.show"}],attrs:{"small":"","color":"#FFF"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true),model:{value:(_vm.showInfo[index_col]),callback:function ($$v) {_vm.$set(_vm.showInfo, index_col, $$v)},expression:"showInfo[index_col]"}},[_c('div',{staticClass:"info-container rounded"},[(data.icon.header)?_c('p',[_vm._v(_vm._s(data.icon.header))]):_vm._e(),(data.icon.message)?_c('p',[_vm._v(_vm._s(data.icon.message))]):_vm._e(),_c('v-icon',{staticClass:"arrow-down",attrs:{"color":"#3c7ff8","size":"24"}},[_vm._v(" mdi-menu-down ")])],1)]):_vm._e()],1),_c('div',{staticClass:"data-info font-weight-bold d-inline-flex",class:{
            overflow: data.overflow,
          }},[_vm._v(" "+_vm._s(data.prefix ? data.prefix : "")+" "+_vm._s(data.currency ? _vm.formatCurrency(data.data) || "0.00" : data.data)+" "),(data.status)?_c('div',{class:{
              draft: data.status.toLowerCase() === 'draft',
              roudned: true,
              status: true,
            }},[_vm._v(" "+_vm._s(_vm.formatStatus(data.status))+" ")]):_vm._e()]),(data.button)?_c('div',{staticClass:"btn-section pt-4"},[(data.button)?_c('a',{attrs:{"id":("sumarry_btn" + index_row + "-" + index_col),"color":"primary"},on:{"click":function($event){return _vm.$emit(
                'click',
                data.returnValue
                  ? data.returnValue
                  : { row: index_row, col: index_col }
              )}}},[_vm._v(" "+_vm._s(data.button.label || "Click Here")+" ")]):_vm._e()]):_vm._e()],1),_c('v-spacer')],1)}),1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }