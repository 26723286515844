import ClientInvoice from "store/models/ClientInvoice";
import StoreActions from "store/actions";

const storeActions = new StoreActions(ClientInvoice);

export default class ClientInvoiceAction {
  async getAll(params) {
    const { limitData, variables } = params;
    return await storeActions.query(
      {
        queryName: "getVbsClientInvoiceById",
        relativeUrl: "/client-invoice/get-all",
        limitData: limitData || [],
      },
      "to load client invoice.",
      {
        ...variables,
      }
    );
  }

  async discardDraft(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation(
      {
        queryName: "draftVbsClientInvoice",
        relativeUrl: "/client-invoice/discard-draft",
        limitData,
        shouldNotReturnId,
      },
      "To discard draft client invoice transaction.",
      {
        clientInvoiceId: id,
      }
    );
  }

  async addNote(params) {
    const { variables, limitData = [] } = params;
    return await storeActions.query(
      {
        queryName: "getVbsClientInvoiceById",
        relativeUrl: "/client-invoice/add-note",
        limitData,
        shouldNotReturnId: true,
      },
      "To add note(s) to client invoice.",
      {
        ...variables,
      }
    );
  }

  async getClientInvoiceTransaction(params) {
    const { variables, limitData = [] } = params;

    return await storeActions.query(
      {
        queryName: "getVbsClientInvoiceTransactionById",
        relativeUrl: "/client-invoice/get-transaction",
        limitData,
      },
      "To fetch transaction by ID.",
      { ...variables }
    );
  }

  async applyClientInvoiceTransactionAdjustment(params) {
    const { variables, limitData = [] } = params;

    return await storeActions.mutation(
      {
        queryName: "applyVbsClientInvoiceTransactionAdjustment",
        relativeUrl: "/client-invoice/apply-adjustment",
        limitData,
        shouldNotReturnId: true,
      },
      "To apply transaction adjustment.",
      {
        ...variables,
      }
    );
  }

  async viewClientInvoice(variables) {
    return await storeActions.query(
      {
        queryName: "getVbsClientInvoiceFileById",
        relativeUrl: "/client-invoice/get-invoice-file",
        noneLimitData: true,
      },
      "to view Client Invoice file",
      {
        ...variables,
      }
    );
  }

  async generateClientInvoice(params) {
    const { limitData = [], variables } = params;
    return await storeActions.mutation(
      {
        queryName: "submitVbsDraftClientInvoice",
        relativeUrl: "/client-invoice/generate-invoice",
        limitData,
        shouldNotReturnId: true,
      },
      "to generate Client Invoice file",
      { ...variables }
    );
  }

  async previewClientInvoiceTransactionAdjustment(params) {
    const { variables, limitData = [] } = params;

    return await storeActions.query(
      {
        queryName: "previewVbsClientInvoiceTransactionAdjustment",
        relativeUrl: "/client-invoice/preview-adjustment",
        limitData,
      },
      "To preview the adjustment made in transaction",
      { ...variables }
    );
  }
}
