import QueryModel from "store/models/QueryModel";

export default class PatientSignature extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "patient_signature";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      signatureType: this.string(""),
    };
  }

  static defaultData = ["signatureType"];
}
