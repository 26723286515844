import Vue from "vue";
import draggable from "vuedraggable";
import SearchFilter from "components/SearchFilter";
import AutoComplete from "components/AutoComplete";
import LoadingScreen from "components/LoadingScreen";
import AddressSection from "components/AddressSection";
import PhoneSection from "components/PhoneSection";
import SearchModal from "components/SearchModal";
import DialogModal from "components/DialogModal";
import CurrencyField from "components/CurrencyField";
import PromptModal from "components/PromptModal";
import DiscardModal from "components/DiscardModal";
import EmailSection from "components/EmailSection";
import PhoneSection2 from "components/PhoneSection2";
import CustomDataTable from "components/CustomDataTable";
import Status from "components/Status";
import ChangeStatus from "components/ChangeStatus";
import AuditTrail from "components/AuditTrail";
import PDFViewer from "components/PDFViewer";
import SearchDataTableModal from "components/SearchDataTableModal";
import HTMLRenderer from "components/HTMLRenderer";
import SummaryTable from "components/SummaryTable";

import { VueMaskDirective } from "v-mask";

const componentRegister = function componentRegister() {
  Vue.directive("mask", VueMaskDirective);
  Vue.component("draggable", draggable);
  Vue.component("v-searchfilter", SearchFilter);
  Vue.component("v-custom-autocomplete", AutoComplete);
  Vue.component("v-loading", LoadingScreen);
  Vue.component("v-address-section", AddressSection);
  Vue.component("v-phone-section", PhoneSection);
  Vue.component("v-search-modal", SearchModal);
  Vue.component("v-dialog-modal", DialogModal);
  Vue.component("v-currency-field", CurrencyField);
  Vue.component("v-prompt-modal", PromptModal);
  Vue.component("v-discard-modal", DiscardModal);
  Vue.component("v-email-section", EmailSection);
  Vue.component("v-phone-section-2", PhoneSection2);
  Vue.component("v-custom-datatable", CustomDataTable);
  Vue.component("v-status", Status);
  Vue.component("v-change-status", ChangeStatus);
  Vue.component("v-audit-trail", AuditTrail);
  Vue.component("v-pdf-viewer", PDFViewer);
  Vue.component("v-search-datatable-modal", SearchDataTableModal);
  Vue.component("v-html-renderer", HTMLRenderer);
  Vue.component("v-summary-table", SummaryTable);
};

export default componentRegister;
