import QueryModel from "store/models/QueryModel";

import GraphqlHelper from "store/models/helpers/graphql-helper";

import SampleStorageLaboratory from "store/models/SampleStorageLaboratory";

const graphqlHelper = new GraphqlHelper();
export default class Sample extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "sample";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      sampleId: this.attr(""),
      receiveDate: this.attr(""),
      notes: this.attr(null),
      sampleStorageLaboratoryId: this.attr(null),
      sampleStorageLaboratory: this.belongsTo(
        SampleStorageLaboratory,
        "sampleStorageLaboratoryId"
      ),
    };
  }

  static defaultData = [
    "sampleId",
    "receiveDate",
    graphqlHelper.subQueryTagBuilder("notes", ["title", "note"]),
    graphqlHelper.subQueryTagBuilder(
      "sampleStorageLaboratory",
      SampleStorageLaboratory.defaultData
    ),
  ];
}
