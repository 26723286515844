import { Model } from "@vuex-orm/core";

export default class AccessionEntry extends Model {
  static entity = "accession_entry";

  static fields() {
    return {
      id: this.attr(null),
      abn: this.attr(""),
      internalId: this.attr(null),
      requisitionId: this.attr(""),
      dateSampleTaken: this.attr(""),
      dateFirstTransmitted: this.attr(""),
      testRequisitionFormId: this.attr(""),
      billingNetsuiteStatus: this.attr(""),
      billingAccessionStatus: this.attr(""),
      billingStatementStatus: this.attr(""),
      netsuiteInvoice: this.attr(""),
      billedAmount: this.attr(""),
      balanceDue: this.attr(""),
      priceDate: this.attr(""),
      priorAuthorization: this.attr(null),
      patientSignature: this.attr(() => ({
        id: null,
        signatureType: "",
      })),
      physician: this.attr(() => ({
        orderingPhysician: {
          id: null,
          firstName: "",
          lastName: "",
          taxonomy: "",
          npi: {
            id: null,
            npiNumber: "",
          },
        },
        referringPhysician: {
          id: null,
          firstName: "",
          lastName: "",
          taxonomy: "",
          npi: {
            id: null,
            npiNumber: "",
          },
        },
      })),
      testOrdered: this.attr(() => ({
        id: null,
        placeOfService: {
          id: null,
          posCode: "",
        },
        test: {
          id: null,
          testCode: "",
        },
      })),
      patient: this.attr(() => ({
        info: {
          id: null,
          gender: "",
          dateOfBirth: "",
          firstName: "",
          lastName: "",
          patientSsn: "",
        },
        addresses: {
          id: null,
          city: "",
          country: "",
          line1: "",
          line2: "",
        },
        defaultAddress: {
          id: null,
          city: "",
          country: "",
          line1: "",
          line2: "",
          stateProvince: "",
          postalCode: "",
        },
        defaultPhone: {
          id: null,
          phone: "",
          countryCode: null,
        },
        phones: {
          id: null,
          phone: "",
          countryCode: null,
        },
        defaultEmail: {
          id: null,
          email: "",
        },
      })),
      test: this.attr(() => ({
        id: null,
        testOrderedId: null,
        cptCode: {
          id: null,
          testCode: "",
          code: "",
          description: "",
          modifierCode: "",
          posCode: "",
          price: "",
        },
      })),
      insurances: this.attr([]),
      notes: this.attr([]),
      payorAccount: this.attr({
        payorCode: "",
        payorName: "",
      }),
      sample: this.attr({
        sampleId: "",
        receiveDate: "",
        sampleStorageLaboratory: {
          id: null,
          label: "",
        },
      }),
      billingClaimsSubmission: this.attr([]),
      billingTransactionDetails: this.attr([]),
      orderBillingErrorCodes: this.attr([]),
      addedOrderBillingErrorCodes: this.attr([]),
      eobDepositInformation: this.attr([]),
      billingDiagnosticCode: this.attr([]),
      orderEntrySaved: this.attr(false),
      billType: this.attr(""),
      dateOfService: this.attr(""),
      billingHistory: this.attr(null),
    };
  }
}
