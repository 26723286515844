import QueryModel from "store/models/QueryModel";

import GraphqlHelper from "./helpers/graphql-helper";

import InsuranceCompany from "store/models/InsuranceCompany";

const graphqlHelper = new GraphqlHelper();
export default class ConversionRulePayorPerPayorSubGroup extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "conversion_rule_payor_per_payorsubgroup";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      payors: this.hasMany(InsuranceCompany, "insuranceCompanyId"),
      totalPayorsCount: this.number(0),
      totalCount: this.number(0),
    };
  }

  static defaultData = [
    "payorSubGroup",
    graphqlHelper.subQueryTagBuilder("payors", [
      "name",
      "insuranceCompanyId",
      "payorGroup",
    ]),
    "totalPayorsCount",
    "totalCount",
  ];
}
