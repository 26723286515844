const AuthLayout = import("layouts/AuthLayout");
const Login = import("modules/authentication/Login");
const ResetPassword = import("modules/authentication/ResetPassword");
const ChangePassword = import("modules/authentication/ChangePassword");
const Error403Page = import("pages/Error403");
const Error404Page = import("pages/Error404");

const routes = [
  {
    path: "/login",
    component: () => AuthLayout,
    children: [
      {
        path: "",
        name: "login",
        component: () => Login,
      },
    ],
  },
  {
    path: "/reset-password",
    component: () => AuthLayout,
    children: [
      {
        path: "",
        name: "reset-password",
        component: () => ResetPassword,
      },
    ],
  },
  {
    path: "/change-password",
    component: () => AuthLayout,
    children: [
      {
        path: "",
        name: "change-password",
        component: () => ChangePassword,
      },
    ],
  },
  {
    path: "/access-not-granted",
    component: () => AuthLayout,
    children: [
      {
        path: "",
        name: "access-not-granted",
        component: () => Error403Page,
      },
    ],
  },
  {
    path: "/page-not-found",
    component: () => AuthLayout,
    children: [
      {
        path: "",
        name: "page-not-found",
        component: () => Error404Page,
      },
    ],
  },
];
export default routes;
