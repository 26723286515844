import { Model } from "@vuex-orm/core";
export default class InsuranceCard extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "insurance_card";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      insuranceCardData: this.string(""),
      filename: this.string(""),
      side: this.string(""),
      insuranceId: this.attr(null),
    };
  }
  // Setup to call an endpoint
  // InsuranceCard.api().fetch()
  // static apiConfig = {
  //   actions: {
  //     async createInsuranceCard(data, isDataManagement = false) {
  //       const { uuid, fileObj } = data;
  //       const variables = {};
  //       const formData = new FormData();
  //       let route = isDataManagement
  //         ? "/data-management/patient/create-insurance-card"
  //         : "/accession/step4/create-insurance-card";

  //       variables.filename = fileObj.name;
  //       variables.insuranceCardData = {};

  //       formData.append("file", fileObj);
  //       formData.append("uuid", uuid);
  //       formData.append("docType", "insurance_card");
  //       formData.append(
  //         "mutation",
  //         "mutation ($filename: String, $side: Side!, $insuranceCardData: String!) { createInsuranceCard (filename: $filename, side: $side, insuranceCardData: $insuranceCardData) { id filename side insuranceCardData } }"
  //       );
  //       formData.append("variables", JSON.stringify(variables));

  //       try {
  //         const result = await this.post(route, formData, {
  //           headers: { "Content-Type": "multipart/form-data" }
  //         });
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async updateInsuranceCardDetails(data, isDataManagement = false) {
  //       const { insuranceCardId, uuid, fileObj } = data;
  //       const variables = { insuranceCardId };
  //       const formData = new FormData();
  //       let route = isDataManagement
  //         ? "/data-management/patient/update-insurance-card"
  //         : "/accession/step4/update-insurance-card-details";

  //       variables.filename = fileObj.name;
  //       variables.insuranceCardData = {};

  //       formData.append("file", fileObj);
  //       formData.append("uuid", uuid);
  //       formData.append("docType", "insurance_card");
  //       formData.append(
  //         "mutation",
  //         "mutation ($insuranceCardId:UUID! $filename: String!, $side: Side!, $insuranceCardData: String!) { updateInsuranceCardDetails (insuranceCardId: $insuranceCardId) { id updateFilename(filename: $filename) updateSide(side: $side) updateCardData(cardData: $insuranceCardData) } }"
  //       );
  //       formData.append(
  //         "query",
  //         "query ($insuranceCardId: UUID!) { getInsuranceCard(id: $insuranceCardId) { id filename side insuranceCardData } }"
  //       );
  //       formData.append("variables", JSON.stringify(variables));

  //       try {
  //         const result = await this.post(route, formData, {
  //           headers: { "Content-Type": "multipart/form-data" }
  //         });
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async deleteInsuranceCard(data, isDataManagement = false) {
  //       try {
  //         let route = isDataManagement
  //           ? "/data-management/patient/delete-insurance-card"
  //           : "/accession/step4/delete-insurance-card";

  //         const result = await this.post(route, {
  //           mutation: "mutation ($id: UUID!) { deleteInsuranceCard(id: $id) }",
  //           variables: JSON.stringify(data)
  //         });
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     }
  //   }
  // };
}
