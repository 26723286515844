import Vue from "vue";
import store from "store";
import router from "router";
import vuetify from "plugins/vuetify";
import apolloProvider from "boot/apollo-provider";
import componentRegister from "boot/component-register";
import mixins from "boot/mixins-register";
import filters from "boot/filters-register";
import globalVars from "boot/global-vars";
import App from "./App.vue";

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const app = new Vue({
  store,
  mixins,
  filters: filters.filters,
  vuetify,
  apolloProvider,
  router: router(),
  render: (h) => h(App),
});
componentRegister();
mixins();
globalVars();
filters.initialize();

app.$mount("#v-app");
