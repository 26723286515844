import QueryModel from "store/models/QueryModel";
import Test from "store/models/Test";
import PaymentTerm from "store/models/PaymentTerm";
import PaymentType from "store/models/PaymentType";

import GraphqlHelper from "store/models/helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class BillingClientContractualInformation extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "billing_client_contractual_information";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      billingStatementType: this.attr(""),
      statementBillingTerm: this.attr(""),
      statementBillingTermOther: this.attr(""),
      paymentTerm: this.belongsTo(PaymentTerm),
      paymentTermOther: this.attr(""),
      paymentType: this.belongsTo(PaymentType),
      paymentTypeOther: this.attr(""),
      orderCode: this.attr(""),
      testId: this.belongsTo(Test),
      contractRate: this.attr(""),
      feeSchedule: this.attr(""),
    };
  }

  static defaultData = [
    "billingStatementType",
    "statementBillingTerm",
    "statementBillingTermOther",
    graphqlHelper.subQueryTagBuilder("paymentTerm", ["label"]),
    "paymentTermOther",
    graphqlHelper.subQueryTagBuilder("paymentType", ["label"]),
    "paymentTypeOther",
    "orderCode",
    "contractRate",
    "feeSchedule",
    graphqlHelper.subQueryTagBuilder("testId", ["label"]),
  ];
}
