import QueryModel from "store/models/QueryModel";
import Modifier from "store/models/Modifier";
import PlaceOfService from "store/models/PlaceOfService";
import GraphqlHelper from "store/models/helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class CptCode extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "cpt_code";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      modifierId: this.attr(null),
      placeOfServiceId: this.attr(null),
      code: this.attr(""),
      description: this.attr(""),
      modifier: this.belongsTo(Modifier, "modifierId"),
      placeOfService: this.belongsTo(PlaceOfService, "placeOfServiceId"),
      price: this.attr(""),
    };
  }

  static defaultData = [
    "code",
    "description",
    graphqlHelper.subQueryTagBuilder("modifier", Modifier.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "placeOfService",
      PlaceOfService.defaultData
    ),
    "price",
  ];
}
