import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "store/models/helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class ClientInvoice extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "client_invoice";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      invoiceNumber: this.attr(""),
      invoiceDate: this.attr(""),
      totalBilled: this.attr(null),
      totalPaid: this.attr(null),
      currentBalance: this.attr(null),
      notes: this.attr([]),
      transactions: this.attr([]),
      status: this.attr(""),
    };
  }

  static defaultData = [
    "id",
    "invoiceNumber",
    "invoiceDate",
    "totalBilled",
    "totalPaid",
    "currentBalance",
    "status",
    graphqlHelper.subQueryTagBuilder("revisions", [
      "invoiceNumber",
      "revisionNumber",
      "invoiceDate",
      "created",
      "lastModified",
      "isDeleted",
      "timeDiscarded",
      "timeProcessed",
      "timeGenerated",
      "timeSent",
    ]),
  ];
}
