import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PDFViewer",
  components: { VuePdfEmbed },
  props: {
    title: String,
    blobUrl: String,
    showModal: Boolean,
    viewType: String, // [plain, modal]
    loading: Boolean,
  },
  computed: {
    __ShowModal: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("update:showModal", value);
      },
    },
  },
};
