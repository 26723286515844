import { Model } from "@vuex-orm/core";
import GraphqlHelper from "store/models/helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class OrderBillingHistory extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "order_billing_history";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      orderId: this.attr(null),
      entries: this.attr([]),
    };
  }

  static defaultData = [
    "orderId",
    graphqlHelper.subQueryTagBuilder("entries", [
      "dateCreated",
      "payorId",
      "payorPriority",
      graphqlHelper.subQueryTagBuilder("fileType", [
        "enum",
        "label",
      ], false),
      "filename",
      "fileStatus",
      "fileDetails",
      "referenceNumber",
      "totalCount",
    ], false),
  ];
}
