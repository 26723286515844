export default {
  methods: {
    scrollTo(elemId, isClassName = false) {
      window.scrollBy({
        top: window.document[
          isClassName ? "getElementByClassName" : "getElementById"
        ](elemId).getBoundingClientRect().top,
        behavior: "smooth",
      });
    },
  },
};
