import Vue from "vue";
import FormatDateTime from "filters/format-datetime";
import SanitizefileName from "filters/sanitize-filename";

export default {
  filters: { FormatDateTime },
  initialize: () => {
    Vue.filter("formatDateTime", FormatDateTime.format);
    Vue.filter("getPSTDate", FormatDateTime.getPSTDate);
    Vue.filter("changeDateFormat", FormatDateTime.dateFormat);
    Vue.filter("parseDateFromString", FormatDateTime.parseDateFromString);
    Vue.filter("sanitizefileName", SanitizefileName.sanitizefileName);
    Vue.filter("getPstUtcDate", FormatDateTime.getPstUtcDate);
    Vue.filter("isDateBefore", FormatDateTime.isDateBefore);
    Vue.filter("getTimeDate", FormatDateTime.getTimeDate);
  },
};
