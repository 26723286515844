import { Model } from "@vuex-orm/core";
// import TRFHelper from "./helpers/trf-helper";
import Account from "store/models/Account";
import Order from "store/models/Order";
import GraphqlHelper from "./helpers/graphql-helper";

// const trfHelper = new TRFHelper();
const graphqlHelper = new GraphqlHelper();
export default class TRF extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "trf";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      filename: this.string(""),
      trfVersion: this.string(""),
      trfData: this.string(""),
      testRequisitionFormId: this.attr(null),
      totalCount: this.attr(0),
      orderId: this.string(""),
      order: this.hasOne(Order, "orderId"),
      created: this.belongsTo(Account, "accountId"),
    };
  }

  static defaultData = [
    "id",
    "filename",
    "trfVersion",
    "trfData",
    "testRequisitionFormId",
    "created",
    "lastModified",
    "totalCount",
    "isDeleted",
    graphqlHelper.subQueryTagBuilder("notes", [
      "id",
      "note",
      "created",
      "lastModified",
      graphqlHelper.subQueryTagBuilder("createdBy", Account.defaultData),
    ]),
    // graphqlHelper.subQueryTagBuilder("order", Order.defaultData),
    graphqlHelper.subQueryTagBuilder("createdBy", Account.defaultData),
  ];

  // static apiConfig = {
  //   actions: {
  //     // FOR FUTURE REFERENCE
  //     async all(
  //       limit = null,
  //       offset = null,
  //       filter = null,
  //       limitData = null,
  //       isDataManagement = false,
  //       includeDeleted = false,
  //       additionalString = null,
  //       order = null
  //     ) {
  //       let defaultData = this.model.defaultData;
  //       if (limitData !== null && limitData !== "") {
  //         defaultData = limitData;
  //       }

  //       let route = isDataManagement
  //         ? "/data-management/get-all-trf"
  //         : "/data-management/get-all-trf";

  //       let queryString = graphqlHelper.queryTagBuilder("trfs", defaultData, {
  //         limit,
  //         offset,
  //         filter,
  //         order,
  //         additionalString,
  //         includeDeleted
  //       });
  //       const result = await this.post(route, {
  //         query: queryString
  //       });
  //       return result;
  //     },
  //     async getTrfs(variables, description, autoSave = true) {
  //       const result = await this.post(
  //         "/trf/get-trfs",
  //         {
  //           query: trfHelper.getQueryV1(variables),
  //           variables: JSON.stringify(variables),
  //           description
  //         },
  //         { save: autoSave }
  //       );
  //       return result;
  //     },
  //     async createTrf(data) {
  //       const { uuid, fileObj, testRequisitionFormId } = data;
  //       const variables = {};
  //       const formData = new FormData();

  //       variables.filename = fileObj.name;
  //       variables.trfVersion = process.env.TRF_VERSION;
  //       variables.trfData = {};
  //       if (testRequisitionFormId.length)
  //         variables.testRequisitionFormId = testRequisitionFormId;

  //       formData.append("file", fileObj);
  //       formData.append("uuid", uuid);
  //       formData.append("docType", "trf");
  //       formData.append(
  //         "mutation",
  //         testRequisitionFormId.length
  //           ? "mutation ($filename: String, $trfVersion: String, $trfData: String!, $testRequisitionFormId: String!) { createTrf (filename: $filename, trfVersion: $trfVersion, trfData: $trfData) { id filename trfVersion trfData setTestRequisitionFormId(testRequisitionFormId: $testRequisitionFormId) } }"
  //           : "mutation ($filename: String, $trfVersion: String, $trfData: String!) { createTrf (filename: $filename, trfVersion: $trfVersion, trfData: $trfData) { id filename trfVersion trfData } }"
  //       );
  //       formData.append("variables", JSON.stringify(variables));

  //       try {
  //         const result = await this.post(
  //           "/accession/step1/create-trf",
  //           formData,
  //           {
  //             headers: { "Content-Type": "multipart/form-data" }
  //           }
  //         );
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async updateTrfDetails(variables, isDataManagement = false) {
  //       try {
  //         const parameters = ["$trfId: UUID!"];
  //         const mutationParameter = ["trfId"];
  //         const setters = [];

  //         trfHelper.updateTestRequisitionFormId(variables, parameters, setters);

  //         const param = {
  //           parameters,
  //           mutation: "updateTrfDetails",
  //           mutationParameter,
  //           setters
  //         };

  //         const mutation = graphqlHelper.mutationTagBuilder(param);
  //         const result = await this.post(
  //           isDataManagement
  //             ? "/data-management/update-trf"
  //             : "/accession/step1/update-trf-details",
  //           {
  //             mutation,
  //             variables: JSON.stringify(variables),
  //             query:
  //               "query ($trfId: UUID!) { getTrf(id: $trfId) { id filename trfData trfVersion testRequisitionFormId } }"
  //           }
  //         );
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async update(variables, isDataManagement = false) {
  //       let parameters = ["$trfId: UUID!"];
  //       let mutationParameter = ["trfId"];
  //       let setters = [];
  //       let data = JSON.parse(variables);
  //       if (data.testRequisitionFormId) {
  //         parameters.push("$testRequisitionFormId: String!");
  //         setters.push(
  //           "updateTestRequisitionFormId(testRequisitionFormId:$testRequisitionFormId)"
  //         );
  //         let param = {
  //           parameters: parameters,
  //           mutation: "updateTrfDetails",
  //           mutationParameter: mutationParameter,
  //           setters: setters
  //         };
  //         let mutationString = graphqlHelper.mutationTagBuilder(param);
  //         const result = await this.post(
  //           isDataManagement
  //             ? "/data-management/update-trf-by-id"
  //             : "/data-management/update-trf-by-id",
  //           {
  //             mutation: mutationString,
  //             variables
  //           }
  //         );
  //         return result;
  //       }
  //     },
  //     async deleteTrf(data, isDataManagement = false) {
  //       try {
  //         const result = await this.post(
  //           isDataManagement
  //             ? "/data-management/delete-trf"
  //             : "/accession/step1/delete-trf",
  //           {
  //             mutation: "mutation ($id: UUID!) { deleteTrf(id: $id) }",
  //             variables: JSON.stringify(data)
  //           }
  //         );
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     }
  //   }
  // };
}
