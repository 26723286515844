import AppSettings from "store/models/AppSettings";

import StoreActions from "store/actions";

const storeActions = new StoreActions(AppSettings);

export default class AccountActions {
  async all(variables) {
    return await storeActions.query(
      {
        queryName: "appSettings",
        relativeUrl: "/app-settings/get-all",
        ...variables,
      },
      "To fetch app settings"
    );
  }

  async byKey(key) {
    return await storeActions.query(
      {
        queryName: "appSettings",
        relativeUrl: "/app-settings/get-all",
        limitData: AppSettings.defaultData,
        filter: {
          key,
          enum_patternMatch: "EXACT",
        },
      },
      "To fetch app settings"
    );
  }
}
