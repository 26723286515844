import axios from "axios";

export default {
  methods: {
    async validateNpiNumber(value) {
      const result = await axios.get(
        `/npi-registry/search-npi-records?npiNumber=${value}`
      );

      const objectHasOwnProperty = (hayStack, needle) => {
        return Object.prototype.hasOwnProperty.call(hayStack, needle);
      };

      if (result.status === 200) {
        // Return valid result
        if (
          (objectHasOwnProperty(result.data, "resultCount") ||
            objectHasOwnProperty(result.data, "result_count")) &&
          objectHasOwnProperty(result.data, "results")
        ) {
          if (
            (result.data.resultCount === 1 || result.data.result_count === 1) &&
            result.data.results[0].number === value
          )
            return true;
          else return "Invalid NPI number.";
        }

        // Return error result
        if (objectHasOwnProperty(result.data, "Errors"))
          return result.data.Errors[0].description;
      } else return "Unknown error has occurred while validating npi number.";
    },
  },
};
