import * as moment from "moment";
import "moment-timezone";

const DEFAULT_TIMEZONE = "America/Los_Angeles";

moment.tz.add(
  `${DEFAULT_TIMEZONE}|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp1 1VaX 3dA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6`
);

export default {
  format(dateVal, params) {
    let format = params.format || "MM/DD/YYYY";
    format = format == "MM/DD/YYYY hh:MM A" ? "MM/DD/YYYY hh:mm A" : format;
    const zone = params.zone || "UTC";
    if (!dateVal.length) return null;
    const theDate =
      format == "MM/DD/YYYY hh:mm A"
        ? new Date(dateVal + "UTC")
        : new Date(dateVal);
    const mDate = moment(theDate);
    return moment.tz(mDate, zone).format(format);
  },

  getPstUtcDate(
    date = null,
    format = "MM/DD/YYYY HH:mm:ss",
    addTime = null, // {days: [Number, null], hours: [Number, null], minutes: [Number, null], seconds: [Number, null] }
    minusTime = null // {days: [Number, null], hours: [Number, null], minutes: [Number, null], seconds: [Number, null] }
  ) {
    const utcDate = moment.utc(date);

    if (addTime)
      utcDate
        .add(addTime.days || 0, "days")
        .add(addTime.hours || 0, "hours")
        .add(addTime.minutes || 0, "minutes")
        .add(addTime.seconds || 0, "seconds");

    if (minusTime)
      utcDate
        .subtract(minusTime.days || 0, "days")
        .subtract(minusTime.hours || 0, "hours")
        .subtract(minusTime.minutes || 0, "minutes")
        .subtract(minusTime.seconds || 0, "seconds");

    const utc = format ? utcDate.format(format) : utcDate.format();
    const pst = format
      ? moment.tz(utcDate, DEFAULT_TIMEZONE).format(format)
      : moment.tz(utcDate, DEFAULT_TIMEZONE).format();

    return {
      utc,
      pst,
    };
  },

  getTimeDate(
    date,
    format = "MM/DD/YYYY HH:mm:ss",
    isValidRFC2822 = true, // valid ("YYYY-MM-DD" || date time) format
    zone = null,
    addTime = null, // {days: [Number, null], hours: [Number, null], minutes: [Number, null], seconds: [Number, null] }
    minusTime = null // {days: [Number, null], hours: [Number, null], minutes: [Number, null], seconds: [Number, null] }
  ) {
    let utcDate;

    if (isValidRFC2822) {
      utcDate = moment.utc(date);
    } else {
      const [month, day, year] = date.split("/");
      utcDate = moment.utc(
        `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
      );
    }

    if (addTime)
      utcDate
        .add(addTime.days || 0, "days")
        .add(addTime.hours || 0, "hours")
        .add(addTime.minutes || 0, "minutes")
        .add(addTime.seconds || 0, "seconds");

    if (minusTime)
      utcDate
        .subtract(minusTime.days || 0, "days")
        .subtract(minusTime.hours || 0, "hours")
        .subtract(minusTime.minutes || 0, "minutes")
        .subtract(minusTime.seconds || 0, "seconds");

    const utc = format ? utcDate.format(format) : utcDate.format();
    const zoneDateTime = format
      ? moment.tz(utcDate, zone || DEFAULT_TIMEZONE).format(format)
      : moment.tz(utcDate, zone || DEFAULT_TIMEZONE).format();

    return {
      utc,
      zone: zoneDateTime,
    };
  },

  dateFormat(dateVal, format = "MM/DD/YYYY HH:mm") {
    return moment(new Date(dateVal).toISOString()).format(format);
  },
  getPSTDate(
    dateVal = null,
    format = "MM/DD/YYYY HH:mm:ss",
    withPSTLabel = false
  ) {
    let date = moment(dateVal ? new Date(dateVal) : new Date()).utcOffset(-7);
    let formattedDate = format ? `${date.format(format)}` : date;
    return withPSTLabel ? `${formattedDate} (PST)` : formattedDate;
  },
  parseDateFromString(
    dateVal = null,
    format = "MM/DD/YYYY HH:mm:ss",
    offset = null
  ) {
    const theDate = dateVal ? new Date(dateVal) : new Date();

    let date = moment(theDate);

    if (offset) date = date[offset.offsetType](offset.value, offset.type);

    let formattedDate = format ? `${date.format(format)}` : date;
    return formattedDate;
  },
  isDateBefore(date, compareTo) {
    return moment(date).isSameOrBefore(compareTo);
  },
};
