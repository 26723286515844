export default {
  methods: {
    //eg: expectedSize 1000mb = 1gb
    validateFileSize(file, expectedSize) {
      if (file) {
        const size = parseFloat(file.size / 1024 / 1024);
        if (size >= expectedSize) {
          return false;
        }
      }
      return true;
    },
  },
};
