import countryState from "countrycitystatejson";
import axios from "axios";
import { uuid } from "vue-uuid";
import { getStateNameByStateCode, getStateCodeByStateName } from "us-state-codes";

export default {
  methods: {
    async getSuggestedAddresses(searchKey) {
      const url = `/smarty-streets/search-address?searchKey=${searchKey}`
      const result = await axios.get(url);
      if (result.status === 200) {
        return result.data;
      }
      return "Unknown error has occurred while fetching address suggestions.";
    },
    async validateUSZipCode(params) {
      const inputID = uuid.v1();
      let url = `/smarty-streets/search-us-zip-codes?`;
      if (params.city) url = `${url}city=${params.city}`;
      if (params.state) url = `${url}&state=${params.state}`;
      if (params.zipCode) url = `${url}&zipCode=${params.zipCode}`;
      if (inputID) url = `${url}&inputID=${inputID}`;

      const result = await axios.get(url);
      if (result.status === 200) {
        if (result.data.length > 0) {
          const {
            input_index,
            input_id,
            status,
            reason,
            city_states,
            zipcodes,
          } = result.data[0];
          // Input index should always 0 since we only request 1 batch
          if (input_index === 0) {
            if (input_id.toString() === inputID.toString()) {
              if (status && reason) {
                let field = "";
                let newReason = reason;
                if (status === "blank") {
                  field = "city";
                  newReason =
                    "Blank lookup (you must provide a Postal Code and/or City/State combination).";
                }
                if (status === "invalid_state") field = "state";
                if (status === "invalid_city") field = "city";
                if (status === "invalid_zipcode") {
                  field = "zipCode";
                  newReason = "Invalid Postal Code.";
                }
                if (status === "conflict") {
                  field = "city";
                  newReason = "Conflicting Postal Code/City/State information.";
                }
                return { field, reason: newReason };
              }
              if (
                city_states &&
                city_states.length > 0 &&
                zipcodes &&
                zipcodes.length > 0
              )
                return true;
              else
                return "Something went wrong with the returned city/states/zipcodes.";
            } else
              return 'Something went wrong, invalid "input_id" was returned.';
          } else
            return 'Something went wrong, invalid "input_index" was returned.';
        } else return "Something went wrong, multiple results was returned.";
      } else
        return "Unknown error has occurred while validating us postal code.";
    },
    getAllCountries() {
      //will return all country and browse country.state to see the array of states for the country
      const countries = countryState.getCountries().map((country) => {
        return {
          id: country.shortName,
          name: country.name,
          states: formatState(
            countryState.getStatesByShort(country.shortName),
            country.shortName
          ),
        };
      });

      function formatState(states, countryShortName) {
        let stateArr = [];
        for (var i = 0; i < states.length; i++) {
          // format id to camel case
          var stateId = states[i].toLowerCase();
          stateId = stateId.replace(/[()']/g, "");
          stateId = stateId.split(/[- ]/g);

          for (var k = 0; k < stateId.length; k++) {
            if (k !== 0) {
              stateId[k] =
                stateId[k].charAt(0).toUpperCase() + stateId[k].slice(1);
            }
          }

          stateId = stateId.join("");

          stateArr.push({
            id: stateId,
            countryId: countryShortName,
            name: states[i],
          });
        }

        return stateArr;
      }

      return countries.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    },
    getAllPhoneCountryCodes() {
      const countries = countryState.getCountries().map((country) => {
        return {
          id: country.shortName,
          phone: country.phone,
          name: country.name,
        };
      });

      const sortedCountries = countries.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });

      // get united states index and make it first
      const findUsIndex = sortedCountries.findIndex(
        (country) => country.id.toLowerCase() === "us"
      );
      if (findUsIndex > -1) {
        const usObj = sortedCountries[findUsIndex];
        sortedCountries.splice(findUsIndex, 1);
        sortedCountries.unshift(usObj);
      }

      return sortedCountries;
    },
    validatePhoneViaAreaCode(phone, areaCode) {
      const areaCodeRegex = new RegExp(`^${areaCode}`);
      return areaCodeRegex.test(phone) || "Invalid phone area code";
    },
    getStateCodeByStateName,
    getStateNameByStateCode,
  },
};
