import AuditLog from "store/models/AuditLog";

export default {
  name: "AuditTrail",
  props: {
    module: String,
    recordId: {
      type: String,
      default: null,
    },
    visibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limitData: [
        "created",
        "message",
        "action",
        "totalCount",
      ],
      paginationOptions: {
        itemsPerPage: 5,
      },
      perPageOptions: [5, 10, 15, 20],
      searchFilter: {
        columns: 3,
        model: AuditLog,
        denseBtn: true,
        fields: [
          {
            label: "Created",
            name: "created",
            type: "date",
            cols: 3,
          },
          {
            label: "Search",
            name: "message",
            type: "text",
            placeholder: "Type-in like code, description, etc...",
            cols: 6,
          },
        ]
      },
      columns: [
        {
          name: "created",
          align: "left",
          text: "Date",
          field: "created",
          width: "30%",
          class: "grey lighten-4 pl-2 pt-1 pr-2 pb-1",
          noMaxlength: true,
        },
        {
          name: "message",
          align: "left",
          text: "Activities",
          field: "message",
          noMaxlength: true,
          isHtml: true,
        },
      ],
      isDataLoading: false,
      auditTrails: [],
      totalCount: 0,
    };
  },
  computed: {
    filteredAuditTrails() {
      return this.auditTrails.map(({ created, message }) => ({
        created: `${this.$options.filters.getTimeDate(created, "DD-MMM-YYYY hh:mm A").zone} PST`,
        message,
      }));
    },
  },
  methods: {
    loading(bool) {
      this.isDataLoading = bool;
    },
    handleClose() {
      this.$emit("onClose", false);
    },
    async fetch() {
      try {
        this.isDataLoading = true;
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        throw Error(e);
      } finally {
        this.isDataLoading = false;
      }
    },
    async searchResults(results) {
      if (results.length) {
        this.totalCount = results[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.auditTrails = results;
    },
  },
  mounted() {
    if (this.recordId) this.fetch();
  },
};
