import QueryModel from "store/models/QueryModel";
import CptCodeConversion from "store/models/CptCodeConversion";
import ConversionRule from "store/models/ConversionRule";

import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class VbsConversionTransaction extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "vbs_conversion_transaction";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      groupId: this.attr(null),
      converter: this.attr(null),
      cptCodeConversionIds: this.attr([]),
      cptCodeConversion: this.hasManyBy(
        CptCodeConversion,
        "cptCodeConversionIds"
      ),
    };
  }

  static defaultData = [
    "groupId",
    graphqlHelper.subQueryTagBuilder("converter", ConversionRule.defaultData),
    graphqlHelper.subQueryTagBuilder("cptCodeConversion", CptCodeConversion.defaultData),
  ];
}
