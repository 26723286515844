var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","persistent":""},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('v-card',[_c('v-card-title',[_c('span',{attrs:{"color":"grayheader"}},[_vm._v("Audit Trail")])]),_c('div',{staticClass:"mt-5 ml-7 mr-7"},[_c('v-searchfilter',{ref:"searchFilter",attrs:{"auditTrail":{
          module: _vm.module,
          recordId: _vm.recordId,
        },"columns":_vm.searchFilter.columns,"isLoading":_vm.isDataLoading,"limit":10,"limitData":_vm.limitData,"modelInstance":_vm.searchFilter.model,"options":_vm.searchFilter.fields,"relativeUrl":"/audit-logs/get-all","queryName":"vbsAuditLogs","allowFutureDates":"","hideBottomActions":"","hideDeleted":"","isAuditTrail":""},on:{"loading":_vm.loading,"searchResults":_vm.searchResults}}),_c('div',[_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"id":"auditTrailTable","accessControl":{
            authUserHasAddAccessControl: false,
            authUserHasViewAccessControl: false,
            authUserHasEditAccessControl: false,
            authUserHasDeleteAccessControl: false,
          },"columns":_vm.columns,"data":_vm.filteredAuditTrails,"isLoading":_vm.isDataLoading,"searchFilter":_vm.$refs.searchFilter,"tableHeight":320,"totalCount":_vm.totalCount,"noDataLabel":"No audit logs found.","rowKey":"id","bordered":"","flatx":"","hideDelete":"","hideHeader":"","hideInactive":"","hideView":"","topBottomBorder":"","useSkeletonLoader":"","whiteRows":""}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0 pa-5",on:{"click":_vm.handleClose}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }