import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import axios from "axios";
import User from "store/models/User";

Vue.use(VueRouter);

export default function () {
  const router = new VueRouter({
    mode: "history",
    routes,
  });

  const objectHasOwnProperty = (hayStack, needle) => {
    return Object.prototype.hasOwnProperty.call(hayStack, needle);
  };

  router.beforeEach(async (to, from, next) => {
    try {
      if (to.name !== null) {
        if (
          to.name !== "login" &&
          to.name !== "change-password" &&
          to.name !== "reset-password"
        ) {
          const authentication = await axios.get("/auth/openid/user-info");
          if (
            objectHasOwnProperty(authentication.data, "userInfo") &&
            authentication.data.userInfo
          ) {
            let user = new User();
            let userInfo = authentication.data.userInfo;
            let storeUser = user.$all();

            // update User entity for new login
            if (!storeUser.length) {
              Object.keys(userInfo).forEach((property) => {
                user[property] = userInfo[property];
              });
              user.$save();
              storeUser = user.$all();
            }

            // check if user has privelege to access the page
            const hasPrivelege = storeUser[0].roles.filter(
              (role) => role.name.toUpperCase() === "BILLING"
            );

            if (!hasPrivelege.length) {
              if (to.path !== "/access-not-granted" && to.path !== "/login") {
                next("/access-not-granted");
              }
            }

            // check user-info response into saved user details
            if (storeUser[0].uuid && storeUser[0].uuid === userInfo.uuid) {
              if (to.name === "login") next("/dashboard");
            }
          } else {
            if (
              to.name != "login" &&
              to.name != "change-password" &&
              to.name != "reset-password"
            )
              next("/login");
          }
        }
      } else {
        next("/page-not-found");
      }

      next();
    } catch (e) {
      next();
    }
  });

  return router;
}
