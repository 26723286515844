import QueryModel from "store/models/QueryModel";

import AccountSignature from "store/models/AccountSignature";

import GraphqlHelper from "./helpers/graphql-helper";

// import Role from "./Role";
// import Task from "./RoleTask";

const graphqlHelper = new GraphqlHelper();
export default class Account extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "account";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.string(),
      email: this.string(),
      firstName: this.string(""),
      lastName: this.string(""),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      totalCount: this.attr(null),
      username: this.string(""),
      roleCount: this.number(0),
      taskCount: this.number(0),
      accountSignatureId: this.attr(null),
      accountSignature: this.belongsTo(AccountSignature, "accountSignatureId"),
      accountInitial: this.attr(""),
      postNominalTitle: this.attr(""),
      jobRole: this.attr(""),
      department: this.attr(""),
      profileRole: this.attr(""),
      vbsStatus: this.attr(""),
    };
  }

  static defaultData = [
    "name",
    "firstName",
    "lastName",
    "email",
    "pin",
    "roleCount",
    "username",
    "accountType",
    "taskCount",
    "createdAt",
    "status",
    graphqlHelper.subQueryTagBuilder("roles", ["name", "description"]),
    graphqlHelper.subQueryTagBuilder("tasks", ["id", "label", "description"]),
    graphqlHelper.subQueryTagBuilder(
      "accountSignature",
      AccountSignature.defaultData
    ),
    "accountInitial",
    "postNominalTitle",
    "jobRole",
    "department",
    "profileRole",
    "vbsStatus",
  ];
}
