import GraphqlHelper from "./helpers/graphql-helper";
import QueryModel from "store/models/QueryModel";
const graphqlHelper = new GraphqlHelper();
export default class Phone extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "phone";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      phone: this.attr(""),
      practiceId: this.attr(null),
      patientId: this.attr(null),
      insuredId: this.attr(null),
      areaCode: this.attr(""),
      countryCode: this.attr(""),
      phoneType: this.attr(null),
    };
  }

  static defaultData = [
    "id",
    "phone",
    "areaCode",
    graphqlHelper.subQueryTagBuilder("phoneType", ["id", "label"]),
    "created",
    "isDeleted",
    "totalCount",
  ];
}
