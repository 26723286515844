import Actions from "modules/actions";

const US_COUNTRY_NAME = "United States";
const actions = new Actions();

export default {
  name: "AddressSection",
  props: {
    address: Object,
    customFields: Array,
    noType: Boolean,
    isOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allAddressTypes: [],
      searchCountry: "",
      searchAddress: "",
      filteredCountries: [],
      filteredSuggestions: [],
      isFetchingSuggestions: false,
      isSearchingAddress: false,
      isLoading: false,
      countries: [],
      selectedAddress: null,
    };
  },
  computed: {
    isUS() {
      return this.address?.country === US_COUNTRY_NAME;
    },
    practiceAddressTypeOptions() {
      const options = this.allAddressTypes.map(({
        id,
        label,
      }) => ({
        label: label,
        value: id,
      }));
      return options;
    },
    countryOptions() {
      const options = [];
      if (this.filteredCountries && this.filteredCountries.length) {
        this.filteredCountries.map(({
          name,
        }) => options.push({
          label: name,
          value: name,
        }));
      }
      return options;
    },
    addressOptions() {
      const options = [];
      if (this.filteredSuggestions && this.filteredSuggestions.length) {
        this.filteredSuggestions.map(({
          city,
          secondary,
          state,
          street_line,
          zipcode,
        }) => options.push({
          label: `${street_line} ${secondary
            ? ` ${secondary} `
            : ''}${city}, ${this.getStateByCode(state)}, ${zipcode}`,
          value: ({
            city,
            stateProvince: this.getStateByCode(state),
            line1: street_line,
            line2: secondary? secondary: this.address.line2,
            postalCode: zipcode,
          }),
        }));
      }
      return options;
    },
    practiceStateOptions() {
      const { country } = this.address;
      const options = [];
      if (country && this.countries.length > 0) {
        const countryFound = this.countries.find(({ name }) => name === country);
        if (countryFound && countryFound?.states?.length) {
          const { states } = countryFound;
          states.forEach(({ id, name }) => options.push({
            id: id,
            label: name,
          }));
        }
      }
      return options;
    },
  },
  watch: {
    searchCountry(newVal) {
      if (newVal && newVal !== this.address.country) {
        this.filterCountries(newVal, true);
      }
    },
    searchAddress(newVal) {
      if (newVal && newVal !== this.selectedAddress?.line1) {
        this.getAddressOptions(newVal);
      }
    },
    selectedAddress(newVal, prevVal) {
      if (prevVal && !newVal) this.clearAddress()
      if (this.isUS) this.populateAddress();
    },
    async "address.country"(newVal, prevVal) {
      const toUS = prevVal !== US_COUNTRY_NAME && newVal === US_COUNTRY_NAME;
      const fromUS = prevVal === US_COUNTRY_NAME && newVal !== US_COUNTRY_NAME;
      if (fromUS || toUS) this.clearAddress();
    },
    async "address.line1"(newVal, prevVal) {
      if (!newVal || newVal === prevVal) return;
      if (!this.selectedAddress || this.selectedAddress.line1 !== newVal) {
        this.searchAddress = null;
        this.getAddressOptions(newVal, true);
      }
    },
  },
  methods: {
    getCustomField(value, type) {
      if (!this.customFields || !this.customFields.length) return null;
      if (type && (value?.toUpperCase() !== type)) return null;
      const field = this.customFields.find(i => i.type === value);
      return field || null;
    },
    getOptionalFieldLabel(label, defaultLabel) {
      if (!this.isUS) return `${label || defaultLabel} (optional)`;
      return label || defaultLabel;
    },
    getStateByCode(code) {
      return this.getStateNameByStateCode(code) || code;
    },
    async filterCountries(val, clearState = false) {
      setTimeout(() => {
        const searchKey = val.toLowerCase();
        if (searchKey.length) {
          const searchedCountries = this.countries.filter(({ name }) =>
            name.toLowerCase().indexOf(searchKey) > -1);
          this.filteredCountries = searchedCountries.map(({ id }) =>
            this.countries.find(country => country.id === id));
        } else {
          this.filteredCountries = this.countries;
        }
        if (clearState && !this.isUS) this.updateAddress({ stateProvince: "" });
      }, 500);
    },
    async getAddressOptions(val, onMount = false) {
      if (val.length < 2 || !this.isUS) return;
      if (!this.isFetchingSuggestions) this.isFetchingSuggestions = true;
      const { suggestions } = await this.getSuggestedAddresses(val);
      this.filteredSuggestions = suggestions;
      if (onMount && suggestions.length > 0) {
          const { city: addressCity, line1, line2, postalCode } = this.address;
          const addressFound = suggestions.find(({
            street_line,
            city,
            zipcode,
          }) => (line1 === street_line)
            && (addressCity === city)
            && (postalCode === zipcode));
        if (addressFound) {
          this.selectedAddress = {
            line1: addressFound.street_line,
            line2: addressFound.secondary ? addressFound.secondary : line2,
            city: addressFound.city,
            postalCode: addressFound.zipcode,
            stateProvince: this.getStateByCode(addressFound.state),
          };
        }
      }
      this.isFetchingSuggestions = false;
    },
    populateAddress() {
      if (this.selectedAddress) {
        this.updateAddress(this.selectedAddress);
      }
    },
    blurAddress() {
      this.isSearchingAddress = false;
      this.$emit("blurAddress");
    },
    clearAddress(clearCountry = false) {
      const address = {
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        stateProvince: "",
      };
      if (clearCountry) {
        address.country = "";
        this.searchCountry = "";
      }
      this.searchAddress = "";
      this.selectedAddress = null;
      this.filteredSuggestions = [];
      this.updateAddress(address);
    },
    openDialog(isVisible) {
      this.$emit("openDialog", isVisible);
    },
    updateAddress(address) {
      this.$emit("update:address", {
        ...this.address,
        ...address
      });
    },
    async prepareData() {
      const { country, line1 } = this.address;
      this.allAddressTypes = await actions.getAddressTypes();
      this.countries = await actions.getAllCountryWithStates();
      this.filteredCountries = this.filterCountries(country);
      if (line1 && !this.selectedAddress) {
        this.getAddressOptions(line1, true);
      }
      if (this.isOptional) return;
      if (!country ) {
        this.updateAddress({ country: US_COUNTRY_NAME });
      } else this.searchCountry = country;
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.isLoading = true;
      await this.prepareData();
      this.isLoading = false;
    });
  },
};
