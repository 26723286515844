import QueryModel from "store/models/QueryModel";

export default class BillingErrorCode extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "billing_error_code";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      errorField: this.attr(""),
      errorGroup: this.attr(null),
      errorCode: this.attr(""),
      hierarchy: this.attr(0),
      errorDescription: this.attr(""),
    };
  }

  static defaultData = [
    "errorField",
    "errorGroup",
    "errorCode", 
    "hierarchy",
    "errorDescription",
  ];
}
