import QueryModel from "store/models/QueryModel";
import CPTCode from "store/models/CPTCode";

import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class Test extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "test";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      label: this.attr(""),
      testCode: this.string(""),
      cptCodeId: this.attr(null),
      cptCode: this.belongsTo(CPTCode, "cptCodeId"),
    };
  }

  static defaultData = [
    "label",
    "testCode",
    graphqlHelper.subQueryTagBuilder("cptCode", CPTCode.defaultData),
  ];
}
