import { Model } from "@vuex-orm/core";
// import GraphqlHelper from "./helpers/graphql-helper";
// const graphqlHelper = new GraphqlHelper();
export default class Onset extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "onset";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      onsetType: this.attr(""),
      date: this.string(""),
    };
  }
  // static apiConfig = {
  //   actions: {
  //     async create(variables) {
  //       let parameters = [];
  //       let mutationParameter = [];
  //       let setters = [];
  //       let data = JSON.parse(variables);
  //       if (data.onsetType) {
  //         mutationParameter.push("onsetType");
  //         parameters.push("$onsetType: OnsetType");
  //         setters.push("onsetType");
  //       }
  //       if (data.date) {
  //         mutationParameter.push("date");
  //         parameters.push("$date: String!");
  //         setters.push("date");
  //       }
  //       let param = {
  //         parameters: parameters,
  //         mutation: "createOnset",
  //         mutationParameter: mutationParameter,
  //         setters: setters
  //       };
  //       let mutationString = graphqlHelper.mutationTagBuilder(param);
  //       const result = await this.post("/accession/step2/create-onset", {
  //         mutation: mutationString,
  //         variables
  //       });
  //       return [result];
  //     },
  //     async update(variables) {
  //       let parameters = [];
  //       let mutationParameter = [];
  //       let setters = [];
  //       let data = JSON.parse(variables);
  //       if (data.onsetId) {
  //         mutationParameter.push("onsetId");
  //         parameters.push("$onsetId: UUID!");
  //       }
  //       if (data.onsetType) {
  //         parameters.push("$onsetType: OnsetType!");
  //         setters.push("updateOnsetType(onsetType:$onsetType)");
  //       }
  //       if (data.date) {
  //         parameters.push("$date: String!");
  //         setters.push("updateDate(date:$date)");
  //       }
  //       let param = {
  //         parameters: parameters,
  //         mutation: "updateOnsetDetails",
  //         mutationParameter: mutationParameter,
  //         setters: setters
  //       };
  //       let mutationString = graphqlHelper.mutationTagBuilder(param);
  //       const result = await this.post("/accession/step2/update-onset", {
  //         mutation: mutationString,
  //         variables
  //       });
  //       return [result];
  //     },
  //     async deleteOnset(data) {
  //       try {
  //         const result = await this.post("/accession/step2/delete-onset", {
  //           mutation: "mutation ($id: UUID!) { deleteOnset(id: $id) }",
  //           variables: JSON.stringify(data)
  //         });
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     }
  //   }
  // };
}
