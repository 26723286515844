import countryState from "countrycitystatejson";
import Order from "store/models/Order";
import OrderEntry from "store/models/OrderEntry";
import AccessionEntry from "store/models/AccessionEntry";
import User from "store/models/User";
import PhoneTypes from "store/models/PhoneTypes";
import AddressType from "store/models/AddressType";

import OrderActions from "store/actions/order";
import AccountActions from "store/actions/account";
import TypesActions from "store/actions/types";
import PhoneActions from "store/actions/phone";
import EmailActions from "store/actions/email";
import AddressActions from "store/actions/address";
import AppSettingsActions from "store/actions/app-settings";
import EnumModelActions from "store/actions/enum";
import NpiActions from "store/actions/npi";

const typesActions = new TypesActions();
const accountActions = new AccountActions();
const orderActions = new OrderActions();
const phoneActions = new PhoneActions();
const addressActions = new AddressActions();
const emailActions = new EmailActions();
const appSettingsActions = new AppSettingsActions();
const enumActions = new EnumModelActions();
const npiActions = new NpiActions();
export default class Actions {
  async runPricingRules(data) {
    return await orderActions.runPricingRules(data);
  }
  async searchNpi(npiNumber) {
    return await npiActions.searchNpi(npiNumber);
  }
  async createNpi(params) {
    return await npiActions.create(params);
  }
  async getAllAppSettings(variables) {
    return await appSettingsActions.all(variables);
  }
  async getAppSettingsByKey(key) {
    return await appSettingsActions.byKey(key);
  }
  async createAddress(variables) {
    return await addressActions.create(variables);
  }
  async updateAddressDetails(params) {
    return await addressActions.update(params);
  }
  async updateAddress(params) {
    return await addressActions.update(params);
  }
  async archiveAddress(params) {
    return await addressActions.archive(params);
  }
  async deArchiveAddress(params) {
    return await addressActions.deArchive(params);
  }
  async createPhone(variables) {
    return await phoneActions.create(variables);
  }
  async updatePhone(variables) {
    return await phoneActions.update(variables);
  }
  async createEmail(variables) {
    return await emailActions.create(variables);
  }
  async getAllAddressTypes(filter = null) {
    return await typesActions.getAddressTypes(filter);
  }
  getAddressTypes() {
    let types = AddressType.all();
    try {
      types.forEach((type, index) => {
        if (
          type.label.toLowerCase() === "other" ||
          type.label.toLowerCase() === "others"
        )
          types.push(types.splice(index, 1)[0]);
      });
      return types;
    } catch (e) {
      console.log(e);
    }
  }
  getAllCountryWithStates() {
    //will return all country and browse country.state to see the array of states for the country
    const countries = countryState.getCountries().map((country) => {
      return {
        id: country.shortName,
        name: country.name,
        states: this.formatState(
          countryState.getStatesByShort(country.shortName),
          country.shortName
        ),
      };
    });
    return countries.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
  }
  formatState(states, countryShortName) {
    let stateArr = [];
    for (var i = 0; i < states.length; i++) {
      // format id to camel case
      var stateId = states[i].toLowerCase();
      stateId = stateId.replace(/[()']/g, "");
      stateId = stateId.split(/[- ]/g);

      for (var k = 0; k < stateId.length; k++) {
        if (k !== 0) {
          stateId[k] = stateId[k].charAt(0).toUpperCase() + stateId[k].slice(1);
        }
      }

      stateId = stateId.join("");

      stateArr.push({
        id: stateId,
        countryId: countryShortName,
        name: states[i],
      });
    }

    return stateArr;
  }

  async getPhoneTypes() {
    return await typesActions.getPhoneTypes();
  }
  getAllPhoneTypes() {
    let types = PhoneTypes.all();
    try {
      types.forEach((type, index) => {
        if (
          type.label.toLowerCase() === "other" ||
          type.label.toLowerCase() === "others"
        )
          types.push(types.splice(index, 1)[0]);
      });
      return types;
    } catch (e) {
      console.log(e);
    }
  }
  getUser() {
    return User.query().find(1);
  }

  getOrderEntryById(variables) {
    return orderActions.get(variables);
  }

  getAccountByEmail(email, limitData = null) {
    return accountActions.get(email, limitData);
  }

  updateAccountDetails(variables) {
    return accountActions.update(variables);
  }

  getAccessionEntryById(variables) {
    return orderActions.get(variables);
  }

  updateOrderDetails(variables) {
    return orderActions.update(variables);
  }

  getProcessingOrderEntry() {
    return OrderEntry.query().last();
  }

  getProcessingAccession() {
    return AccessionEntry.query().last();
  }

  async getEnumValues(name) {
    return await enumActions.getEnumValues(name);
  }

  async getEmailTypes() {
    return await typesActions.getEmailTypes();
  }

  copyOrderModelToOrderEntryModel(variables) {
    const { id } = variables;
    const order = Order.query().whereId(id).withAllRecursive().first();

    OrderEntry.deleteAll();
    const orderEntry = new OrderEntry();
    orderEntry.id = order.id;
    orderEntry.internalId = order.internalId;
    orderEntry.requisitionId = order?.trf?.testRequisitionFormId || "";
    orderEntry.abn = order.abn || "";
    orderEntry.dateFirstTransmitted = order.dateFirstTransmitted || "";
    orderEntry.priorAuthorization = order.priorAuthorization ? "yes" : "no";
    orderEntry.priorAuthorizationCode = order.priorAuthorizationCode || "";
    orderEntry.billType = order.billType || "";
    orderEntry.dateSampleTaken = order.dateSampleTaken || "";
    orderEntry.dateOfService = order.dateOfService || "";

    // set physician
    if (order.physician !== null) {
      orderEntry.physician.orderingPhysician.id = order.physician.id;
      orderEntry.physician.orderingPhysician.firstName =
        order.physician.firstName;
      orderEntry.physician.orderingPhysician.lastName =
        order.physician.lastName;
      orderEntry.physician.orderingPhysician.taxonomy =
        order.physician.taxonomy;

      // set physician npi
      if (order.physician.npi !== null) {
        orderEntry.physician.orderingPhysician.npi.id = order.physician.npi.id;
        orderEntry.physician.orderingPhysician.npi.npiNumber =
          order.physician.npi.npiNumber;
      }
    }

    // set referring physician
    if (order.referingPhysician !== null) {
      orderEntry.physician.referringPhysician.id = order.referingPhysician.id;
      orderEntry.physician.referringPhysician.firstName =
        order.referingPhysician.firstName;
      orderEntry.physician.referringPhysician.lastName =
        order.referingPhysician.lastName;
      orderEntry.physician.referringPhysician.taxonomy =
        order.referingPhysician.taxonomy;

      // set referring physician npi
      if (order.referingPhysician.npi !== null) {
        orderEntry.physician.referringPhysician.npi.id =
          order.referingPhysician.npi.id;
        orderEntry.physician.referringPhysician.npi.npiNumber =
          order.referingPhysician.npi.npiNumber;
      }
    }

    // set patient info
    if (order.patient !== null) {
      orderEntry.patient.info.id = order.patient.id;
      orderEntry.patient.info.lastName = order.patient.lastName;
      orderEntry.patient.info.firstName = order.patient.firstName;
      orderEntry.patient.info.dateOfBirth = order.patient.dateOfBirth;
      orderEntry.patient.info.gender = order.patient.gender;
      orderEntry.patient.info.patientSsn = order.patient.patientSsn;

      if (order.patient.addresses !== null && order.patient.addresses[0]) {
        orderEntry.patient.addresses.id = order.patient.addresses[0].id;
        orderEntry.patient.addresses.city = order.patient.addresses[0].city;
        orderEntry.patient.addresses.line1 = order.patient.addresses[0].line1;
        orderEntry.patient.addresses.country =
          order.patient.addresses[0].country;
      }

      if (order.patient.defaultAddress !== null) {
        orderEntry.patient.defaultAddress.id = order.patient.defaultAddress.id;
        orderEntry.patient.defaultAddress.city =
          order.patient.defaultAddress.city;
        orderEntry.patient.defaultAddress.line1 =
          order.patient.defaultAddress.line1;
        orderEntry.patient.defaultAddress.line2 =
          order.patient.defaultAddress.line2;
        orderEntry.patient.defaultAddress.country =
          order.patient.defaultAddress.country;
        orderEntry.patient.defaultAddress.postalCode =
          order.patient.defaultAddress.postalCode;
        orderEntry.patient.defaultAddress.stateProvince =
          order.patient.defaultAddress.stateProvince;
      }

      if (order.patient.defaultEmail !== null) {
        orderEntry.patient.defaultEmail.id = order.patient.defaultEmail.id;
        orderEntry.patient.defaultEmail.email =
          order.patient.defaultEmail.email;
      }

      // set default phone
      if (order.patient.defaultPhone) {
        const { id, phone, areaCode } = order.patient.defaultPhone;

        orderEntry.patient.defaultPhone = {
          id,
          phone,
          areaCode,
        };
      }
    }

    // set patient signature
    if (order.patientSignature !== null) {
      orderEntry.patientSignature.id = order.patientSignature.id;
      orderEntry.patientSignature.signatureType =
        order.patientSignature.signatureType;
    }

    // set used insurances
    if (order.usedInsurance && order.usedInsurance !== null) {
      const { usedInsurance } = order;
      orderEntry.insurances =
        usedInsurance !== null
          ? usedInsurance.map((item) => {
              const { insurance, isDefault } = item;
              const {
                insured,
                insuranceRelationshipType,
                msp,
                insuranceCompany,
                notes,
              } = insurance;

              return {
                isDefault,
                id: insurance.id,
                authorizationNumber: insurance.authorizationNumber || "",
                groupId: insurance.groupId || "",
                groupName: insurance.groupName || "",
                insuranceGroupId: insurance.insuranceGroupId || "",
                insuranceMemberId: insurance.insuranceMemberId || "",
                planId: insurance.planId || "",
                planName: insurance.insurancePlanName || "",
                payorPriority: insurance.payorPriority,
                subscriberId: insurance.subscriberId || "",
                note:
                  notes !== null
                    ? notes.shift()
                    : {
                        id: null,
                        note: "",
                      },
                insuranceRelationshipType:
                  insuranceRelationshipType !== null
                    ? {
                        id: insuranceRelationshipType.id,
                        label: insuranceRelationshipType.label,
                      }
                    : {
                        id: null,
                        label: "",
                      },
                insured:
                  insured !== null
                    ? {
                        id: insured.id,
                        firstName: insured.firstName,
                        lastName: insured.lastName,
                        middleName: insured.middleName,
                        defaultAddress:
                          insured.defaultAddress !== null
                            ? {
                                id: insured.defaultAddress.id,
                                line1: insured.defaultAddress.line1,
                                city: insured.defaultAddress.city,
                                stateProvince:
                                  insured.defaultAddress.stateProvince,
                                postalCode: insured.defaultAddress.postalCode,
                                country: insured.defaultAddress.country,
                              }
                            : {
                                id: null,
                                line1: "",
                                city: "",
                                stateProvince: "",
                                postalCode: "",
                                country: "",
                              },
                      }
                    : {
                        id: null,
                        firstName: "",
                        lastName: "",
                        middleName: "",
                        defaultAddress: {
                          id: null,
                          line1: "",
                          city: "",
                          stateProvince: "",
                          postalCode: "",
                          country: "",
                        },
                      },
                msp:
                  msp !== null
                    ? {
                        id: msp.id,
                        mspCode: msp.mspCode,
                        mspCodeDescription: msp.mspCodeDescription,
                      }
                    : {
                        id: null,
                        mspCode: "",
                        mspCodeDescription: "",
                      },
                payor:
                  insuranceCompany !== null
                    ? {
                        id: insuranceCompany.id,
                        name: insuranceCompany.name,
                        payorId: insuranceCompany.insuranceCompanyId,
                        payorGroup: insuranceCompany.payorGroup,
                        payorSubGroup: insuranceCompany.payorSubGroup,
                      }
                    : {
                        id: null,
                        name: "",
                        payorId: "",
                        payorGroup: "",
                        payorSubGroup: "",
                      },
              };
            })
          : [];
    }

    // set test
    if (order.test !== null) {
      orderEntry.test.id = order.test.id;
      orderEntry.test.testCode = order.test.testCode;

      // set cpt code
      if (order.test.cptCode !== null) {
        orderEntry.test.cptCode.id = order.test.cptCode.id;
        orderEntry.test.cptCode.code = order.test.cptCode.code;
        orderEntry.test.cptCode.description = order.test.cptCode.description;
        orderEntry.test.cptCode.price = order.test.cptCode.price;
      }
    }

    // set test ordered
    if (order.testOrdered !== null) {
      const { placeOfService } = order.testOrdered;

      orderEntry.testOrdered.id = order.testOrdered.id;
      orderEntry.testOrdered.modifierMb = order.testOrdered.modifierMb;
      orderEntry.testOrdered.placeOfService = placeOfService
        ? {
            id: placeOfService.id,
            posCode: placeOfService.posCode,
          }
        : {
            id: null,
            posCode: "",
          };
    }

    // set notes
    if (order.notes !== null) {
      orderEntry.notes = order.notes.map((note) => {
        return {
          id: note.id,
          note: note.note,
          createdBy: note.createdBy,
          created: note.created,
        };
      });
    }

    // set billing diagnostic codes
    if (
      order.billingDiagnosticCode !== null &&
      order.billingDiagnosticCode.length > 0
    ) {
      orderEntry.billingDiagnosticCode = order.billingDiagnosticCode.map(
        (bdc) => {
          return {
            id: bdc.id,
            diagnosticCode: bdc.diagnosticCode,
            addedBy: bdc.addedBy,
            addedDate: bdc.addedDate,
            notes: bdc.notes,
            active: bdc.active,
          };
        }
      );
    }

    // set diagnostic codes
    if (order.diagnosticCodes !== null && order.diagnosticCodes.length) {
      orderEntry.diagnosticCodes = order.diagnosticCodes.map((dc) => {
        return {
          id: dc.id,
          diagnosticCode: dc,
          addedBy: dc.createdBy,
          addedDate: dc.created,
          notes: dc.notes ? dc.notes.shift() : "",
          active: false,
        };
      });
    }

    if (order.sample !== null) {
      orderEntry.sample.id = order.sample.id;
      orderEntry.sample.sampleId = order.sample.sampleId;
      orderEntry.sample.receiveDate = order.sample.receiveDate;

      orderEntry.sample.notes =
        order.sample.notes.length > 0
          ? order.sample.notes.map(({ id, note, title }) => ({
              id,
              note,
              title,
            }))
          : [];

      // set sample storage laboratory
      if (order.sample.sampleStorageLaboratory) {
        orderEntry.sample.sampleStorageLaboratory.id =
          order.sample.sampleStorageLaboratory.id;
        orderEntry.sample.sampleStorageLaboratory.label =
          order.sample.sampleStorageLaboratory.label;
      }
    }

    if (order.payorAccount !== null) {
      orderEntry.payorAccount.payorCode = order.payorAccount.payorCode;
      orderEntry.payorAccount.payorName = order.payorAccount.payorName;
      orderEntry.payorAccount.clientBillType =
        order.payorAccount.clientBillType;
    }

    // set order entry saved
    orderEntry.orderEntrySaved = order.orderEntrySaved || false;

    orderEntry.$save();
  }

  copyOrderModelToAccessionEntryModel(variables) {
    const { id } = variables;
    try {
      const order = Order.query().whereId(id).withAllRecursive().first();

      AccessionEntry.deleteAll();
      const orderEntry = new AccessionEntry();
      orderEntry.id = order.id;

      orderEntry.abn = order.abn || "";
      orderEntry.dateFirstTransmitted = order.dateFirstTransmitted || "";
      orderEntry.billingNetsuiteStatus = order.billingNetsuiteStatus || "";
      orderEntry.netsuiteInvoice = order.netsuiteInvoice || "";
      orderEntry.billedAmount = order.billedAmount || "";
      orderEntry.balanceDue = order.balanceDue || "";
      orderEntry.priceDate = order.priceDate || "";
      orderEntry.billingAccessionStatus = order.billingAccessionStatus || "";
      orderEntry.priorAuthorization = order.priorAuthorization ? "yes" : "no";
      orderEntry.internalId = order.internalId || "";
      orderEntry.requisitionId = order?.trf?.testRequisitionFormId || "";
      orderEntry.billingStatementStatus = order.billingStatementStatus || "";
      orderEntry.billType = order.billType || "";
      orderEntry.dateSampleTaken = order.dateSampleTaken || "";
      orderEntry.dateOfService = order.dateOfService || "";

      if (order.billingClaimsSubmission.length > 0) {
        orderEntry.billingClaimsSubmission = [
          ...order.billingClaimsSubmission.map((data) => data),
        ];
      }

      if (order.billingTransactionDetails.length > 0) {
        orderEntry.billingTransactionDetails = [
          ...order.billingTransactionDetails.map((data) => {
            if (!data.computedValues) data.computedValues = {};
            return data;
          }),
        ];
      }

      // set physician
      if (order.physician !== null) {
        orderEntry.physician.orderingPhysician.id = order.physician.id;
        orderEntry.physician.orderingPhysician.firstName =
          order.physician.firstName;
        orderEntry.physician.orderingPhysician.lastName =
          order.physician.lastName;
        orderEntry.physician.orderingPhysician.taxonomy =
          order.physician.taxonomy;

        // set physician npi
        if (order.physician.npi !== null) {
          orderEntry.physician.orderingPhysician.npi.id =
            order.physician.npi.id;
          orderEntry.physician.orderingPhysician.npi.npiNumber =
            order.physician.npi.npiNumber;
        }
      }

      // set referring physician
      if (order.referingPhysician !== null) {
        orderEntry.physician.referringPhysician.id = order.referingPhysician.id;
        orderEntry.physician.referringPhysician.firstName =
          order.referingPhysician.firstName;
        orderEntry.physician.referringPhysician.lastName =
          order.referingPhysician.lastName;
        orderEntry.physician.referringPhysician.taxonomy =
          order.referingPhysician.taxonomy;

        // set referring physician npi
        if (order.referingPhysician.npi !== null) {
          orderEntry.physician.referringPhysician.npi.id =
            order.referingPhysician.npi.id;
          orderEntry.physician.referringPhysician.npi.npiNumber =
            order.referingPhysician.npi.npiNumber;
        }
      }

      if (order.patient !== null) {
        orderEntry.patient.info.id = order.patient.id;
        orderEntry.patient.info.lastName = order.patient.lastName;
        orderEntry.patient.info.firstName = order.patient.firstName;
        orderEntry.patient.info.dateOfBirth = order.patient.dateOfBirth;
        orderEntry.patient.info.gender = order.patient.gender;
        orderEntry.patient.info.patientSsn = order.patient.patientSsn;

        if (order.patient.addresses !== null && order.patient.addresses[0]) {
          orderEntry.patient.addresses.id = order.patient.addresses[0].id;
          orderEntry.patient.addresses.city = order.patient.addresses[0].city;
          orderEntry.patient.addresses.line1 = order.patient.addresses[0].line1;
          orderEntry.patient.addresses.line2 = order.patient.addresses[0].line2;
          orderEntry.patient.addresses.country =
            order.patient.addresses[0].country;
        }

        if (order.patient.defaultAddress !== null) {
          orderEntry.patient.defaultAddress.id =
            order.patient.defaultAddress.id;
          orderEntry.patient.defaultAddress.city =
            order.patient.defaultAddress.city;
          orderEntry.patient.defaultAddress.line1 =
            order.patient.defaultAddress.line1;
          orderEntry.patient.defaultAddress.line2 =
            order.patient.defaultAddress.line2;
          orderEntry.patient.defaultAddress.country =
            order.patient.defaultAddress.country;
          orderEntry.patient.defaultAddress.postalCode =
            order.patient.defaultAddress.postalCode;
          orderEntry.patient.defaultAddress.stateProvince =
            order.patient.defaultAddress.stateProvince;
        }

        if (order.patient.defaultEmail !== null) {
          orderEntry.patient.defaultEmail.id = order.patient.defaultEmail.id;
          orderEntry.patient.defaultEmail.email =
            order.patient.defaultEmail.email;
        }

        // set default phone
        if (order.patient.defaultPhone) {
          const { id, phone, areaCode } = order.patient.defaultPhone;

          orderEntry.patient.defaultPhone = {
            id,
            phone,
            areaCode,
          };
        }
      }
      // set patient signature
      if (order.patientSignature !== null) {
        orderEntry.patientSignature.id = order.patientSignature.id;
        orderEntry.patientSignature.signatureType =
          order.patientSignature.signatureType;
      }
      if (order.usedInsurance && order.usedInsurance !== null) {
        const { usedInsurance } = order;

        orderEntry.insurances = [];
        for (let i = 0; i < usedInsurance.length; i++) {
          let insurance = {
            authorizationNumber: "",
            authorizationRequired: "",
            groupId: "",
            groupName: "",
            id: usedInsurance[i].insurance.id,
            insurancePlanName: "",
            insuranceRelationshipType: null,
            insured: { firstName: "", lastName: "", middleName: "" },
            isDefault: false,
            isDeleted: false,
            msp: "",
            insuranceCompany: null,
            payorPriority: usedInsurance[i].insurance.payorPriority,
            planId: "",
            subscriberId: "",
            note: "",
          };
          usedInsurance[i].insurance["isDefault"] =
            usedInsurance[i].isDefault || false;

          if (!usedInsurance[i].insurance.insured)
            insurance.insured = {
              id: null,
              firstName: "",
              lastName: "",
              middleName: "",
              defaultAddress: {
                id: null,
                line1: "",
                line2: "",
                city: "",
                stateProvince: "",
                postalCode: "",
                country: "",
              },
            };
          else {
            insurance.insured = usedInsurance[i].insurance.insured;

            insurance.insured.middleName =
              usedInsurance[i].insurance.insured.middleName || "";
            if (!usedInsurance[i].insurance.insured.defaultAddress)
              insurance.insured["defaultAddress"] = {
                id: null,
                line1: "",
                line2: "",
                city: "",
                stateProvince: "",
                postalCode: "",
                country: "",
              };
          }

          if (usedInsurance[i].insurance.insuranceRelationshipType)
            insurance.insuranceRelationshipType = {
              id: usedInsurance[i].insurance.insuranceRelationshipType.id,
              hippaCode:
                usedInsurance[i].insurance.insuranceRelationshipType.hippaCode,
              label: usedInsurance[i].insurance.insuranceRelationshipType.label,
            };

          if (usedInsurance[i].insurance.msp)
            insurance.msp = {
              id: usedInsurance[i].insurance.msp.id,
              mspCode: usedInsurance[i].insurance.msp.mspCode,
              mspCodeDescription:
                usedInsurance[i].insurance.msp.mspCodeDescription,
            };

          if (usedInsurance[i].insurance.insuranceCompany) {
            insurance.insuranceCompany = {
              id: usedInsurance[i].insurance.insuranceCompany.id,
              name: usedInsurance[i].insurance.insuranceCompany.name,
              payorGroup:
                usedInsurance[i].insurance.insuranceCompany.payorGroup || "",
              payorSubGroup:
                usedInsurance[i].insurance.insuranceCompany.payorSubGroup || "",
              payorId:
                usedInsurance[i].insurance.insuranceCompany.insuranceCompanyId,
            };

            if (!order.billingStatementStatus) {
              if (
                usedInsurance[i].insurance.payorPriority == 1 &&
                !order.billingStatementStatus
              ) {
                if (
                  usedInsurance[i].insurance.insuranceCompany.payorGroup ==
                  "Patient"
                )
                  orderEntry.billingStatementStatus = "PTSTMT";
                else if (
                  usedInsurance[i].insurance.insuranceCompany.payorGroup ==
                  "Client"
                )
                  orderEntry.billingStatementStatus = "CLSTMT";
                else orderEntry.billingStatementStatus = "TP";
              }
            } else
              orderEntry.billingStatementStatus = order.billingStatementStatus;
          }

          if (
            usedInsurance[i].insurance.notes &&
            usedInsurance[i].insurance.notes.length
          )
            insurance.note = usedInsurance[i].insurance.notes[0];
          else
            insurance.note = {
              id: null,
              note: "",
            };

          insurance.planId = usedInsurance[i].insurance.planId || "";
          insurance.insurancePlanName =
            usedInsurance[i].insurance.insurancePlanName || "";
          insurance.groupId = usedInsurance[i].insurance.groupId || "";
          insurance.groupName = usedInsurance[i].insurance.groupName || "";
          insurance.subscriberId =
            usedInsurance[i].insurance.subscriberId || "";
          insurance.authorizationNumber =
            usedInsurance[i].insurance.authorizationNumber || "";

          orderEntry.insurances.push(insurance);
        }
      }

      if (order.test !== null) {
        orderEntry.test.id = order.test.id;

        if (order.test.cptCode !== null)
          orderEntry.test.cptCode = order.test.cptCode;
      }

      if (order.testOrdered !== null) {
        orderEntry.testOrdered = order.testOrdered;
        orderEntry.testOrdered = order.testOrdered || "";
        orderEntry.testOrdered.placeOfService = order.testOrdered.placeOfService
          ? {
              id: order.testOrdered.placeOfService.id,
              posCode: order.testOrdered.placeOfService.posCode,
            }
          : {
              id: null,
              posCode: "",
            };

        if (order.testOrdered.test !== null) {
          orderEntry.testOrdered.test.id = order.testOrdered.test.id;
          orderEntry.testOrdered.test.testCode =
            order.testOrdered.test.testCode;
        }
      }

      // set notes
      if (order.notes !== null) {
        orderEntry.notes = order.notes.map((note) => {
          return {
            id: note.id,
            note: note.note,
            createdBy: note.createdBy,
            created: note.created,
          };
        });
      }

      if (order.sample !== null) {
        orderEntry.sample.id = order.sample.id;
        orderEntry.sample.sampleId = order.sample.sampleId;
        orderEntry.sample.receiveDate = order.sample.receiveDate;

        orderEntry.sample.notes =
          order.sample.notes.length > 0
            ? order.sample.notes.map(({ id, note, title }) => ({
                id,
                note,
                title,
              }))
            : [];

        // set sample storage laboratory
        if (order.sample.sampleStorageLaboratory) {
          orderEntry.sample.sampleStorageLaboratory.id =
            order.sample.sampleStorageLaboratory.id;
          orderEntry.sample.sampleStorageLaboratory.label =
            order.sample.sampleStorageLaboratory.label;
        }
      }

      if (order.payorAccount !== null) {
        orderEntry.payorAccount.id = order.payorAccount.id;
        orderEntry.payorAccount.payorCode = order.payorAccount.payorCode;
        orderEntry.payorAccount.payorName = order.payorAccount.payorName;
      }

      // set billing diagnostic codes
      if (
        order.billingDiagnosticCode !== null &&
        order.billingDiagnosticCode.length > 0
      ) {
        orderEntry.billingDiagnosticCode = order.billingDiagnosticCode.map(
          (bdc) => {
            return {
              id: bdc.id,
              diagnosticCode: bdc.diagnosticCode,
              addedBy: bdc.addedBy,
              addedDate: bdc.addedDate,
              notes: bdc.notes,
              active: bdc.active,
            };
          }
        );
      }

      if (order.eobDepositInformation.length) {
        orderEntry.eobDepositInformation = order.eobDepositInformation;
      }

      // set order entry saved
      orderEntry.orderEntrySaved = order.orderEntrySaved || false;

      orderEntry.$save();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
