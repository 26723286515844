import GraphqlHelper from "./helpers/graphql-helper";

import QueryModel from "store/models/QueryModel";
import Test from "store/models/Test";

const graphqlHelper = new GraphqlHelper();
export default class FeeSchedule extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "fee_schedule";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      feeName: this.attr(""),
      testId: this.attr(null),
      test: this.belongsTo(Test, "testId"),
      effectivityDate: this.attr(""),
      expirationDate: this.attr(""),
      baseRate: this.attr(""),
      discountPercentage: this.attr(""),
      name: this.attr(""),
      heirarchy: this.attr(""),
      terminationDate: this.attr(""),
    };
  }

  static defaultData = [
    "feeName",
    graphqlHelper.subQueryTagBuilder("test", ["label"]),
    "effectivityDate",
    "expirationDate",
    "baseRate",
    "discountPercentage",
    "feeId",
    "name",
    "heirarchy",
    graphqlHelper.subQueryTagBuilder("associatedClients", [
      "id",
      "payorCode",
      "payorName",
    ]),
    "notes",
    "terminationDate",
  ];
}
