export default {
  props: {
    visible: Boolean,
    header: {
      type: String,
      default: 'Change Status',
    },
    message: {
      type: String,
      default: 'You are about to change the status of this record.',
    },
    warning: {
      type: String,
      default: 'Changing the status will update the Termination Date to today\'s date and the deactivation will only take effect after the termination date.',
    },
    primaryWarning: {
      type: String,
      default: '',
    },
    hasWarningAction: { type: Boolean, default: false },
    warningActionText: { type: String, default: 'View item'},
    isActive: {
      required: true,
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    buttonTexts: {
      type: Object,
      default: () => ({
        cancel: 'Cancel',
        activate: 'Activate',
        deactivate: 'Deactivate',
      }),
    },
  },
  computed: {
    __ChangeStatusVisibility: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    derivedStatus() {
      if (this.isActive) {
        return 'Active';
      }
      return 'Deactivated';
    },
    submitButtonText() {
      const { activate, deactivate } = this.buttonTexts;
      if (this.isActive) {
        return deactivate;
      }
      return activate;
    },
  },
};
