import QueryModel from "store/models/QueryModel";

export default class FinalAccessionReportConfig extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "final_accession_report_config";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      orderNumber: this.attr(Boolean),
      clientId: this.attr(Boolean),
      clientName: this.attr(Boolean),
      accessionNumber: this.attr(Boolean),
      patientFirstName: this.attr(Boolean),
      patientLastName: this.attr(Boolean),
      dateOfService: this.attr(Boolean),
      dateReceivedInLab: this.attr(Boolean),
      dateResultsFinalized: this.attr(Boolean),
      orderingPhysicianNPI: this.attr(Boolean),
      orderingPhysicianLastName: this.attr(Boolean),
      orderingPhysicianFirstName: this.attr(Boolean),
      testCode: this.attr(Boolean),
      testName: this.attr(Boolean),
      orderStatus: this.attr(Boolean),
      isPortrait: this.attr(Boolean),
    };
  }

  static defaultData = [
    "orderNumber",
    "clientId",
    "clientName",
    "accessionNumber",
    "patientFirstName",
    "patientLastName",
    "dateOfService",
    "dateReceivedInLab",
    "dateResultsFinalized",
    "orderingPhysicianNPI",
    "orderingPhysicianLastName",
    "orderingPhysicianFirstName",
    "testCode",
    "testName",
    "orderStatus",
    "isPortrait",
  ];
}
