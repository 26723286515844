import QueryModel from "store/models/QueryModel";

// import GraphqlHelper from "./helpers/graphql-helper";
// import AccountSignatureHelper from "./helpers/account-signature-helper";

// const graphqlHelper = new GraphqlHelper();
// const accountSignatureHelper = new AccountSignatureHelper();
export default class AccountSignature extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "account_signature";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      accountSignatureData: this.attr(""),
      filename: this.attr(""),
      created: this.attr(""),
      lastModified: this.attr(""),
    };
  }

  static defaultData = [
    "accountSignatureData",
    "filename",
    "created",
    "lastModified",
  ];
}
