import Npi from "store/models/Npi";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Npi);
export default class OrderActions {
  async searchNpi(npiNumber) {
    return await storeActions.query(
      {
        queryName: "npis",
        relativeUrl: "/npi/search-npi",
        filter: {
          npiNumber,
          enum_patternMatch: "EXACT",
        },
      },
      "To search npi."
    );
  }

  async create(params) {
    return await storeActions.mutation(
      {
        queryName: "createNpi",
        relativeUrl: "/npi/create-npi",
      },
      "To create npi.",
      { ...params }
    );
  }
}
