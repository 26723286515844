const RouterLayout = import("layouts/RouterLayout");

const SystemMaintenance = import("modules/system-maintenance");

const Payors = import("modules/system-maintenance/Payors");

const Facilities = import("modules/system-maintenance/Facilities");
const EditFacility = import(
  "modules/system-maintenance/Facilities/pages/EditFacility"
);
const Accounts = import("modules/system-maintenance/AccountsPage");

const ClientsPhysicians = import("modules/main/ClientsPhysicians");
const Clients = import("modules/main/ClientsPhysicians/Clients");
const AddClient = import(
  "modules/main/ClientsPhysicians/Clients/pages/AddClient"
);
const EditClient = import(
  "modules/main/ClientsPhysicians/Clients/pages/EditClient"
);

const ClientInvoiceTransaction = import(
  "modules/main/ClientsPhysicians/Clients/pages/ClientInvoiceTransaction"
);

const ClientPreviewInvoice = import(
  "modules/main/ClientsPhysicians/Clients/pages/ClientPreviewInvoice"
);

const Physicians = import("modules/main/ClientsPhysicians/Physicians");
const PhysicianDetails = import(
  "modules/main/ClientsPhysicians/Physicians/pages/Details"
);

const FeeSchedule = import("modules/system-maintenance/FeeSchedule");
const AddFeeSchedule = import(
  "modules/system-maintenance/FeeSchedule/pages/AddFeeSchedule"
);

const DiagnosticCodes = import("modules/system-maintenance/DiagnosticCodes");
const CptCodes = import("modules/system-maintenance/CPTCode");
const RevenueCodes = import("modules/system-maintenance/RevenueCodes");
const ScheduledProcessors = import(
  "modules/system-maintenance/ScheduledProcessors"
);
const ConversionRules = import("modules/system-maintenance/ConversionRules");
const AddConversionRules = import(
  "modules/system-maintenance/ConversionRules/pages/AddConversionRules"
);

const routes = [
  {
    path: "/system-maintenance",
    meta: {
      slug: "system-maintenance",
    },
    component: () => RouterLayout,
    children: [
      {
        path: "",
        name: "system-maintenance",
        component: () => SystemMaintenance,
      },
      {
        path: "clients-physicians",
        meta: {
          label: "Clients and Physicians",
          slug: "clients-physicians",
        },
        component: () => RouterLayout,
        children: [
          {
            path: "",
            name: "clients-and-physicians",
            component: () => ClientsPhysicians,
          },
          {
            path: "clients",
            meta: {
              slug: "clients",
            },
            component: () => RouterLayout,
            children: [
              {
                path: "",
                name: "clients",
                component: () => Clients,
              },
              {
                path: "add",
                name: "add-client",
                meta: {
                  slug: "add-client",
                },
                // redirect: '/system-maintenance/clients-physicians',
                component: () => AddClient,
              },
              {
                path: "edit/:id",
                name: "edit-client",
                meta: {
                  slug: "edit-client",
                },
                component: () => EditClient,
              },
              {
                path: "invoice/:id/transactions",
                name: "invoice-transaction-detail",
                meta: {
                  slug: "invoice-transaction-detail",
                },
                component: () => ClientInvoiceTransaction,
              },
              {
                path: "invoice/:id/preview-invoice",
                name: "preview-invoice",
                meta: {
                  slug: "preview-invoice",
                },
                component: () => ClientPreviewInvoice,
              },
            ],
          },
          {
            path: "physician",
            meta: {
              slug: "physician",
            },
            component: () => RouterLayout,
            children: [
              {
                path: "",
                name: "physician",
                component: () => Physicians,
              },
              {
                path: "add",
                name: "add-physician",
                meta: {
                  slug: "add-physician",
                },
                component: () => PhysicianDetails,
              },
              {
                path: "edit/:id",
                name: "edit-physician",
                meta: {
                  slug: "edit-physician",
                },
                component: () => PhysicianDetails,
              },
            ],
          },
        ],
      },
      {
        path: "diagnosis-codes",
        name: "diagnosis-code",
        meta: {
          slug: "diagnosis-codes",
        },
        component: () => DiagnosticCodes,
      },
      {
        path: "payors",
        name: "payors",
        meta: {
          slug: "payors",
        },
        component: () => Payors,
      },
      {
        path: "facilities",
        meta: {
          slug: "facilities",
        },
        component: () => RouterLayout,
        children: [
          {
            path: "",
            name: "facilities",
            component: () => Facilities,
          },
          {
            path: "edit/:id",
            name: "edit-facility",
            meta: {
              slug: "edit-facility",
            },
            component: () => EditFacility,
          },
        ],
      },
      {
        path: "fee-schedule",
        meta: {
          slug: "fee-schedule",
        },
        component: () => RouterLayout,
        children: [
          {
            path: "",
            name: "fee-schedule",
            component: () => FeeSchedule,
          },
          {
            path: "add",
            name: "add-fee-schedule",
            meta: {
              slug: "add-fee-schedule",
            },
            component: () => AddFeeSchedule,
          },
          {
            path: "edit/:id",
            name: "edit-fee-schedule",
            meta: {
              label: "Fee Schedule Details",
              slug: "edit-fee-schedule",
            },
            component: () => AddFeeSchedule,
          },
        ],
      },
      {
        path: "cpt-codes",
        meta: {
          label: "CPT",
          slug: "cpt",
        },
        component: () => RouterLayout,
        children: [
          {
            path: "",
            name: "cpt",
            component: () => CptCodes,
          },
        ],
      },
      {
        path: "conversion-rules",
        meta: {
          label: "Conversion Rules",
          slug: "conversion-rules",
        },
        component: () => RouterLayout,
        children: [
          {
            path: "",
            name: "conversion-rules",
            component: () => ConversionRules,
          },
          {
            path: "add",
            name: "add-rules",
            meta: {
              label: "New Conversion Rules Details",
              slug: "add-rules",
            },
            component: () => AddConversionRules,
          },
          {
            path: "edit/:id",
            name: "edit-rule",
            meta: {
              label: "Conversion Rules Details",
              slug: "edit-conversion-rule",
            },
            component: () => AddConversionRules,
          },
        ],
      },
      {
        path: "revenue-codes",
        name: "revenue-codes",
        meta: {
          slug: "revenue-codes",
        },
        component: () => RevenueCodes,
      },
      {
        path: "scheduled-processors",
        name: "scheduled-processors",
        meta: {
          slug: "scheduled-processors",
        },
        component: () => ScheduledProcessors,
      },
      {
        path: "accounts",
        name: "accounts",
        meta: {
          label: "User Configuration",
          slug: "accounts",
        },
        component: () => Accounts,
      },
    ],
  },
];
export default routes;
