import QueryModel from "store/models/QueryModel";

export default class User extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "user";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(1),
      uuid: this.attr(null),
      name: this.attr(null),
      firstName: this.attr(""),
      lastName: this.attr(""),
      email: this.attr(null),
      picture: this.attr(null),
      roles: this.attr(null),
      username: this.attr(null),
    };
  }

  get userRoles() {
    return this.roles;
  }
}
