import QueryModel from "store/models/QueryModel";

import Email from "store/models/Email";
import Phone from "store/models/Phone";
import Address from "store/models/Address";

// const insuredHelper = new InsuredHelper();
// const graphqlHelper = new GraphqlHelper();
export default class Insured extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "insured";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      firstName: this.string(""),
      lastName: this.string(""),
      middleName: this.string(""),
      dateOfBirth: this.string(""),
      gender: this.string(""),
      addresses: this.hasMany(Address, "insuredId"),
      emails: this.hasMany(Email, "insuredId"),
      phones: this.hasMany(Phone, "insuredId"),
      defaultAddressId: this.attr(null),
      defaultEmailId: this.attr(null),
      defaultPhoneId: this.attr(null),
      defaultAddress: this.belongsTo(Address, "defaultAddressId"),
      defaultEmail: this.belongsTo(Email, "defaultEmailId"),
      defaultPhone: this.belongsTo(Phone, "defaultPhoneId"),
    };
  }
}
