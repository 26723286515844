import Email from "store/models/Email";

import StoreActions from "store/actions";

const storeActions = new StoreActions(Email);
export default class EmailActions {
  async create(params) {
    const { variables, queries } = params;

    return await storeActions.mutation(
      {
        queryName: "createEmail",
        relativeUrl: "/email/create-email",
        queries: queries || null,
      },
      "To create email.",
      { ...variables }
    );
  }
}
