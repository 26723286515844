import { Model } from "@vuex-orm/core";

import GraphqlHelper from "./helpers/graphql-helper";
// import InsurancePlanHelper from "./helpers/insurance-plan-helper";

const graphqlHelper = new GraphqlHelper();
// const insurancePlanHelper = new InsurancePlanHelper();

export default class InsurancePlan extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "insurance_plan";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      label: this.string(""),
      description: this.string(""),
      insuranceCompanyId: this.attr(null),
    };
  }

  static defaultData = [
    "label",
    "created",
    "isDeleted",
    "totalCount",
    graphqlHelper.subQueryTagBuilder("insuranceCompany", [
      "name",
      "insuranceCompanyId",
      "insuranceSourceType",
    ]),
  ];

  // Setup to call an endpoint
  // static apiConfig = {
  //   actions: {
  //     async createInsurancePlan(
  //       variables,
  //       isPatientDataManagement = false,
  //       isDataManagement = false
  //     ) {
  //       try {
  //         let route = isPatientDataManagement
  //           ? "/data-management/patient/create-insurance-plan"
  //           : isDataManagement
  //           ? "/data-management/insurance-plan/create"
  //           : "/accession/step4/create-insurance-plan";

  //         const result = await this.post(route, {
  //           mutation:
  //             "mutation ($label: String!, $description: String) { createInsurancePlan (label: $label, description: $description) { id label description } }",
  //           variables: JSON.stringify(variables)
  //         });
  //         return [result];
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async all(
  //       limit = null,
  //       offset = null,
  //       filter = null,
  //       limitData = null,
  //       isDataManagement = false,
  //       includeDeleted = false,
  //       additionalString = null,
  //       order = null
  //     ) {
  //       let defaultData = limitData ? limitData : this.model.defaultData;

  //       let queryString = graphqlHelper.queryTagBuilder(
  //         "insurancePlans",
  //         defaultData,
  //         {
  //           limit,
  //           offset,
  //           filter,
  //           includeDeleted,
  //           additionalString,
  //           order
  //         }
  //       );

  //       let route = isDataManagement
  //         ? "/data-management/all-insurance-plan"
  //         : "/data-management/all-insurance-plan";

  //       const result = await this.post(route, {
  //         query: queryString
  //       });

  //       return result;
  //     },
  //     async updateInsurancePlanDetails(variables) {
  //       try {
  //         const parameters = ["$insurancePlanId: UUID!"];
  //         const mutationParameter = ["insurancePlanId"];
  //         const setters = [];

  //         insurancePlanHelper.updateLabel(variables, parameters, setters);
  //         insurancePlanHelper.updateDescription(variables, parameters, setters);

  //         const param = {
  //           parameters,
  //           mutation: "updateInsurancePlanDetails",
  //           mutationParameter,
  //           setters
  //         };

  //         const mutation = graphqlHelper.mutationTagBuilder(param);
  //         const result = await this.post(
  //           "/data-management/insurance-plan/update",
  //           {
  //             mutation,
  //             variables: JSON.stringify(variables)
  //           }
  //         );

  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async delete(variables) {
  //       try {
  //         const result = await this.post(
  //           "/data-management/insurance-plan/delete",
  //           {
  //             mutation:
  //               "mutation ($id: UUID!) { deleteInsurancePlan(id: $id) }",
  //             variables: JSON.stringify(variables)
  //           }
  //         );
  //         return result;
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     async getById(id, limitData = null, includeDeleted = false) {
  //       let setters = limitData || this.model.defaultData;

  //       const parameters = ["$id: UUID!"];
  //       const queryParameter = ["id"];

  //       if (limitData && typeof limitData == "object") setters = limitData;

  //       if (includeDeleted) {
  //         parameters.push("$includeDeleted: Boolean");
  //         queryParameter.push("includeDeleted");
  //       }

  //       const query = graphqlHelper.queryWithParameterTagBuilder({
  //         parameters,
  //         query: "getInsurancePlan",
  //         queryParameter,
  //         setters
  //       });

  //       const result = await this.post("/data-management/insurance-plan/get", {
  //         query,
  //         variables: JSON.stringify({ id, includeDeleted })
  //       });
  //       return result;
  //     }
  //   }
  // };
}
