import { uuid } from "vue-uuid";

import Actions from "modules/actions";

export default {
  name: "PhoneSection2",
  props: {
    phones: {
      required: true,
      type: Array,
    },
    singleSelection: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    superScript: {
      required: false,
      type: Object, // { prefix: Boolean, value: String  }
    },
    customRules: {
      required: false,
      type: Object, // { phone: [], areaCode: [] }
    },

    hideAdd: { 
      default: false,
      type: Boolean,
    },

    hideRemove:{ 
      default: false,
      type: Boolean,
    },

    withTag: { 
      default: false,
      type: Boolean,
    },
    setPhoneMask: {
      default: "###-####",
      type: String,
    }
  },
  data() {
    return {
      actions: new Actions(),
      defaultPhone: "",
      phoneMask: this.setPhoneMask,
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (val.id && val.id !== null) || "This field is required.";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateAreaCode: (val) => {
          if (isNaN(this.sanitizePhoneString(val)))
            return "Area Code must be a number.";

          return (
            this.sanitizePhoneString(val).length == 3 ||
            "Area Code must contain 3 numbers."
          );
        },
        isNumeric: (val) =>
          !isNaN(val) || "Phone number only allows numeric characters.",
        hasSpecialChars: (val) =>
          /^[a-zA-Z0-9 ]*$/.test(val) ||
          "Phone number only allows numeric characters.",
        validateLength: (val) =>
          (val && this.sanitizePhoneString(val).length == 7) ||
          "Phone must contain 7 numbers.",
      },
      phoneTypes: [],
    };
  },
  computed: {
    __Phones: {
      get() {
        const defaultPhone = this.phones?.find((phone) => phone.isDefault);

        if (defaultPhone) this.defaultPhone = defaultPhone.uuid;

        return this.phones;
      },
      set(value) {
        this.$emit("update:phones", value);
      },
    },
  },
  methods: {
    async getPhoneTypes() {
      try {
        if (!this.phoneTypes.length) {
          this.phoneTypes = [
            { label: "Fetching phone types...", disabled: true },
          ];

          const result = await this.actions.getPhoneTypes();

          if (result) {
            this.phoneTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    addPhone() {
      this.__Phones.push({
        id: null,
        uuid: uuid.v1(),
        isDefault: false,
        phone: "",
        areaCode: "",
      });
    },
    removePhone(phoneUUID) {
      const findIndex = this.__Phones.findIndex(
        (phone) => phone.uuid === phoneUUID
      );

      if (findIndex > -1) this.__Phones.splice(findIndex, 1);

      this.$nextTick(() => {
        this.updateDefaultPhone(this.defaultPhone);
      });

      this.$refs.form.resetValidation();
    },
    updateDefaultPhone(e) {
      this.__Phones.map((phone) => {
        phone.isDefault = false;
        return phone;
      });

      const findIndex = this.__Phones.findIndex((phone) => phone.uuid === e);

      const setIndex = findIndex > -1 ? findIndex: 0;

      this.__Phones[setIndex].isDefault = true;
      this.defaultPhone = this.__Phones[setIndex].uuid;
    },
    formatReformatAreaCode(uuid, format = false) {
      const findIndex = this.__Phones.findIndex((phone) => phone.uuid == uuid);
      if (findIndex > -1) {
        let areaCode = this.__Phones[findIndex].areaCode;

        this.__Phones[findIndex].areaCode = format
          ? areaCode
            ? `(${areaCode})`
            : ""
          : this.sanitizePhoneString(areaCode);
      }
    },
    allowNumericInput(event) {
      if ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 46)
        event.preventDefault();
    },
  },
  async mounted() {
    this.__Phones.map((phone) => {
      phone["uuid"] = uuid.v1();
      phone.areaCode = phone.areaCode ? `(${phone.areaCode})` : "";
      return phone;
    });

    if (!this.singleSelection || this.__Phones.length) {
      await this.getPhoneTypes();
    }
  },
};
