export default {
  methods: {
    validateEmail(value) {
      if (value.length === 0) return "This field is required";
      return (
        /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(
          value
        ) || "Invalid email address"
      );
    }
  }
};
