export default {
  name: "SearchModal",
  props: {
    openModal: {
      type: Boolean,
      value: false,
    },
    persistent: Boolean,
    modelAction: { type: Function, require: true },
    render: { type: String, require: true },
    mapResult: { type: String, require: true },
    modalTitle: String,
    searchLabel: String,
    searchLogicalOperand: String,
    filterBy: Object,
    searchBy: { type: String, require: true },
    returnObject: { type: String, require: true },
    multipleSelection: Boolean,
    allDataLoadedMsg: String,
    noDataMsg: String,
    preselectedData: [Array, Object],
  },
  data() {
    return {
      dataCollection: [],
      isLoading: false,
      offset: null,
      returnObj: this.multipleSelection ? [] : null,
      searchVal: "",
      totalCount: 0,
      allDataLoaded: false,
    };
  },
  watch: {
    async isLoading(val) {
      if (val) {
        await this.getData();
      }
    },
    openModal(val) {
      if (val) {
        this.dataCollection = [];
        this.offset = null;
        if (this.preselectedData && this.preselectedData.length)
          this.returnObj = this.preselectedData;
        else this.returnObj = this.multipleSelection ? [] : null;
      }
    },
  },
  methods: {
    async getData() {
      let resultsLength = 0;
      try {
        this.allDataLoaded = false;
        const limitData = this.parseLimitData();
        const result = await this.modelAction({
          limit: 20,
          offset: this.offset,
          filter: this.searchVal
            ? {
                ...this.buildFilters(this.searchBy),
                logicalOperator: this.searchLogicalOperand || "OR",
              }
            : null,
          limitData,
        });

        if (result && result.length) {
          resultsLength = result.length;
          if (this.dataCollection.length) {
            this.dataCollection = this.dataCollection.concat(
              this.sanitizeResult(
                this.searchVal
                  ? result
                  : result.filter(
                      (res) =>
                        !this.returnObj.find((datum) => res.id == datum.id)
                    )
              )
            );
          } else {
            this.dataCollection = this.sanitizeResult(
              this.searchVal
                ? result
                : this.returnObj.concat(
                    result.filter(
                      (res) =>
                        !this.returnObj.find((datum) => res.id == datum.id)
                    )
                  )
            );
          }
        }
        if (!this.totalCount) this.totalCount = result[0].totalCount;

        if (this.totalCount == this.dataCollection.length)
          this.allDataLoaded = true;
        else if (!result.length && !this.searchVal) this.allDataLoaded = true;
      } catch (err) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
        this.offset = this.offset ? resultsLength + this.offset : resultsLength;
      }
    },
    async searchData() {
      this.searchDebounce();
    },
    buildFilters(searchFilters) {
      const props = this.parseToArray(searchFilters);

      if (props.length == 1)
        return { [searchFilters.replace(/ /g, "")]: this.searchVal };
      else return props.reduce((pv, cv) => ((pv[cv] = this.searchVal), pv), {});
    },
    sanitizeResult(data) {
      const props = this.parseToArray(this.mapResult);
      return data.map((item) => {
        let collection = {
          label: this.render,
          value: {},
          disable: false,
        };

        for (let i = 0; i < props.length; i++) {
          collection.value[props[i]] = item[props[i]];

          if (collection.label.includes(props[i]))
            collection.label = collection.label.replace(
              `<${props[i]}>`,
              item[props[i]]
            );
        }

        return collection;
      });
    },
    parseLimitData() {
      let paramsArr = this.parseToArray(this.mapResult);
      if (!this.totalCount) paramsArr = paramsArr.concat(["totalCount"]);

      if (!paramsArr.includes("id")) paramsArr = paramsArr.concat(["id"]);

      return paramsArr;
    },
    parseToArray(data) {
      return data.replace(/ /g, "").split(",");
    },
    selectData() {
      const props = this.parseToArray(this.returnObject);
      let returnObj = {};

      if (!this.multipleSelection)
        for (let i = 0; i < props.length; i++)
          returnObj[props[i]] = this.returnObj[props[i]];
      else
        returnObj = this.returnObj.map((obj) => {
          let data = {};
          for (let i = 0; i < props.length; i++) data[props[i]] = obj[props[i]];
          return data;
        });

      this.$emit("selectionResult", returnObj);

      this.clearData();
    },
    isSelected(data) {
      return !this.returnObj.find((datum) => datum.id == data.id);
    },
    clearData() {
      this.searchVal = "";
      this.returnObj = this.multipleSelection ? [] : null;
      this.offset = null;
    },
    selectAll() {
      this.returnObj = this.returnObj.concat(
        this.dataCollection
          .filter((data) => {
            if (this.returnObj.find((obj) => data.value.id == obj.id)) return;
            return data.value;
          })
          .map((data) => data.value)
      );
    },
    deselectAll() {
      this.returnObj = [];
    },
  },
  computed: {
    showSelectAll() {
      return this.returnObj.length;
    },
    isDeselectAll() {
      return !this.dataCollection.filter((data) => {
        if (this.returnObj.find((obj) => obj.id == data.value.id)) return;
        return data.value;
      }).length;
    },
    selectedCollection() {
      return this.sanitizeResult(this.returnObj);
    },
    show: {
      get() {
        return this.openModal
      },
      set(bool){
        this.$emit('update:openModal', bool)
      }
    }
  },
  async created() {
    this.searchDebounce = await this.debounce(() => {
      this.isLoading = true;
      this.dataCollection = [];
      this.offset = null;
    }, 600);
  },
};
