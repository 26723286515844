import QueryModel from "store/models/QueryModel";

export default class AddressType extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "address_type";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      label: this.attr(""),
      forBilling: this.attr(false),
    };
  }

  static defaultData = ["label", "forBilling"];
}
