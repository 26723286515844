import { Model } from "@vuex-orm/core";

export default class OrderEntry extends Model {
  static entity = "order_entry";

  static fields() {
    return {
      id: this.attr(null),
      internalId: this.attr(null),
      requisitionId: this.attr(""),
      abn: this.attr(null),
      dateSampleTaken: this.attr(null),
      dateFirstTransmitted: this.attr(null),
      priorAuthorization: this.attr("no"),
      priorAuthorizationCode: this.attr(""),
      patientSignature: this.attr(() => {
        return {
          id: null,
          signatureType: "",
        };
      }),
      physician: this.attr(() => {
        return {
          orderingPhysician: {
            id: null,
            firstName: "",
            lastName: "",
            taxonomy: "",
            npi: {
              id: null,
              npiNumber: "",
            },
          },
          referringPhysician: {
            id: null,
            firstName: "",
            lastName: "",
            taxonomy: "",
            npi: {
              id: null,
              npiNumber: "",
            },
          },
        };
      }),
      patient: this.attr(() => {
        return {
          info: {
            id: null,
            gender: "",
            dateOfBirth: "",
            firstName: "",
            lastName: "",
            patientSsn: "",
          },
          addresses: {
            id: null,
            city: "",
            country: "",
            line1: "",
            line2: "",
          },
          defaultAddress: {
            id: null,
            city: "",
            country: "",
            line1: "",
            line2: "",
            stateProvince: "",
            postalCode: "",
          },
          defaultPhone: {
            id: null,
            phone: "",
            areaCode: "",
          },
          phones: {
            id: null,
            phone: "",
            areaCode: "",
          },
          defaultEmail: {
            id: null,
            email: "",
          },
        };
      }),
      test: this.attr(() => {
        return {
          id: null,
          testCode: "",
          cptCode: {
            id: null,
            code: "",
            description: "",
            placeOfService: {
              id: null,
            },
            modifier: {
              id: null,
            },
            price: "",
          },
          placeOfService: {
            id: "",
            posCode: "",
          },
          notes: [],
        };
      }),
      testOrdered: this.attr(() => {
        return {
          id: null,
          modifierMb: "",
          placeOfService: {
            id: null,
            posCode: "",
          },
        };
      }),
      insurances: this.attr([]),
      notes: this.attr([]),
      payorAccount: this.attr({
        payorCode: "",
        payorName: "",
      }),
      sample: this.attr(() => {
        return {
          id: null,
          sampleId: "",
          receiveDate: "",
          sampleStorageLaboratory: {
            id: null,
            label: "",
          },
        };
      }),
      billingDiagnosticCode: this.attr([]),
      diagnosticCodes: this.attr([]),
      orderEntrySaved: this.attr(false),
      orderBillingErrorCodes: this.attr([]),
      billType: this.attr(""),
      dateOfService: this.attr(""),
    };
  }
}
