import QueryModel from "store/models/QueryModel";

export default class ConversionRule extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "conversion_rules";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      internalId: this.attr(""),
      name: this.attr(""),
      description: this.attr(""),
      validityStartDate: this.attr(""),
      validityEndDate: this.attr(""),

    };
  }

  static defaultData = [
    "internalId",
    "name",
    "description",
    "validityStartDate",
    "validityEndDate"
  ];
}
