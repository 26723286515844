import { startCase } from "lodash";

const STATUS = {
  inactive: "INACTIVE",
  deactivate: "DEACTIVATE",
  deactivated: "DEACTIVATED",
  active: "ACTIVE",
  activated: "ACTIVATED",
  created: "CREATED",
  pending: "PENDING",
  scheduled: "SCHEDULED",
  available: "AVAILABLE",
  inProgress: "IN-PROGRESS",
  failed: "FAILED",
  unbillable: "UNBILLABLE",
  unpriceable: "UNPRICEABLE",
  posting: "POSTING",
  default: "DEFAULT",
  notDefault: "NOTDEFAULT",
  success: "SUCCESS",
};

export default {
  name: "Status",
  props: {
    align: {
      type: String,
      default: "center",
    },
    capitalized: {
      type: Boolean,
      default: true,
    },
    status: {
      type: String,
      default: "",
    },
    statusCount: Number,
  },
  computed: {
    derivedStatus() {
      const isDefaultStatus = [STATUS.default, STATUS.notDefault].includes(
        this.status.toUpperCase()
      );

      if (isDefaultStatus) {
        return this.status.toUpperCase() == STATUS.default ? "Yes" : "No";
      }

      if (this.capitalized) {
        return startCase(this.status);
      }
      return this.status;
    },
    tagClass() {
      const status = this.status.toUpperCase();
      const isInactive = [
        STATUS.inactive,
        STATUS.deactivate,
        STATUS.deactivated,
        STATUS.unbillable,
        STATUS.unpriceable,
      ].includes(status);
      const isActive = [
        STATUS.active,
        STATUS.activated,
        STATUS.posting,
        STATUS.success,
      ].includes(status);
      const isCreated = status === STATUS.created;
      const isPending = status === STATUS.pending;
      const isScheduled = status === STATUS.scheduled;
      const isAvailable = status === STATUS.available;
      const isInProgress = status === STATUS.inProgress;
      const isFailed = status === STATUS.failed;

      const isDefault = status === STATUS.default;
      const isNotDefault = status === STATUS.notDefault;

      return {
        status: true,
        inactive: isInactive,
        active: isActive || isAvailable,
        createdPending: isCreated || isPending || isScheduled,
        inProgress: isInProgress,
        failed: isFailed,
        default: isDefault,
        notDefault: isNotDefault,
        [`text-${this.align}`]: true,
      };
    },
  },
};
