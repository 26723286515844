import Vue from "vue";

export default {
  methods: {
    showNotifyMessage(options) {
      const { type, message, title } = options;

      const color =
        type === "danger" ? "error" : type === "info" ? "primary" : "success";

      Vue.notify({
        title: title || '',
        speed: 300,
        group: "notif",
        type: color,
        text: message,
      });
    },
  },
};
