import { watch } from "@vue/composition-api";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyField",
  props: {
    currencyValue: {
      required: true,
    },
    loadInitialValue: Boolean,
    label: String,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
    outlined: Boolean,
    prefix: String,
    suffix: String,
    length: Number,
    rules: Array,
    errorMessages: Array,
    hideDetails: Boolean,
    hidePrefix: Boolean,
    options: Object,
    dense: Boolean,
    button: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      theCurrencyValue: "",
      localRules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (val.id && val.id !== null) || "This field is required.";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateCurrency: (val) => {
          const isNegativeInfinity = 1 / val;

          if (isNegativeInfinity === -Infinity)
            return "Currency does not accept negative 0(.00).";

          return true;
        },
      },
    };
  },
  setup(props) {
    const localOptions = {
      currency: "USD",
      locale: "en-US",
      currencyDisplay: "hidden",
      precision: 2,
      hideGroupingSeparatorOnFocus: true,
      valueScalling: "billions",
    };

    const { inputRef, setValue } = useCurrencyInput(
      props.options ? props.options : localOptions
    );
    watch(
      () => props.loadInitialValue,
      () => setValue(props.currencyValue)
    );

    return { inputRef };
  },
  computed: {
    __CurrencyValue: {
      get() {
        return this.currencyValue;
      },
      set(value) {
        this.$emit("update:currencyValue", value);
        this.$nextTick(
          () => (this.theCurrencyValue = this.formatCurrency(value))
        );
      },
    },
  },
  methods: {
    setCurrencyValue(val) {
      const isEqual = this.currencyValue === val;
      this.__CurrencyValue = val;

      if(!isEqual) this.$emit("onNewValue", val);
    },
    validate() {
      return this.$refs.inputRef.validate();
    },
    async validateAsync() {
      return await this.$refs.form.validateAsync();
    },
  },
  watch: {
    currencyValue(newVal, oldVal) {
      if (!newVal) {
        this.theCurrencyValue = "";
        return;
      }
      if (newVal !== oldVal) {
        this.theCurrencyValue = this.formatCurrency(newVal);
      }
    },
  },
  mounted() {
    this.$emit("hasMounted");
    this.theCurrencyValue = this.currencyValue
      ? this.formatCurrency(this.currencyValue)
      : "";
  },
};
