const Dashboard = import("modules/main/Dashboard");
const Profile = import("modules/main/Profile");

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => Dashboard,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => Profile,
  },
];
export default routes;
