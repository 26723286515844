import QueryModel from "store/models/QueryModel";
import Test from "store/models/Test";
import Modifier from "store/models/Modifier";
import PlaceOfService from "store/models/PlaceOfService";
import ConversionTransaction from "store/models/ConversionTransaction";

import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class TestsOrdered extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "tests_ordered";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      testId: this.attr(null),
      modifierId: this.attr(null),
      placeOfServiceId: this.attr(null),
      conversionTransactionId: this.attr(null),
      test: this.belongsTo(Test, "testId"),
      modifier: this.belongsTo(Modifier, "modifierId"),
      placeOfService: this.belongsTo(PlaceOfService, "placeOfServiceId"),
      modifierMb: this.attr(""),
      price: this.attr(""),
      pricedAmount: this.attr(null),
      conversionTransaction: this.belongsTo(
        ConversionTransaction,
        "vbsConvertionTransaction"
      ),
    };
  }

  static defaultData = [
    graphqlHelper.subQueryTagBuilder("test", Test.defaultData),
    graphqlHelper.subQueryTagBuilder("modifier", Modifier.defaultData),
    graphqlHelper.subQueryTagBuilder("status", ["id", "label"]),
    graphqlHelper.subQueryTagBuilder(
      "placeOfService",
      PlaceOfService.defaultData
    ),
    graphqlHelper.subQueryTagBuilder(
      "conversionTransaction",
      ConversionTransaction.defaultData,
    ),
    "price",
    "pricedAmount",
    "modifierMb",
  ];
}
