var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.width || '500',"persistent":""},model:{value:(_vm.__PromptVisibility),callback:function ($$v) {_vm.__PromptVisibility=$$v},expression:"__PromptVisibility"}},[_c('v-card',{class:{ 'pa-8 pl-15 pr-15': _vm.textCenter }},[_c('v-card-text',{staticClass:"pa-5",class:{
        'text-center': _vm.textCenter,
      }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.message)}},[_vm._v(_vm._s(_vm.message || "Prompt message here.."))])]),(!_vm.noDivider)?_c('v-divider'):_vm._e(),_c('div',{staticClass:"prompt-actions",class:{ 'text-center': _vm.textCenter, 'text-right': !_vm.textCenter }},[(!_vm.textCenter)?_c('v-spacer'):_vm._e(),_c('v-btn',{class:{
          'float-left': _vm.negativeBtnPos == 'left',
          'float-right': _vm.negativeBtnPos == 'right',
        },attrs:{"id":"negative_btn","elevation":_vm.buttonsElevation,"color":_vm.negativeBtnColor || '',"depressed":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.doCancel()}}},[_vm._v(" "+_vm._s(_vm.negativeBtnTxt || "Cancel")+" ")]),_c('v-btn',{class:{
          'float-left': _vm.positiveBtnPos == 'left',
          'float-right': _vm.positiveBtnPos == 'right',
        },attrs:{"id":"positive_btn","elevation":_vm.buttonsElevation,"color":_vm.positiveBtnColor || 'primary',"depressed":"","disabled":_vm.isLoading},on:{"click":_vm.doOk}},[_vm._v(" "+_vm._s(_vm.positiveBtnTxt || "Ok")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }