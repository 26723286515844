import EmailTypes from "store/models/EmailType";
import AddressTypes from "store/models/AddressType";
import PhonesTypes from "store/models/PhoneTypes";
import InsuranceRelationshipTypes from "store/models/InsuranceRelationshipType";
import StoreActions from "store/actions";

const emailActions = new StoreActions(EmailTypes);
const addressActions = new StoreActions(AddressTypes);
const phoneActions = new StoreActions(PhonesTypes);
const insuranceRelationshipTypeActions = new StoreActions(
  InsuranceRelationshipTypes
);
export default class EmailTypesActions {
  async getEmailTypes(variables) {
    return await emailActions.query(
      {
        queryName: "emailTypes",
        relativeUrl: "/types/all-email-types",
        ...variables,
      },
      "to load email type list."
    );
  }
  async getPhoneTypes(variables) {
    return await phoneActions.query(
      {
        queryName: "phoneTypes",
        relativeUrl: "/types/all-phone-types",
        ...variables,
      },
      "to load phone type list."
    );
  }
  async getAddressTypes(variables) {
    return await addressActions.query(
      {
        queryName: "addressTypes",
        relativeUrl: "/types/all-address-types",
        ...variables,
      },
      "to load address type list."
    );
  }
  async getInsuranceRelationshipTypes(variables) {
    return await insuranceRelationshipTypeActions.query(
      {
        queryName: "insuranceRelationshipTypes",
        relativeUrl: "/types/all-insurance-relationship-types",
        ...variables,
      },
      "To load insurance relationship type list."
    );
  }
}
