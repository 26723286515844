import CIAccordion from "./components/ClientInvoiceAccordion";

export default {
  name: "DataTable",
  components: {
    CIAccordion,
  },
  props: {
    selectable: Boolean,
    singleSelect: Boolean,
    selectAction: Array,
    selectActionPosition: String,
    isLoading: Boolean,
    initialLoading: Boolean,
    data: Array,
    orderBy: {
      type: Object,
      default: () => null,
    },
    isView: Boolean,
    limitData: Array,
    columns: Array,
    totalCount: Number,
    withAdd: Boolean,
    addLabel: String,
    historyAction: Object,
    singleSelectAction: Object,
    // note:
    // option values should be fetched on api before passing as props value to search filter component
    // add multiselect: true for multiselect field functionality
    searchFilter: Object,
    noDataLabel: String,
    viewLabel: String,
    editLabel: String,
    deleteLabel: String,
    additionalButton: Object,
    hasArchive: Boolean,
    accessControl: Object,
    hideView: Boolean,
    rowsPerPage: Number,
    hideDelete: Boolean,
    hasInactive: Boolean,
    hideActionBtn: Boolean,
    inactiveLabel: String,
    activeLabel: String,
    disabledSelection: { type: Boolean, default: () => false },
    hasChangeStatus: { type: Boolean, default: () => false },
    hasAuditTrail: { type: Boolean, default: () => false },
    initialValue: Array,
    hideHeader: { type: Boolean, default: false },
    disableCheckbox: { type: Object, default: () => {} },
    tableClass: { type: String, default: "custom-data-table" },
    whiteRows: Boolean,
    topBottomBorder: Boolean,
    tableHeight: [Number, String],
    removePagination: Boolean,
    customPerPageOptions: Array, // [Object] {text: String, value: Number}
    customItemsPerPage: Number, // value should be included on the given customPerPageOptions
    noDataIcon: {
      type: Boolean,
      default: true,
    },
    editableColumns: {
      type: Boolean,
      default: false,
    },
    useSkeletonLoader: {
      type: Boolean,
      default: false,
    },
    withSearchFilter: {
      type: Boolean,
      default: true,
    },
    clientInvoices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: "",
      selected: [],
      childSelected: [],
      hasError: false,
      dataOnError: null,
      isPopUpDataLoading: false,
      popUpData: {},
      Nrows: 10,
      loadInitialCurrencyValue: false,
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortOptions: {
          sortBy: "",
          sort: "",
          isDateColumn: false,
        },
      },
      perPageOptions: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "20",
          value: 20,
        },
      ],
      columnsMenuVisible: false,
      visibleColumns: [],
      expandedRow: [],
    };
  },
  computed: {
    counter() {
      if (this.searchFilter && this.searchFilter.offset == null) {
        this.pagination.page = 1;
      }
      return (this.searchFilter && this.searchFilter.offset) || 0;
    },
    viewMenuClickable() {
      return (
        this.accessControl.authUserHasViewAccessControl &&
        this.accessControl.authUserHasViewAccessControl
      );
    },
    viewMenuDisable() {
      return (
        this.accessControl.authUserHasViewAccessControl &&
        !this.accessControl.authUserHasViewAccessControl
      );
    },
    editMenuClickable() {
      return (
        !this.accessControl.authUserHasEditAccessControl ||
        (this.accessControl.authUserHasEditAccessControl &&
          this.accessControl.authUserHasEditAccessControl)
      );
    },
    editMenuDisable() {
      return (
        this.accessControl.authUserHasEditAccessControl &&
        !this.accessControl.authUserHasEditAccessControl
      );
    },
    deleteMenuClickable() {
      return (
        !this.accessControl.authUserHasDeleteAccessControl ||
        (this.accessControl.authUserHasDeleteAccessControl &&
          this.accessControl.authUserHasDeleteAccessControl)
      );
    },
    deleteMenuDisable() {
      return (
        this.accessControl.authUserHasDeleteAccessControl &&
        !this.accessControl.authUserHasDeleteAccessControl
      );
    },
    addMenuClickable() {
      return (
        this.accessControl.authUserHasAddAccessControl &&
        this.accessControl.authUserHasAddAccessControl
      );
    },
    addMenuDisable() {
      return (
        this.accessControl.authUserHasAddAccessControl &&
        !this.accessControl.authUserHasAddAccessControl
      );
    },
    archiveMenuClickable() {
      return (
        !this.accessControl.authUserHasArchiveAccessControl ||
        (this.accessControl.authUserHasArchiveAccessControl &&
          this.accessControl.authUserHasArchiveAccessControl)
      );
    },
    archiveMenuDisable() {
      return (
        this.accessControl.authUserHasArchiveAccessControl &&
        !this.accessControl.authUserHasArchiveAccessControl
      );
    },
    additionalButtonMenuClickable() {
      return (
        !this.accessControl.authUserHasButtonAccessControl ||
        (this.accessControl.authUserHasButtonAccessControl &&
          this.accessControl.authUserHasButtonAccessControl)
      );
    },
    additionalButtonMenuDisable() {
      return (
        this.accessControl.authUserHasButtonAccessControl &&
        !this.accessControl.authUserHasButtonAccessControl
      );
    },
    selectedColumns: {
      get() {
        return this.visibleColumns.map((column) => column.field);
      },
      set() {
        this.$emit("update:columnsUpdated", true);
      },
    },
    isTableVisible() {
      if (!this.useSkeletonLoader || !this.isLoading) return true;
      return false;
    },
  },

  watch: {
    "searchFilter.currentLimit": function (val) {
      if (
        val == (this.customItemsPerPage || 10) &&
        this.paginationOptions.itemsPerPage != (this.customItemsPerPage || 10)
      ) {
        this.paginationOptions.itemsPerPage = this.customItemsPerPage || 10;
        this.paginationOptions.page = 1;
      }
    },
    "searchFilter.offset": function (val) {
      if (val == null) {
        this.paginationOptions.page = 1;
      }
    },
  },
  filters: {
    formatStatus(value) {
      if (!value) return "";
      if (["ACTIVE", "ACTIVATED"].includes(value.toUpperCase()))
        return "Active";
      if (
        ["INACTIVE", "DEACTIVATE", "DEACTIVATED"].includes(value.toUpperCase())
      )
        return "Deactivated";
      return value;
    },
  },
  methods: {
    expandRow(data) {
      const index = this.expandedRow.findIndex((row) => row.id == data.id);
      if (index !== -1) this.expandedRow.splice(index, 1);
      else this.expandedRow.push(data);
    },
    getRowsPageString(pagination) {
      if (pagination.page * pagination.itemsPerPage < this.totalCount) {
        return `${pagination.itemsPerPage * (pagination.page - 1) + 1} - ${
          pagination.page * pagination.itemsPerPage
        } of ${this.totalCount}`;
      } else {
        return `${pagination.itemsPerPage * (pagination.page - 1) + 1} - ${
          this.totalCount
        } of ${this.totalCount}`;
      }
    },
    clearSelected() {
      this.selected = [];
    },
    setError(bool, dataOnError = null) {
      this.dataOnError = dataOnError;
      this.hasError = bool;
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } selected`;
    },
    getSelectedItems() {
      return this.selected;
    },
    getRowsNumberCount() {
      return this.totalCount;
    },
    customSort(rows) {
      const data = [...rows];
      this.onRequest({
        pagination: this.pagination,
        filter: undefined,
      });

      return data;
    },
    add() {
      this.$emit("onAdd");
    },
    deleteItem(id) {
      this.$emit("onDelete", id);
    },
    archive(id) {
      this.$emit("onArchive", id);
    },
    changeStatus(id, isActive) {
      this.$emit("onChangeStatus", id, isActive);
    },
    auditTrail(id) {
      this.$emit("onToggleAudit", id);
    },
    inactive(id, inactive = false) {
      if (inactive) {
        this.$emit("onActive", id);
      } else {
        this.$emit("onInactive", id);
      }
    },
    edit(id) {
      this.$emit("onEdit", id);
    },
    view(id) {
      this.$emit("onView", id);
    },
    isNonViewMenuItemVisible(data) {
      if (data.showViewMenuOnly) return !data.showViewMenuOnly;
      else return true;
    },
    isForceDisplayAdditionalButton(data) {
      if (data.forceDisplayAdditionalButton)
        return data.forceDisplayAdditionalButton;
      else return false;
    },
    refetch() {
      if (this.dataOnError) {
        this.dataOnError = null;
      }
      if (!this.withSearchFilter) {
        this.$emit("executeQuery");
        this.paginationOptions.page = 1;
        return;
      }
      this.searchFilter.filterSearchModel(this.Nrows, null);
    },
    isDataTableSelectColumn(column) {
      return column.value?.toUpperCase() == "DATA-TABLE-SELECT";
    },
    totalPages(itemsPerPage) {
      const hasRemainder = this.totalCount % itemsPerPage != 0;
      return (
        Math.floor(this.totalCount / itemsPerPage) + (hasRemainder ? 1 : 0)
      );
    },
    async navigate(pageNumber) {
      try {
        if (this.withSearchFilter && !this.searchFilter) return;

        const { sortOptions } = this.paginationOptions;

        const offset =
          pageNumber > 1
            ? (pageNumber - 1) * this.paginationOptions.itemsPerPage
            : null;

        let sort = null;
        let sortByName = null;

        const dateAliases = {
          created: "CREATED",
          lastModified: "LASTMODIFIED",
          dateFirstTransmitted: "DATEFIRSTTRANSMITTED",
          dateSampleTaken: "DATESAMPLETAKEN",
          effectivityDate: "EFFECTIVITYDATE",
          terminationDate: "TERMINATIONDATE",
        };

        if (sortOptions?.sortBy?.length) {
          const { sortBy, isDateColumn } = sortOptions;
          const derivedSorter =
            this.orderBy?.[sortBy] ||
            `orderBy${sortBy.charAt(0).toUpperCase()}${sortBy.slice(1)}`;
          sortByName = isDateColumn
            ? this.orderBy[sortBy] || "orderByDate"
            : derivedSorter;
        }

        if (sortOptions.isDateColumn) {
          sort = sortByName
            ? {
                by: sortByName,
                order: `{ dateField: ${
                  dateAliases[sortOptions.sortBy] || "CREATED"
                }, orderBy: ${sortOptions.sort.toUpperCase() || "DESC"} }`,
              }
            : null;
        } else {
          sort = sortByName
            ? {
                by: sortByName,
                order: sortOptions.sort.toUpperCase() || "DESC",
              }
            : null;
        }

        if (!this.withSearchFilter) {
          await this.$emit("executeQuery", {
            limit: this.paginationOptions.itemsPerPage,
            offset,
            sort,
          });
          return;
        }

        await this.searchFilter.filterSearchModel(
          this.paginationOptions.itemsPerPage,
          offset,
          false,
          sort
        );
      } catch (err) {
        this.showNotifyMessage({
          message: "A problem has occured while fetching data.",
          type: "danger",
        });
      } finally {
        this.$emit("update:isLoading", false);
      }
    },
    async firstPage() {
      this.paginationOptions.page = 1;
      await this.navigate(1);
    },
    async lastPage(itemsPerPage) {
      const totalPages = this.totalPages(itemsPerPage);

      this.paginationOptions.page = totalPages;

      await this.navigate(totalPages);
    },
    async sortBy(column, sort) {
      const isDateColumn = column?.isDateColumn || false;

      this.paginationOptions.sortOptions = {
        sortBy: column.name,
        sort,
        isDateColumn,
      };

      await this.navigate(1);
    },
    isActiveColumn(field, sort = null, sortItem = false) {
      const { sortOptions } = this.paginationOptions;

      if (sortItem && sort)
        return sortOptions.sort == sort && sortOptions.sortBy == field;

      return sortOptions.sortBy == field;
    },
    disablePaginationButton(button = "first") {
      const { itemsPerPage, page } = this.paginationOptions;
      if (this.isLoading) return true;
      if (button === "first") return page === 1;
      if (page === this.totalPages(itemsPerPage)) return true;

      return !this.data.length;
    },
    selectDeselect(isSelected, obj) {
      if (!isSelected) {
        const findIndex = this.selected.findIndex((item) => item.id == obj.id);

        if (findIndex > -1) this.selected.splice(findIndex, 1);
      } else {
        this.selected.push(obj);
      }
      this.$emit("selectDeselect", this.selected);
    },
    setSelected(val) {
      this.selected = val;
    },
    selectDeselectAll() {
      if (!this.selected.length) {
        this.selected = JSON.parse(JSON.stringify(this.data));
      } else {
        this.selected = [];
      }
      this.$emit("selectDeselect", this.selected);
    },
    enterSelect() {
      this.$emit("selectDeselect", this.childSelected);
    },
    resetDataTableOptions() {
      this.paginationOptions = {
        page: 1,
        itemsPerPage: this.customItemsPerPage || 10,
        sortOptions: {
          sortBy: "",
          sort: "",
          isDateColumn: false,
        },
      };
    },
    setColor(color) {
      return color;
    },
    toggleColumnsMenu() {
      if (!this.isLoading) {
        this.columnsMenuVisible = !this.columnsMenuVisible;
      }
    },
    isColumnSelected(field) {
      const columns = this.visibleColumns.map((column) => column.field);
      return columns.includes(field);
    },
    isColumnOptionDisabled(field) {
      if (field === "action") return true;
      if (
        this.selectedColumns.length <= 2 &&
        this.selectedColumns.includes(field)
      )
        return true;
      return false;
    },
    onSelectColumn(field) {
      if (this.isColumnSelected(field)) {
        this.visibleColumns = this.visibleColumns.filter(
          (i) => i.field !== field
        );
      } else {
        const orderedColumns = [];
        const newColumns = [
          ...this.visibleColumns,
          this.columns.find((i) => i.field === field),
        ];
        this.columns.map((column) => {
          const isVisible =
            newColumns.findIndex((i) => i.field === column.field) !== -1;
          if (isVisible) {
            orderedColumns.push(column);
          }
        });
        this.visibleColumns = orderedColumns;
      }
    },
  },
  mounted() {
    this.Nrows = this.customItemsPerPage || this.rowsPerPage || 10;
    if (this.initialValue) {
      this.selected = this.initialValue;
    }

    if (this.customPerPageOptions) {
      this.perPageOptions = this.customPerPageOptions;
    }

    if (this.customItemsPerPage) {
      this.paginationOptions.itemsPerPage = this.customItemsPerPage;

      const isIncluded =
        this.perPageOptions.filter(
          (item) => item.value == this.paginationOptions.itemsPerPage
        ).length > 0;

      if (!isIncluded) {
        this.perPageOptions.push({
          text: this.paginationOptions.itemsPerPage.toString(),
          value: this.paginationOptions.itemsPerPage,
        });

        this.perPageOptions = this.perPageOptions.sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
      }
    }

    if (this.visibleColumns.length === 0 && this.columns) {
      this.visibleColumns = this.columns;
    }
  },
};
