import Account from "store/models/Account";
import GraphqlHelper from "./helpers/graphql-helper";
import QueryModel from "store/models/QueryModel";

const graphqlHelper = new GraphqlHelper();

export default class RevenueCode extends QueryModel {
  static entity = "revenue_code";
  static fields() {
    return {
      id: this.attr(null),
      codeType: this.attr(""),
      code: this.attr(""),
      description: this.attr(""),
      helpInfo: this.attr(""),
      glCode: this.attr(""),
      isActive: this.attr(""),
      validityStartDate: this.attr(""),
      validityEndDate: this.attr(""),
      created: this.attr(""),
      lastModified: this.attr(""),
      createdBy: this.belongsTo(Account, "createdById"),
      lastModifiedBy: this.belongsTo(Account, "lastModifiedBy"),
    };
  }

  static defaultData = [
    graphqlHelper.subQueryTagBuilder("codeType", ["enum", "label"]),
    "code",
    "description",
    "helpInfo",
    "glCode",
    "isActive",
    "validityStartDate",
    "validityEndDate",
    "lastModified",
    "created",
  ];
}
