import FormDataTable from "./FormDataTable";

import { camelCase } from "lodash";

export default {
  name: "SearchDataTableModal",
  components: { FormDataTable },
  props: {
    openModal: {
      type: Boolean,
      value: false,
    },
    modalTitle: String,
    sectionNote: String,

    hasHeadSlot: { type: Boolean, default: false },

    tableHeader: [Array, Object], // if with Tabs create nested same length of tabTitles
    searchLabel: String, // if with tabs set inside `dataTableConfig`

    // For Single DataTable Config
    withSearchFilter: { type: Boolean, default: true },
    executeQuery: Function,
    limitData: Array,
    relativeUrl: String,
    queryName: String,
    searchBy: String,
    modelInstance: Function,
    initialValue: Array,
    selectable: { type: Boolean, default: true },
    searchLogicalOperand: String,

    hideSearch: { type: Boolean, default: false },
    hideEditAction: { type: Boolean, default: false },
    hideSaveAction: { type: Boolean, default: false },
    hideArchiveSwitch: { type: Boolean, default: false },

    mapDataCollection: Function,

    initialFilter: { text: Object, default: null },

    // for Tab support Config
    withTabs: { type: Boolean, default: false },
    tabTitles: Array,
    dataTableConfig: Array, //  limitData: Array,
    multipleInitialValue: Array,
  },
  data() {
    return {
      canEdit: true,
      tab: "",
      selectedInTabs: {},
      disabledSave: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.openModal;
      },
      set(bool) {
        this.$emit("update:openModal", bool);
      },
    },
    isSingleTable() {
      return !this.withTabs ? true : false;
    },
  },
  methods: {
    itemFromSlotSelectDeselectAll(e) {
      const { status, title, selected } = e;
      if (status) {
        selected.forEach((item) => {
          this.itemFromSlotSelectDeselect({ item: item, value: status }, title);
        });
      } else {
        const nodeName = camelCase(title);
        this.selectedInTabs[nodeName] = [];
        this.disabledSave = this.hasSelectedExclusion();
      }
    },
    itemFromSlotSelectDeselect(e, title) {
      const { value, item } = e;

      const nodeName = camelCase(title);

      const findIndex = this.selectedInTabs[nodeName].findIndex(
        (fieldItem) => fieldItem.id == (item.id ? item.id : item.value)
      );

      if (findIndex > -1) {
        this.selectedInTabs[nodeName].splice(findIndex, 1);
      } else
        this.selectedInTabs[nodeName].push({
          id: item.id ? item.id : item.value,
          item,
          selected: value,
        });

      this.disabledSave = this.hasSelectedExclusion();
    },

    itemsFromCustomTableSelectDeselect(
      selectedFromTable,
      title,
      checkCount = true
    ) {
      const nodeName = camelCase(title);
      this.selectedInTabs[nodeName] = [...selectedFromTable];
      if (checkCount) this.disabledSave = this.hasSelectedExclusion();
    },

    hasSelectedExclusion() {
      let hasSelection = [];

      if (this?.tabTitles?.length)
        this.tabTitles.forEach((title) => {
          const nodeName = camelCase(title);
          if (this.selectedInTabs[nodeName])
            hasSelection.push(this.selectedInTabs[nodeName].length);
        });
      return hasSelection.some((item) => item >= 1);
    },

    getTab(title) {
      this.$emit("getCurrentTab", title);
    },

    resetFields() {
      this.$refs.formDataTable.forEach((formTable) => {
        formTable.searchVal = "";
        formTable.selectedOption = "";
      });
    },

    closeModal() {
      this.canEdit = false;
      this.disabledSave = false;

      this.resetFields();
      this.$emit(
        "closeModal",
        this.selectable ? this.getMultipleSelectedinTable() : []
      );
    },

    getSelected() {
      this.resetFields();
      this.$emit("returnSelected", this.getMultipleSelectedinTable());
    },

    getMultipleSelectedinTable() {
      return this.selectedInTabs;
    },
    onEditClick() {
      this.canEdit = true;
      this.disabledSave = true;
      this.$emit("editModal", this.canEdit);
    },

    setUpListeners() {
      this.$eventBus.$on("searchModalChangeCanEdit", (canEdit) => {
        this.canEdit = canEdit;
        this.disabledSave = this.hasSelectedExclusion();
      });
    },
  },
  mounted() {
    if (this?.tabTitles?.length)
      this.tabTitles.forEach((title, index) => {
        const nodeName = camelCase(title);
        Object.assign(this.selectedInTabs, {
          [nodeName]: this.multipleInitialValue[index]
            ? [...this.multipleInitialValue[index]]
            : [],
        });
      });

    if (this.$route.name.includes("edit")) {
      this.canEdit = false;
    }
    this.disabledSave = this.hasSelectedExclusion();

    this.setUpListeners();
  },
};
