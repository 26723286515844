import GraphqlHelper from "./graphql-helper";
import { camelCase } from "lodash";

const graphqlHelper = new GraphqlHelper();

export default class ModelHelper {
  /*
    Data structure should be:
    const variables = {
      id: String, (MANDATORY)
      includeDeleted: Boolean, (optional)
    };
  */
  getQueryById(variables, setters, options) {
    let parameters = [];
    let queryParameter = [];

    // Validate id property (MANDATORY)
    if (
      graphqlHelper.objectHasOwnProperty(variables, "id") &&
      variables.id.length > 0
    ) {
      parameters.push("$id: UUID!");
      queryParameter.push("id");
    } else throw new Error(`Missing property "id" in variable object.`);

    // Validate includeDeleted property (OPTIONAL)
    if (
      graphqlHelper.objectHasOwnProperty(options, "includeDeleted") &&
      options.includeDeleted
    ) {
      parameters.push("$includeDeleted: Boolean");
      queryParameter.push("includeDeleted");

      variables["includeDeleted"] = options.includeDeleted;
    }

    // Validate includeInactive property (OPTIONAL)
    if (graphqlHelper.objectHasOwnProperty(options, "includeInactive")) {
      parameters.push("$includeInactive: Boolean");
      queryParameter.push("includeInactive");

      variables["includeInactive"] = options.includeInactive;
    }

    return graphqlHelper.queryWithParameterTagBuilder({
      parameters,
      query: options.queryName,
      queryParameter,
      setters,
    });
  }

  /*
    Data structure should be:
    const variables = {
      property: {
        propertyName: {
          type: String, 
          value: String
        }
      }
    };
  */

  getQueryByProperty(variables, setters, options) {
    let parameters = [];
    let queryParameter = [];

    // Validate id property (MANDATORY)
    if (
      graphqlHelper.objectHasOwnProperty(variables, "property") &&
      Object.keys(variables.property).length > 0
    ) {
      const { property } = variables;
      Object.keys(property).forEach((key) => {
        const { type, value } = property[key];
        parameters.push(`$${key}: ${type}`);
        queryParameter.push(key);
        const payloadKey = camelCase(key.replace(".", " "));
        variables[payloadKey] = value;
      });

      delete variables.property;
    } else throw new Error(`Missing property "property" in variable object.`);

    return graphqlHelper.queryWithParameterTagBuilder({
      parameters,
      query: options.queryName,
      queryParameter,
      setters,
    }, options.noneLimitData);
  }

  getQueryCollection(setters, options) {
    let querySettings = {
      limit: graphqlHelper.objectHasOwnProperty(options, "limit")
        ? options.limit
        : null,
      offset: graphqlHelper.objectHasOwnProperty(options, "offset")
        ? options.offset
        : null,
      filter: graphqlHelper.objectHasOwnProperty(options, "filter")
        ? options.filter
        : null,
      includeDeleted: graphqlHelper.objectHasOwnProperty(
        options,
        "includeDeleted"
      )
        ? options.includeDeleted
        : null,
      additionalString: graphqlHelper.objectHasOwnProperty(
        options,
        "additionalString"
      )
        ? options.additionalString
        : null,
      order: graphqlHelper.objectHasOwnProperty(options, "order")
        ? options.order
        : null,
      includeInactive: graphqlHelper.objectHasOwnProperty(
        options,
        "includeInactive"
      )
        ? options.includeInactive
        : null,
      recipient: graphqlHelper.objectHasOwnProperty(options, "recipient")
        ? options.recipient
        : null,
    };

    return graphqlHelper.queryTagBuilder(
      options.queryName,
      setters,
      querySettings,
      !!options.shouldNotReturnId,
      !!options.noQueryString
    );
  }

  mutationQuery(
    options,
    mutationParameter,
    parameters,
    setters,
    variables = null
  ) {
    if (variables !== null) {
      const paramKeys = Object.keys(variables);

      for (var a = 0; a < paramKeys.length; a++) {
        parameters.push(`$${paramKeys[a]}: ${variables[paramKeys[a]].type}`);
        mutationParameter.push(paramKeys[a]);

        if (
          !graphqlHelper.objectHasOwnProperty(
            variables[paramKeys[a]],
            "unincludeToFields"
          ) ||
          !variables[paramKeys[a]].unincludeToFields
        )
          setters.push(paramKeys[a]);

        variables[`${paramKeys[a]}`] = variables[paramKeys[a]].value;
      }
    }

    if (
      graphqlHelper.objectHasOwnProperty(options, "queries") &&
      options.queries !== null &&
      Object.keys(options.queries).length > 0
    ) {
      const queryKeys = Object.keys(options.queries);
      for (var i = 0; i < queryKeys.length; i++) {
        if (Object.keys(options.queries[queryKeys[i]]).length) {
          let queryParams = [];
          const subQueryKeys = Object.keys(options.queries[queryKeys[i]]);

          for (var k = 0; k < subQueryKeys.length; k++) {
            if (!parameters.length)
              parameters.push(
                `$${subQueryKeys[k]}: ${
                  options.queries[queryKeys[i]][subQueryKeys[k]].type
                }`
              );
            else if (
              !parameters
                .map((a) => a.includes(`$${subQueryKeys[k]}:`))
                .join()
                .includes("true")
            )
              parameters.push(
                `$${subQueryKeys[k]}: ${
                  options.queries[queryKeys[i]][subQueryKeys[k]].type
                }`
              );

            queryParams.push(`${subQueryKeys[k]}: $${subQueryKeys[k]}`);
            variables[`${subQueryKeys[k]}`] =
              options.queries[queryKeys[i]][subQueryKeys[k]].value;
          }

          setters.push(`${queryKeys[i]}(${queryParams.join(", ")})`);
        }
      }
    }

    if (
      graphqlHelper.objectHasOwnProperty(options, "collectionQueries") &&
      options.collectionQueries !== null &&
      options.collectionQueries.length > 0
    ) {
      for (var d = 0; d < options.collectionQueries.length; d++) {
        const queryKeys = Object.keys(options.collectionQueries[d]);
        for (var b = 0; b < queryKeys.length; b++) {
          if (Object.keys(options.collectionQueries[d][queryKeys[b]]).length) {
            let queryParams = [];
            const subQueryKeys = Object.keys(
              options.collectionQueries[d][queryKeys[b]]
            );

            for (var c = 0; c < subQueryKeys.length; c++) {
              if (subQueryKeys[c].toUpperCase() !== "MUTATIONRETURN") {
                parameters.push(
                  `$${subQueryKeys[c]}${d}${b}: ${
                    options.collectionQueries[d][queryKeys[b]][subQueryKeys[c]]
                      .type
                  }`
                );

                queryParams.push(
                  `${subQueryKeys[c]}: $${subQueryKeys[c]}${d}${b}`
                );
                variables[`${subQueryKeys[c]}${d}${b}`] =
                  options.collectionQueries[d][queryKeys[b]][
                    subQueryKeys[c]
                  ].value;
              }
            }
            const findIndexSub = subQueryKeys.findIndex(
              (key) => key.toUpperCase() == "MUTATIONRETURN"
            );
            setters.push(
              `${queryKeys[b]}${d}:${queryKeys[b]}(${queryParams.join(", ")}) ${
                findIndexSub > -1
                  ? "{" +
                    options.collectionQueries[d][queryKeys[b]][
                      subQueryKeys[findIndexSub]
                    ].join(",") +
                    "}"
                  : ""
              }`
            );
          }
        }
      }
    }

    if (
      graphqlHelper.objectHasOwnProperty(options, "childQueries") &&
      options.childQueries !== null &&
      options.childQueries.length > 0
    ) {
      for (d = 0; d < options.childQueries.length; d++) {
        const queryKeys = Object.keys(options.childQueries[d]);
        for (b = 0; b < queryKeys.length; b++) {
          if (Object.keys(options.childQueries[d][queryKeys[b]]).length) {
            let queryParams = [];
            const subQueryKeys = Object.keys(
              options.childQueries[d][queryKeys[b]]
            );

            for (c = 0; c < subQueryKeys.length; c++) {
              const childKeys = Object.keys(
                options.childQueries[d][queryKeys[b]][subQueryKeys[c]]
              );
              let subQueryParam = [];
              for (let i = 0; i < childKeys.length; i++) {
                parameters.push(
                  `$${childKeys[i]}${c}${i}: ${
                    options.childQueries[d][queryKeys[b]][subQueryKeys[c]][
                      childKeys[i]
                    ].type
                  }`
                );

                subQueryParam.push(`${childKeys[i]}: $${childKeys[i]}${c}${i}`);

                variables[`${childKeys[i]}${c}${i}`] =
                  options.childQueries[d][queryKeys[b]][subQueryKeys[c]][
                    childKeys[i]
                  ].value;
              }
              queryParams.push(
                `${subQueryKeys[c]}(${subQueryParam.join(", ")})`
              );
            }
            setters.push(
              `${queryKeys[b]}${d}:${queryKeys[b]}{${queryParams.join(" ")}}`
            );
          }
        }
      }
    }

    if (
      graphqlHelper.objectHasOwnProperty(options, "booleanQueries") &&
      options.booleanQueries !== null &&
      options.booleanQueries.length
    ) {
      for (var t = 0; t < options.booleanQueries.length; t++) {
        setters.push(options.booleanQueries[t]);
      }
    }
  }
}
