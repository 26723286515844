import Vue from "vue";
import Vuetify, { VLayout, VForm } from "vuetify/lib";
import VuetifyAsyncValidation from "vuetify-async-validation";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, {
  components: { VLayout, VForm },
});
Vue.use(VuetifyAsyncValidation);

const options = {
  theme: {
    themes: {
      light: {
        primary: "#41b3f9",
        secondary: "#00306D",
        accent: "#EDAFB8",
        greytext: "#767676",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        "background-grey": "#fafafa",
        moreActionBtn: "#9c9da1",
        graybtn: "#f5f5f5",
        graybtntxt: "#6b6f73",
        closeBtn: "#d1d5db",
        smallColumnTxt: "#2f2724",
        iconColor: "#374252",
      },
    },
  },
};

export default new Vuetify(options);
