import Actions from "modules/actions";
import ClientInvoice from "store/actions/clientInvoice";

const clientInvoiceAction = new ClientInvoice();

export default class ClientInvoiceAction extends Actions {
  async getAllClientInvoice(variables) {
    return clientInvoiceAction.getAll(variables);
  }

  async discardDraftClientInvoice(variables) {
    return await clientInvoiceAction.discardDraft(variables);
  }

  async addNoteToClientInvoice(variables) {
    return await clientInvoiceAction.addNote(variables);
  }

  async getClientInvoiceTransaction(params) {
    return await clientInvoiceAction.getClientInvoiceTransaction(params);
  }

  async applyClientInvoiceTransactionAdjustment(params) {
    return await clientInvoiceAction.applyClientInvoiceTransactionAdjustment(
      params
    );
  }
  async viewClientInvoice(variables) {
    return await clientInvoiceAction.viewClientInvoice(variables);
  }

  async generateClientInvoice(variables) {
    return await clientInvoiceAction.generateClientInvoice(variables);
  }

  async previewClientInvoiceTransactionAdjustment(params) {
    return await clientInvoiceAction.previewClientInvoiceTransactionAdjustment(
      params
    );
  }
}
