import QueryModel from "store/models/QueryModel";
import Modifier from "store/models/Modifier";
import CptCode from "store/models/CPTCode";

import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class CptCodeConversion extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "cpt_code_conversions";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      cptCodeId: this.attr(null),
      modifierId: this.attr(null),
      cptCode: this.belongsTo(CptCode, "cptCodeId"),
      modifier: this.belongsTo(Modifier, "modifierId"),
      unit: this.attr(null)
    };
  }

  static defaultData = [
    "id",
    'unit',
    graphqlHelper.subQueryTagBuilder("cptCode", CptCode.defaultData),
    graphqlHelper.subQueryTagBuilder("modifier", Modifier.defaultData),
  ];
}
