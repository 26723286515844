import axios from "axios";

import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import TRF from "store/models/TRF";
import Npi from "store/models/Npi";
import Test from "store/models/Test";
import User from "store/models/User";
import Onset from "store/models/Onset";
import Order from "store/models/Order";
import Sample from "store/models/Sample";
import Account from "store/models/Account";
import Insured from "store/models/Insured";
import Exclusion from "store/models/Exclusion";
import Physician from "store/models/Physician";
import FeeSchedule from "store/models/FeeSchedule";
import ConversionRule from "store/models/ConversionRule";
import ConversionRulePayorPerPayorSubGroup from "store/models/ConversionRulePayorPerPayorSubGroup";
import Accession from "store/models/Accession";
import Insurance from "store/models/Insurance";
import OrderEntry from "store/models/OrderEntry";
import ContactType from "store/models/ContactType";
import PayorAccount from "store/models/PayorAccount";
import Phlebotomist from "store/models/Phlebotomist";
import InsuranceCard from "store/models/InsuranceCard";
import InsurancePlan from "store/models/InsurancePlan";
import AccessionEntry from "store/models/AccessionEntry";
import DiagnosticCode from "store/models/DiagnosticCode";
import InsuranceCompany from "store/models/InsuranceCompany";
import PatientSignature from "store/models/PatientSignature";
import AccountSignature from "store/models/AccountSignature";
import PhysicianSignature from "store/models/PhysicianSignature";
import EOBDepositInformation from "store/models/EOBDepositInformation";
import InsuranceRelationshipType from "store/models/InsuranceRelationshipType";
import OrderBillingDiagnosticCode from "store/models/OrderBillingDiagnosticCode";
import Patient from "store/models/Patient";
import Address from "store/models/Address";
import Phone from "store/models/Phone";
import Email from "store/models/Email";
import Modifier from "store/models/Modifier";
import MSP from "store/models/MSP";
import CPTCode from "store/models/CPTCode";
import TestOrdered from "store/models/TestOrdered";
import PlaceOfService from "store/models/PlaceOfService";
import EnumModel from "store/models/EnumModel";
import DataManagement from "store/models/DataManagement";
import EmailType from "store/models/EmailType";
import AddressType from "store/models/AddressType";
import PhoneType from "store/models/PhoneTypes";
import BillingClaimsSubmission from "store/models/BillingClaimsSubmission";
import InsuranceNote from "store/models/InsuranceNote";
import BillingTransactionDetails from "store/models/BillingTransactionDetails";
import AddedOrderBillingErrorCode from "store/models/AddedOrderBillingErrorCode";
import OrderBillingErrorCode from "store/models/OrderBillingErrorCode";
import BillingErrorCode from "store/models/BillingErrorCode";
import AppSettings from "store/models/AppSettings";
import SampleStorageLaboratory from "store/models/SampleStorageLaboratory";
import ClaimSubmissionMethod from "store/models/ClaimSubmissionMethod";
import ClaimFilingIndicator from "store/models/ClaimFilingIndicator";
import EnrollmentStatus from "store/models/EnrollmentStatus";
import StateMedicalLicense from "store/models/StateMedicalLicense";
import BillingClientContractualInformation from "store/models/BillingClientContractualInformation";
import ContactInformation from "store/models/ContactInformation";
import PayorFileAttachment from "store/models/PayorFileAttachment";
import PaymentTerm from "store/models/PaymentTerm";
import PaymentType from "store/models/PaymentType";
import FinalAccessionReportConfig from "store/models/FinalAccessionReportConfig";
import FinalAccessionReport from "store/models/FinalAccessionReport";
import AuditLog from "store/models/AuditLog";
import RevenueCode from "store/models/RevenueCode";
import TestsOrdered from "store/models/TestsOrdered";
import ConversionTransaction from "store/models/ConversionTransaction";
import CptCodeConversion from "store/models/CptCodeConversion";
import ScheduledProcessor from "store/models/ScheduledProcessor";
import ScheduledProcessorTxn from "store/models/ScheduledProcessorTxn";
import ClientInvoice from "store/models/ClientInvoice";
import OrderBillingHistory from "store/models/OrderBillingHistory";

Vue.use(Vuex);

VuexORM.use(VuexORMAxios, { axios });

const database = new VuexORM.Database();

database.register(TRF);
database.register(Npi);
database.register(MSP);
database.register(Test);
database.register(User);
database.register(Onset);
database.register(Order);
database.register(Sample);
database.register(Account);
database.register(Insured);
database.register(CPTCode);
database.register(Exclusion);
database.register(Physician);
database.register(FeeSchedule);
database.register(ConversionRule);
database.register(ConversionRulePayorPerPayorSubGroup);
database.register(Accession);
database.register(Insurance);
database.register(OrderEntry);
database.register(ContactType);
database.register(PayorAccount);
database.register(Phlebotomist);
database.register(InsurancePlan);
database.register(InsuranceCard);
database.register(AccessionEntry);
database.register(DiagnosticCode);
database.register(InsuranceCompany);
database.register(PatientSignature);
database.register(AccountSignature);
database.register(PhysicianSignature);
database.register(EOBDepositInformation);
database.register(InsuranceRelationshipType);
database.register(OrderBillingDiagnosticCode);
database.register(Patient);
database.register(Address);
database.register(Phone);
database.register(Email);
database.register(Modifier);
database.register(TestOrdered);
database.register(PlaceOfService);
database.register(EnumModel);
database.register(DataManagement);
database.register(AddressType);
database.register(EmailType);
database.register(PhoneType);
database.register(BillingClaimsSubmission);
database.register(InsuranceNote);
database.register(BillingTransactionDetails);
database.register(AddedOrderBillingErrorCode);
database.register(OrderBillingErrorCode);
database.register(BillingErrorCode);
database.register(AppSettings);
database.register(SampleStorageLaboratory);
database.register(ClaimSubmissionMethod);
database.register(ClaimFilingIndicator);
database.register(EnrollmentStatus);
database.register(StateMedicalLicense);
database.register(BillingClientContractualInformation);
database.register(ContactInformation);
database.register(PayorFileAttachment);
database.register(PaymentTerm);
database.register(PaymentType);
database.register(FinalAccessionReportConfig);
database.register(FinalAccessionReport);
database.register(AuditLog);
database.register(RevenueCode);
database.register(TestsOrdered);
database.register(ConversionTransaction);
database.register(CptCodeConversion);
database.register(ScheduledProcessor);
database.register(ScheduledProcessorTxn);
database.register(ClientInvoice);
database.register(OrderBillingHistory);

const store = new Vuex.Store({
  plugins: [VuexORM.install(database, { namespace: "database" })],
  getters: {
    authUserHasAccessControl: (state) => (userTask) => {
      const hasPriveledge =
        state.database.user.data[1].roles !== null
          ? state.database.user.data[1].roles.filter((role) => {
              if (role.tasks !== null)
                return role.tasks.filter((task) => {
                  task.label === userTask;
                });

              return false;
            })
          : [];

      return hasPriveledge.length ? true : false;
    },
  },
});

export default store;
