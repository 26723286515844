import GraphqlHelper from "./helpers/graphql-helper";
import QueryModel from "store/models/QueryModel";

const graphqlHelper = new GraphqlHelper();
export default class Sample extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "sample_storage_laboratory";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      facilityId: this.attr(null),
      label: this.attr(""),
      taxonomyCode: this.attr(""),
      totalCount: this.attr(null),
      facilityType: this.attr(""),
      taxId: this.attr(""),
      effectivityDate: this.attr(null),
      terminationDate: this.attr(null),
    };
  }

  static defaultData = [
    "facilityId",
    "label",
    "totalCount",
    "taxonomyCode",
    "facilityType",
    "taxId",
    "sampleTestOrderCode",
    "terminationDate",
    "effectivityDate",
    graphqlHelper.subQueryTagBuilder("defaultContactInformation", [
      graphqlHelper.subQueryTagBuilder("mainPhone", [
        "id",
        "phone",
        "areaCode",
      ]),
    ]),
    graphqlHelper.subQueryTagBuilder("defaultAddress", [
      "id",
      "line1",
      "line2",
      "city",
      "country",
      "postalCode",
      "stateProvince",
      graphqlHelper.subQueryTagBuilder("addressType", ["label"]),
      graphqlHelper.subQueryTagBuilder("mainPhone", ["phone", "areaCode"]),
      graphqlHelper.subQueryTagBuilder("mainFax", ["phone", "areaCode"]),
    ]),
    graphqlHelper.subQueryTagBuilder("addresses", [
      "id",
      "line1",
      "line2",
      "city",
      "country",
      "postalCode",
      "stateProvince",
      graphqlHelper.subQueryTagBuilder("addressType", ["label"]),
      graphqlHelper.subQueryTagBuilder("mainPhone", ["phone", "areaCode"]),
      graphqlHelper.subQueryTagBuilder("mainFax", ["phone", "areaCode"]),
    ]),
    graphqlHelper.subQueryTagBuilder("npi", ["npiNumber"]),
    graphqlHelper.subQueryTagBuilder("contactInformations", [
      "firstName",
      "middleName",
      "lastName",
      "nameTitle",

      graphqlHelper.subQueryTagBuilder("mainPhone", ["phone", "areaCode"]),
      graphqlHelper.subQueryTagBuilder("mainFax", ["phone", "areaCode"]),
      graphqlHelper.subQueryTagBuilder("contactEmail", ["email"]),
      "created",
    ]),
  ];
}
