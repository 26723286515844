import HeaderItem from "components/Header/components/HeaderItem";

export default {
  name: "HeaderItem",
  components: { HeaderItem },
  props: {
    item: Object,
    topLevelMenu: Boolean,
  },
  computed: {
    __TopLevelMenu: {
      get() {
        return this.topLevelMenu;
      },
      set(value) {
        this.$emit("update:topLevelMenu", value);
      },
    },
  },
  methods: {
    to(link) {
      this.__TopLevelMenu = false;
      if (this.$router.history.current.path != link) this.$router.push(link);
    },
  },
};
