import Account from "./Account";
import CPTCode from "./CPTCode";
import CptCodeConversion from "./CptCodeConversion";
import QueryModel from "store/models/QueryModel";
import GraphqlHelper from "./helpers/graphql-helper";

const graphqlHelper = new GraphqlHelper();
export default class BillingTransactionDetails extends QueryModel {
  static entity = "billing_transaction_details";

  static fields() {
    return {
      id: this.attr(null),
      transactionDate: this.attr(null),
      payorId: this.attr(null),
      vbsCptCodeId: this.attr(null),
      cptCodeConversionId: this.attr(null),
      checkAmount: this.attr(null),
      subscriberId: this.attr(null),
      payorIcn: this.attr(null),
      depositNumber: this.attr(null),
      checkNumber: this.attr(null),
      vbsCheckDate: this.attr(null),
      vbsRevenueCode: this.attr({}),
      vbsCptCode: this.belongsTo(CPTCode, "vbsCptCodeId"),
      total: this.attr(null),
      deductible: this.attr(null),
      coPay: this.attr(null),
      coInsurance: this.attr(null),
      ptResp: this.attr(null),
      vbsPaidAmount: this.attr(null),
      adjAmount: this.attr(null),
      adjDenialCode: this.attr(null),
      payorPriority: this.attr(null),
      user: this.attr(null),
      paidAmounts: this.attr(null),
      computedValues: this.attr({}),
      totalCount: this.number(0),
      createdBy: this.belongsTo(Account, "payorId"),
      cptCodeConversions: this.belongsTo(
        CptCodeConversion,
        "cptCodeConversionId"
      ),
      vbsTransactionDateTime: this.attr(null),
      isDeleted: this.boolean(null),
      created: this.attr(null),
      lastModified: this.attr(null),
    };
  }

  static defaultData = [
    "id",
    "transactionDate",
    "payorId",
    "subscriberId",
    "payorIcn",
    "depositNumber",
    "checkNumber",
    "checkDate",
    "cptCode",
    "total",
    "deductible",
    "coPay",
    "coInsurance",
    "ptResp",
    "paidAmount",
    "adjAmount",
    "adjDenialCode",
    "payorPriority",
    "user",
    "paidAmounts",
    "checkAmount",
    "vbsPaidAmount",
    "vbsCheckDate",
    "vbsDeductible",
    "vbsTransactionDateTime",
    graphqlHelper.subQueryTagBuilder("cptCodeConversions", [
      "unit",
      graphqlHelper.subQueryTagBuilder("modifier", ["modifierCode"]),
      graphqlHelper.subQueryTagBuilder("cptCode", ["code"]),
    ]),
    graphqlHelper.subQueryTagBuilder(
      "computedValues",
      ["billedAmount", "expAmount", "revenueAdjAmount", "balance"],
      false
    ),
    graphqlHelper.subQueryTagBuilder("vbsCptCode", ["code"]),
    graphqlHelper.subQueryTagBuilder("vbsRevenueCode", ["code"]),
  ];
}
