import GraphqlHelper from "./helpers/graphql-helper";

import QueryModel from "store/models/QueryModel";

import Npi from "store/models/Npi";
import Address from "store/models/Address";
import Email from "store/models/Email";
import Phone from "store/models/Phone";
import Physician from "store/models/Physician";
import Account from "store/models/Account";
import Exclusion from "store/models/Exclusion";
import BillingClientContractualInformation from "store/models/BillingClientContractualInformation";
import ContactInformation from "store/models/ContactInformation";
import FeeSchedule from "store/models/FeeSchedule";

const graphqlHelper = new GraphqlHelper();
export default class PayorAccount extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "payor_account";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      sfdcId: this.attr(""),
      payorCode: this.attr(""),
      payorAccountType: this.attr(""),
      subType: this.attr(""),
      clientBillType: this.attr(""),
      exclusionId: this.attr(null),
      exclusion: this.belongsTo(Exclusion, "exclusionId"),
      exclusionOther: this.attr(""),
      subAccountToId: this.attr(null),
      subAccountTo: this.belongsTo(this, "subAccountToId"),
      payorName: this.attr(""),
      npiId: this.attr(null),
      npi: this.belongsTo(Npi, "npiId"),
      tinNumber: this.attr(""),
      payorInfo: this.attr(""),
      license: this.attr(""),
      addressIds: this.attr([]),
      addresses: this.hasManyBy(Address, "addressIds"),
      emailIds: this.attr([]),
      emails: this.hasManyBy(Email, "emailIds"),
      phoneIds: this.attr([]),
      phones: this.hasManyBy(Phone, "phoneIds"),
      physicianIds: this.attr([]),
      physicians: this.hasManyBy(Physician, "physicianIds"),
      contactPersonIds: this.attr([]),
      defaultAddressId: this.attr(null),
      defaultAddress: this.belongsTo(Address, "defaultAddressId"),
      defaultEmailId: this.attr(null),
      defaultEmail: this.belongsTo(Email, "defaultEmailId"),
      defaultPhoneId: this.attr(null),
      defaultPhone: this.belongsTo(Phone, "defaultPhoneId"),
      defaultPhysicianId: this.attr(null),
      defaultPhysician: this.belongsTo(Physician, "defaultPhysicianId"),
      defaultContactPersonId: this.attr(null),
      billingStatementType: this.attr(""),
      statementBillingTerm: this.attr(""),
      adjustmentCode: this.attr(""),
      customCode: this.attr(""),
      customField: this.attr(""),
      created: this.attr(""),
      lastModified: this.attr(""),
      internalId: this.attr(""),
      totalCount: this.number(0),
      accountId: this.attr(null),
      createdBy: this.belongsTo(Account, "accountId"),
      isDeleted: this.boolean(false),
      orderCount: this.number(0),
      contractualInformationIds: this.attr(null),
      contractualInformations: this.hasManyBy(
        BillingClientContractualInformation,
        "contractualInformationIds"
      ),
      contactInformationsIds: this.attr(null),
      contactInformations: this.hasManyBy(
        ContactInformation,
        "contactInformationsIds"
      ),
      feeScheduleId: this.attr(null),
      feeSchedule: this.hasManyBy(FeeSchedule, "feeScheduleId"),
    };
  }

  static defaultData = [
    "id",
    "payorCode",
    "payorAccountType",
    "subType",
    graphqlHelper.subQueryTagBuilder(
      "clientBillType",
      ["enum", "label"],
      false
    ),
    graphqlHelper.subQueryTagBuilder("subAccountTo", ["payorCode"]),
    "payorName",
    "tinNumber",
    "payorInfo",
    "isDeleted",
    "totalCount",
    graphqlHelper.subQueryTagBuilder("createdBy", Account.defaultData),
    graphqlHelper.subQueryTagBuilder(
      "contractualInformations",
      BillingClientContractualInformation.defaultData
    ),
    graphqlHelper.subQueryTagBuilder(
      "contactInformations",
      ContactInformation.defaultData
    ),
    graphqlHelper.subQueryTagBuilder("physicians", Physician.defaultData),
    graphqlHelper.subQueryTagBuilder("feeSchedules", FeeSchedule.defaultData),
  ];
}
