import { Model } from "@vuex-orm/core";

import GraphqlHelper from "store/models/helpers/graphql-helper";
import ModelHelper from "store/models/helpers/model-helper";

const graphqlHelper = new GraphqlHelper();
const modelHelper = new ModelHelper();
export default class QueryModel extends Model {
  static apiConfig = {
    actions: {
      async query(options, description, variables = null) {
        // enum query
        if (
          graphqlHelper.objectHasOwnProperty(options, "enumQuery") &&
          options.enumQuery.length
        ) {
          const result = await this.post(options.relativeUrl, {
            query: `{__type(name:"${options.enumQuery}"){enumValues{name}}}`,
            queryName: options.queryName,
            description,
          });

          return result;
        } else {
          let setters = [];
          let queryString;
          let params = {
            description,
            queryName: options.queryName,
          };

          if (
            graphqlHelper.objectHasOwnProperty(options, "limitData") &&
            options.limitData !== null &&
            options.limitData.length
          ) {
            setters = options.limitData || this.model.defaultData;
          } else {
            setters = this.model.defaultData;
          }

          if (variables !== null) {
            if (graphqlHelper.objectHasOwnProperty(variables, "id")) {
              queryString = modelHelper.getQueryById(
                variables,
                setters,
                options
              );
            } else {
              queryString = modelHelper.getQueryByProperty(
                variables,
                setters,
                options
              );
            }
          } else {
            queryString = modelHelper.getQueryCollection(setters, options);
          }

          if (variables !== null)
            params["variables"] = JSON.stringify(variables);

          params["query"] = queryString;
          const result = await this.post(options.relativeUrl, params);
          return result;
        }
      },

      async mutation(options, description, variables = null) {
        let parameters = [];
        let mutationParameter = [];
        let setters = [];
        let shouldHaveParameters = false;
        if (
          graphqlHelper.objectHasOwnProperty(options, "limitData") &&
          options.limitData !== null &&
          options.limitData.length
        ) {
          setters = options.limitData;
        }
        if (
          graphqlHelper.objectHasOwnProperty(options, "shouldHaveParameters")
        ) {
          shouldHaveParameters = true;
        }
        modelHelper.mutationQuery(
          options,
          mutationParameter,
          parameters,
          setters,
          variables
        );
        const param = {
          parameters,
          mutation: options.queryName,
          mutationParameter,
          setters,
          options,
        };

        const mutation = graphqlHelper.mutationTagBuilder(
          param,
          shouldHaveParameters,
          !!options.shouldNotReturnId
        );
        const result = await this.post(options.relativeUrl, {
          mutation,
          variables: JSON.stringify(variables),
          description,
          queryName: options.queryName,
        });
        return result;
      },
    },
  };
}
