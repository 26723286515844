import QueryModel from "store/models/QueryModel";
// import GraphqlHelper from "./helpers/graphql-helper";
// const graphqlHelper = new GraphqlHelper();
export default class Address extends QueryModel {
  // This is the name used as module name of the Vuex Store.
  static entity = "address";
  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      isDefault: this.boolean(false),
      practiceId: this.attr(null),
      patientId: this.attr(null),
      practiceContactId: this.attr(null),
      insuredId: this.attr(null),
      line1: this.attr(""),
      line2: this.attr(""),
      city: this.attr(""),
      stateProvince: this.attr(""),
      postalCode: this.attr(""),
      country: this.attr(""),
      isDeleted: this.boolean(false),
      created: this.attr(null),
    };
  }
}
