import axios from "axios";

import Actions from "components/Header/actions/index";
import HeaderItem from "components/Header/components/HeaderItem";

export default {
  name: "Header",
  components: {
    HeaderItem,
  },
  data() {
    return {
      actions: new Actions(),
      headerTime: "",
      topLevelMenus: {
        systemMaintenance: false,
        analytics: false,
      },
      noHeader: ["reset-password", "change-password", "login"],
      menuItems: [
        {
          title: "Orders",
          pathName: "orders",
          url: "/orders",
        },
        {
          title: "System Maintenance",
          pathName: "system-maintenance",
          children: [
            {
              title: "Data Tables",
              children: [
                {
                  title: "Clients and Physicians",
                  url: "/system-maintenance/clients-physicians",
                },
                {
                  title: "Facilities",
                  url: "/system-maintenance/facilities",
                },
                {
                  title: "Payors",
                  url: "/system-maintenance/payors",
                },
                {
                  title: "CPT",
                  url: "/system-maintenance/cpt-codes",
                },
                {
                  title: "Diagnosis Codes",
                  url: "/system-maintenance/diagnosis-codes",
                },
                {
                  title: "Revenue Codes",
                  url: "/system-maintenance/revenue-codes",
                },
              ],
            },
            {
              title: "Fee Schedules",
              url: "/system-maintenance/fee-schedule",
            },
            {
              title: "Monitoring and Alerts",
              url: "",
            },
            {
              title: "Rulesets & Processors",
              children: [
                {
                  title: "Conversion Rules",
                  url: "/system-maintenance/conversion-rules",
                },
                {
                  title: "Scheduled Processors",
                  url: "/system-maintenance/scheduled-processors",
                },
              ],
            },
            {
              title: "User Configuration",
              children: [
                {
                  title: "User Account Configuration",
                  url: "/system-maintenance/accounts",
                },
                {
                  title: "Profile Association",
                  url: "",
                },
              ],
            },
            {
              title: "Work Queue Management",
              url: "",
            },
          ],
        },
        {
          title: "Analytics",
          pathName: "analytics",
          children: [
            {
              title: "Create a Query",
              url: "",
            },
            {
              title: "Saved Reports",
              url: "",
            },
            {
              title: "Scheduled Reports",
              children: [
                {
                  title: "Month-End Report",
                  children: [
                    {
                      title: "Month-End Package",
                      url: "",
                    },
                    {
                      title: "Final Reported Accessions",
                      url: "/analytics/final-reported-accessions",
                    },
                  ],
                },
                {
                  title: "View All Scheduled Reports",
                  url: "",
                },
              ],
            },
            {
              title: "Dashboards",
              url: "",
            },
            {
              title: "Extra Data Table",
              url: "",
            },
            {
              title: "Configuration",
              url: "",
            },
          ],
        },
      ],
    };
  },
  computed: {
    user() {
      return this.actions.getUserDetails() || {};
    },
  },
  methods: {
    isUrlActivated(path) {
      return this.$route.path.includes(path);
    },
    getInitials(name) {
      let initials = name.split(" ");

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }

      return initials.toUpperCase();
    },
    to(link) {
      if (this.$router.history.current.path != link) this.$router.push(link);
    },
    startTime() {
      this.headerTime = this.$options.filters.getPSTDate(
        null,
        "MMM DD, YYYY HH:mm:ss",
        true
      );
    },
    async logout() {
      await axios.post("/auth/logout");
      this.$router.go("/login");
    },
  },
};
